let cache: any = null

function importAll(r: __WebpackModuleApi.RequireContext) {
  if (cache) {
    return cache
  }

  cache = r
    .keys()
    .filter((key) => key.startsWith('./'))
    .map((key) => ({
      name: key.replace('./', '').replace('.svg', ''),
      src: r(key), // Use the default export for the file path
    }))
  return cache
}

const i: any = importAll(require.context('../assets/convrt-icons', false, /\.svg$/))

// Merge the two lists (i and icons) and filter out duplicates based on 'name' and ensure 'src' exists
const icons = [...i].filter((icon, index, self) => {
  // Remove duplicates by comparing 'name' and make sure 'src' exists
  const isDuplicate = self.findIndex((t) => t.name === icon.name) !== index
  const hasSrc = icon.src && typeof icon.src === 'string'
  return !isDuplicate && hasSrc
})
export default icons
