import { Card, Dropdown, MenuProps, message, Modal, Tooltip } from 'antd'
import { useRef, useState } from 'react'
import styles from './VideoCard.module.scss'
import videoIcon from 'common/assets/svg/Video.svg'
import closeVideoIcon from 'common/assets/svg/closeVideo.svg'
import { useAppSelector } from 'state'
import Dots from 'common/assets/svg/three_dots.svg'
import { useLazyDeleteVideoQuery, useLazyPriorityUpOrDownVideoQuery } from '../../state/api/LearningCenterAPI'
import { trackEvent } from 'eventConfig'

interface VideoCardProps {
  video: any
}
export default function VideoCard({ video }: VideoCardProps) {
  //https://www.w3schools.com/html/mov_bbb.mp4'
  const { id: videoID, title, src, cover } = video
  const { isAdmin } = useAppSelector((state) => state.learning)
  const [videoOpen, setVideoOpen] = useState(false)
  const vidRef: any = useRef(null)
  const [deleteVideoQuery] = useLazyDeleteVideoQuery()
  const [moveUpOrDown] = useLazyPriorityUpOrDownVideoQuery()
  const adminDropdownItems: MenuProps['items'] = [
    {
      key: '1',
      label: 'Delete',
    },
    {
      key: '2',
      label: 'Move Up',
    },
    {
      key: '3',
      label: 'Move Down',
    },
  ]
  const deleteVideo = async () => {
    try {
      await deleteVideoQuery({ videoID })
      message.success('Video deleted successfully - please refresh')
    } catch (e) {
      message.error('Error deleting video')
      console.log('Error deleting video:', e)
    }
  }
  const moveUp = async () => {
    await moveUpOrDown({ videoID, up: true })
    message.success('Video moved up successfully - please refresh')
  }
  const moveDown = async () => {
    await moveUpOrDown({ videoID, up: false })
    message.success('Video moved down successfully - please refresh')
  }
  const handleAdminMenuClick: MenuProps['onClick'] = (e) => {
    e.domEvent.stopPropagation()
    e.domEvent.preventDefault()
    if (e.key === '1') {
      deleteVideo()
    }
    if (e.key === '2') {
      moveUp()
    }
    if (e.key === '3') {
      moveDown()
    }
  }

  return (
    <>
      <Modal
        zIndex={1002}
        className='learning-video-modal'
        open={videoOpen}
        onCancel={() => {
          setVideoOpen(false)
          if (vidRef) {
            vidRef?.current?.pause()
          }
        }}
        centered
        closeIcon={null}
        closable={false}
        width={'1208px'}
        footer={null}
        title={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 70,
              width: '100%',
            }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: 7,
                justifyContent: 'space-between',
                height: 50,
                width: '96%',
              }}>
              <span className={styles.modalTitle}>{title}</span>
              <img
                src={closeVideoIcon}
                alt=''
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setVideoOpen(false)
                  if (vidRef) {
                    vidRef?.current?.pause()
                  }
                }}
              />
            </div>
          </div>
        }
        bodyStyle={{ height: '650px', padding: 0, width: '100%' }}>
        <video width={'100%'} height={'100%'} src={src} autoPlay controls ref={vidRef}></video>
      </Modal>
      <Card
        onClick={() => {
          trackEvent('LEARNING_CENTER_USER_CLICKED_VIDEO', {
            mediaType: 'Video',
            mediaName: title,
          })
          setVideoOpen(true)
        }}
        className={styles.videoCard}
        bodyStyle={{
          padding: 0,
          overflow: 'hidden',
          borderRadius: 8,
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <div className={styles.cover}>
          <img
            src={cover}
            style={{ width: '100%', height: '100%', objectFit: 'cover', maxHeight: '120px', overflow: 'hidden' }}
            alt='Video'
          />
        </div>
        <div className={styles.content}>
          <div className={styles.wrapper}>
            <span style={{ flex: 0.7, marginLeft: 12 }}>
              <Tooltip title={title} placement='top' trigger={['hover', 'focus', 'click']}>
                {title?.length > 37
                  ? `${title.charAt(0).toUpperCase()}${title?.trim()?.substring(1, 37)?.toLowerCase()}...`
                  : title}
              </Tooltip>
            </span>
            <span style={{ flex: 0.2, cursor: 'pointer' }}>
              {!isAdmin && <img src={videoIcon} alt='Video' />}
              {isAdmin && (
                <Dropdown placement='topLeft' menu={{ items: adminDropdownItems, onClick: handleAdminMenuClick }}>
                  <img
                    src={Dots}
                    alt='3 Dots'
                    style={{ cursor: 'pointer', height: '100%', marginRight: 5, zIndex: 15 }}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  />
                </Dropdown>
              )}
            </span>
          </div>
        </div>
      </Card>
    </>
  )
}
