import { Popover, Tooltip } from 'antd'
import { FancyImage } from 'features/contacts/components/FancyImage/FancyImage'
import styles from './ModularColumns.module.scss'
import CompanyInfo from 'common/components/OutreachAI/CreateCampaign/Leads/CompanyInfo'
import { InfoCircleOutlined, LinkOutlined } from '@ant-design/icons'
import lead_icon4 from 'common/assets/svg/lead_icon4.svg'
import { capitalize } from 'lodash'
import { TagsList } from 'features/contacts/components/TagsList/TagsList'
import { TagsListCut } from 'features/contacts/components/TagsList/TagsListCut'
import group_unlocked_icon from 'common/assets/svg/group_unlocked_icon.svg'
import group_locked_icon from 'common/assets/svg/group_locked_icon.svg'
import { Channels } from './Channels'
import TruncatedTooltip from './TruncatedTooltip'
import IsLeadTemp from './IsLeadTemp'
import { AiLeadsChannels } from './AiLeadsChannels'
import { CompanyLeadChannels } from './CompanyLeadsChannels'

export const defaultColumnsByPlatform: any = {
  instagram: ['name', 'username', 'biography', 'categories', 'follower_count', 'is_private'],
  facebook: ['name', 'username', 'biography', 'is_private'],
  linkedin: ['name', 'company', 'location', 'biography', 'skills', 'follower_count', 'about'],
}
export const defaultColumnsByAction = {
  ai_leads: ['name', 'company', 'location', 'industry', 'businessType', 'source', 'channels'],
  ai_leads_companies: [
    'name',
    'location',
    'industry',
    'categories',
    'technologies',
    'number_of_employees',
    'channels',
    'source',
  ],
  csv: ['name', 'company', 'jobTitle', 'profile_url', 'custom_1', 'custom_2'],
}
// interface DataType {
//   key: any
//   name: string
//   lead_photo: any
//   company: string
//   company_photo: any
//   jobTitle: string
//   accuracy: any
//   location: string
//   industry: string
//   businessType: string[]
//   source: string
//   channels: string
//   enrich: string
//   facebook: any
//   linkedin: any
//   instagram: any
//   twitter: any
//   discord: any
//   telegram: any
// }

// Include columns from all lead tables, such as groups (Instagram, Facebook, LinkedIn) and AI data (leads and companies).
// Once we have all columns, we can add a new prop to ModularTable called "defaultColumns" to set the initial columns visible to the user for each scenario.
// Additionally, we need to store the selected columns for each campaign in the "props" column of the campaigns table.
// This can be managed through a global state, updating it when creating a campaign via createCampaign(), and retrieving it when calling useCampaigns() or on the summary page.

// If styling "dosn't work" - make sure to add it to ModularColumns.module.scss from the place that you took it.

/*
convrt_source:
  social_hub
  ai_leads
  ai_leads_companies
  CSV
*/

/*

INSTAGRAM:
  SOCIAL_HUB:
    full_name
    profile_pic_url
    handle
    bio
    follower_count
    category
    is_private
  AI_LEADS:
    name
    lead_photo
    company
    channels
    location
    industry
    businessType
    source
  AI_LEADS_COMPANIES:
    name
    location
    industry
    categories
    technologies
    number_of_employees
    channels
    source
  CSV:
    TODO
LINKEDIN:
  SOCIAL_HUB:
    REGULAR:
      full_name
      location
      position (Bio)
      number_of_followers
    NAV:
      full_name
      company
      location
      skills
      about
*/

export const columns: any = [
  {
    title: 'Contact Name',
    dataIndex: 'name',
    key: 'name',
    width: 230,
    ellipsis: {
      showTitle: true,
    },
    render: (text: string, data: any) => {
      const { platform, convrt_source: source } = data

      return renderBy('name', platform, source, data)
    },
  },
  {
    title: 'Handle',
    dataIndex: 'username',
    key: 'username',
    width: 120,
    ellipsis: {
      showTitle: true,
    },
    render: (text: string, data: any) => {
      if (data.display) {
        return (
          <Tooltip placement='topLeft' title={data?.username}>
            <span style={{ fontWeight: 600, cursor: 'pointer' }}>
              {/* {data?.username?.length > 10 ? `${data?.username.substring(0, 10)}...` : data?.username} */}
              {data?.username}
            </span>
          </Tooltip>
        )
      } else if (data?.recipient_id) {
        return (
          <Tooltip placement='topLeft' title={data?.recipient}>
            <span style={{ fontWeight: 600, cursor: 'pointer' }}>
              {/* {data?.recipient?.length > 10 ? `${data?.recipient.substring(0, 10)}...` : data?.recipient} */}
              {data?.recipient}
            </span>
          </Tooltip>
        )
      } else {
        return <span className={styles.handle}></span>
      }
    },
  },
  {
    title: 'Bio',
    dataIndex: 'biography',
    key: 'biography',
    width: 325,
    ellipsis: {
      showTitle: true,
    },
    render: (text: string, data: any) =>
      text ? (
        <Tooltip placement='top' title={text}>
          <span style={{ fontWeight: 500, cursor: 'pointer' }}>
            {text?.length > 60 ? `${text.substring(0, 60)?.trim()}...` : text}
            {/* {text} */}
          </span>
        </Tooltip>
      ) : (
        data?.position && renderLinkedinPosition(data)
      ),
  },
  {
    title: 'Categories',
    dataIndex: 'categories',
    key: 'categories',
    width: 120,
    ellipsis: true,

    render: (text: string, data: any) => {
      // tag with category, if category is not available, show 'N/A'
      const { platform, convrt_source: source } = data
      return renderBy('categories', platform, source, data)
    },
  },
  {
    title: 'Followers',
    dataIndex: 'follower_count',
    key: 'follower_count',
    width: 100,
    ellipsis: true,

    render: (follower_count: any, data: any) => {
      const { platform, convrt_source: source } = data
      if (platform === 'linkedin' && source === 'social_hub') {
        const followerCount = data.number_of_followers
        const followersText = followerCount?.match(/^.*?(followers|Followers)/i)?.[0] || ''
        return (
          <TruncatedTooltip text={followersText} maxLength={40} tooltipPlacement='top' style={{ fontSize: '14px' }} />
        )
      }
      if (isNaN(Number(follower_count)) || follower_count === 0 || follower_count === '0' || !follower_count)
        return <span>{'N/A'}</span>
      return <span>{Number(follower_count).toLocaleString()}</span>
    },
  },
  {
    title: 'Technologies',
    dataIndex: 'technologies',
    key: 'technologies',
    width: 120,
    render: (technologies: any) => {
      if (!technologies) return null
      return (
        <div className='tblFieldText'>
          {technologies?.length > 0 ? <TagsList tags={technologies} sliceNum={1} /> : <span>{''}</span>}
        </div>
      )
    },
  },
  {
    title: 'Employees',
    dataIndex: 'number_of_employees',
    key: 'number_of_employees',
    width: 120,
    render: (number_of_employees: any) => {
      if (!number_of_employees) return null
      return <TagsListCut tags={[number_of_employees]} sliceNum={1} toCapitalize={false} />
    },
  },
  {
    title: 'Account Status',
    dataIndex: 'is_private',
    key: 'is_private',
    ellipsis: true,
    width: 130,
    render: (is_private: any) =>
      is_private !== null ? (
        <div className={styles.accountStatus}>
          <img src={is_private ? group_locked_icon : group_unlocked_icon} alt='' />
        </div>
      ) : null,
  },
  {
    title: 'Job Title',
    dataIndex: 'jobTitle',
    key: 'jobTitle',
    width: 150,
    ellipsis: true,
  },

  {
    title: 'Company',
    dataIndex: 'company',
    key: 'company',
    width: 220,
    render: (text: string, data: any) => {
      const { platform, convrt_source: source } = data
      if (!source) return <span>{text}</span>
      return renderBy('company', platform, source, data)
    },
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    width: 260,
    render: (text: string, data: any) => {
      const { platform, convrt_source: source } = data
      if (platform === 'linkedin' && source === 'social_hub') {
        return renderLocationLinkedinSocialHub(data)
      }
      return (
        <Tooltip placement='topLeft' title={text}>
          <span>{text?.length > 30 ? `${text.substring(0, 30)}...` : text}</span>
        </Tooltip>
      )
    },
  },
  {
    title: 'Industry',
    dataIndex: 'industry',
    key: 'industry',
    width: 120,
    render: (text: string, data: any) => <span>{capitalize(text)}</span>,
  },
  {
    title: 'Business Type',
    dataIndex: 'businessType',
    key: 'businessType',
    width: 145,
    render: (businessTypes: string[]) => {
      return (
        <div className='tblFieldText'>
          {businessTypes?.length > 0 ? <TagsList tags={businessTypes} sliceNum={1} /> : <span>{''}</span>}
        </div>
      )
    },
  },
  // {
  //   title: 'Accuracy',
  //   dataIndex: 'accuracy',
  //   key: 'accuracy',
  //   width: 100,
  //   render: (accuracy: any) => <CustomAccuracyTag number={accuracy} />,
  // },
  {
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
    width: 100,
    render(text: string) {
      return <TruncatedTooltip text={text} maxLength={10} fontSize='14' />
    },
  },
  {
    title: 'About',
    dataIndex: 'about',
    key: 'about',
    width: 80,
    // ellipsis: true,

    render: (text: string, data: any) => {
      const about = data?.about?.replace(/^About:\s*/, '')?.replace(/\s*…see more$/, '')
      const experience = data?.experience
      const headline = data?.headline
      const biography = data?.position
      const followersText = data?.number_of_followers?.match(/^.*?(followers|Followers)/i)?.[0] || ''
      if (!about && !experience && !headline && !biography && !followersText) return <></>

      return (
        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
          <Popover
            content={
              <div
                className={styles.popoverContent}
                style={{
                  maxHeight: '400px',
                  overflowY: 'auto',
                  paddingRight: '10px',
                }}>
                {headline && (
                  <div className={styles.sectionContent}>
                    <p>Headline</p>
                    <div style={{ maxWidth: '300px', whiteSpace: 'pre-wrap' }}>{data?.headline}</div>
                  </div>
                )}
                {experience && (
                  <div className={styles.sectionContent}>
                    <p>Experience</p>
                    <div style={{ maxWidth: '300px', whiteSpace: 'pre-wrap' }}>{data?.experience}</div>
                  </div>
                )}
                {about && (
                  <div className={styles.sectionContent}>
                    <p>About</p>
                    <div style={{ maxWidth: '300px', whiteSpace: 'pre-wrap' }}>{about}</div>
                  </div>
                )}
                {biography && (
                  <div className={styles.sectionContent}>
                    <p>Biography</p>
                    <div style={{ maxWidth: '300px', whiteSpace: 'pre-wrap' }}>{biography}</div>
                  </div>
                )}
                {followersText && (
                  <div className={styles.sectionContent}>
                    <p>Followers</p>
                    <div style={{ maxWidth: '300px', whiteSpace: 'pre-wrap' }}>{followersText}</div>
                  </div>
                )}
              </div>
            }
            trigger='hover'
            placement='top'>
            <InfoCircleOutlined style={{ fontSize: '16px', cursor: 'pointer', color: '#7043ff' }} />
          </Popover>
        </div>
      )
    },
  },
  {
    title: 'Skills',
    dataIndex: 'skills',
    key: 'skills',
    width: 150,
    ellipsis: true,

    render: (text: string, data: any) => {
      const skillsArray = text?.split('\n')
      const skillNames = skillsArray?.map((skill) => skill?.split(/ {2,}/)[0])
      if (!skillNames || skillNames?.length === 0 || skillNames?.every((skill) => skill.trim() === '')) {
        return <></>
      }

      return <TagsList tags={skillNames} sliceNum={1} />
    },
  },
  {
    title: 'Channels',
    dataIndex: 'channels',
    key: 'channels',
    width: 180,
    render: (text: string, data: any) => {
      // Refactor this for better code!
      const { convrt_source: source } = data
      if (source === 'social_hub') {
        return <Channels data={data} text={text} />
      }
      if (source === 'ai_leads') {
        return <AiLeadsChannels data={data} text={text} />
      }
      if (source === 'ai_leads_companies') {
        return <CompanyLeadChannels data={data} text={text} />
      }
      return null
    },
  },
  {
    title: 'Profile URL',
    dataIndex: 'profile_url',
    key: 'profile_url',
    width: 200,
    ellipsis: true,
    render: (text: string, data: any) => {
      const { convrt_source } = data
      if (convrt_source === 'CSV') {
        switch (data?.platform) {
          case 'instagram':
            return <span>{data.instagram}</span>
          case 'facebook':
            return <span>{data.facebook}</span>
          case 'linkedin':
            return <span>{data.linkedin}</span>
        }
        return <span>{data?.handle || data?.recipient}</span>
      }
      return <div>{text || data?.recipient}</div>
    },
  },
  {
    title: 'Campaign Status',
    dataIndex: 'isTemp',
    key: 'isTemp',
    width: 150,
    render: (text: string, data: any) => {
      return <IsLeadTemp lead={data} />
    },
  },
  {
    title: 'Custom 1',
    key: 'custom_1',
    dataIndex: 'custom_1',
    width: 200,
  },
  {
    title: 'Custom 2',
    key: 'custom_2',
    dataIndex: 'custom_2',
    width: 200,
  },
]

const renderBy = (column: string, platform: string, source: string, data: any) => {
  switch (column) {
    case 'name':
      return renderByName(platform, source, data)
    case 'company':
      return renderByCompany(platform, source, data)
    case 'categories':
      return renderByCategories(platform, source, data)
    default:
      return 'N/A'
  }
}
const renderByName = (platform: string, source: string, data: any) => {
  if (source === 'ai_leads_companies') {
    // the platform doesnt matter
    return renderByNameAiLeadsCompanies(data)
  }
  if (source === 'ai_leads') {
    return renderByNameInstagramAILeads(data)
  }
  if (source === 'CSV') {
    return renderByNameInstagram(source, data)
  }

  switch (platform) {
    case 'instagram':
      return renderByNameInstagram(source, data)
    case 'linkedin':
      return renderByNameLinkedin(source, data)
    case 'facebook':
      return renderByNameInstagram(source, data)
    default:
      return 'N/A'
  }
}
const renderByCompany = (platform: string, source: string, data: any) => {
  if (source === 'CSV') {
    return renderByCompanyCSV(data)
  }
  if (source === 'social_hub' && !data?.company) {
    return null
  }
  switch (platform) {
    case 'instagram':
      return renderByCompanyInstagram(source, data)
    case 'facebook':
      return renderByCompanyInstagram(source, data)
    case 'linkedin':
      return renderByCompanyLinkedin(source, data)
    default:
      return renderByCompanyInstagram(source, data)
  }
}
const renderByCategories = (platform: string, source: string, data: any) => {
  if (!data.categories || !data?.categories?.[0]) return null
  return (
    <div>
      {data?.categories ? (
        <TagsListCut tags={[data.categories]} sliceNum={1} />
      ) : (
        <TagsListCut tags={['N/A']} sliceNum={1} toCapitalize={false} />
      )}
    </div>
  )
}

const renderByCompanyLinkedin = (source: string, data: any) => {
  switch (source) {
    case 'social_hub':
      return renderByCompanyLinkedinSocialHub(data)
    case 'ai_leads':
      return renderByCompanyInstagramAILeads(data)
    default:
      return null
  }
}
const renderByCompanyInstagram = (source: string, data: any) => {
  switch (source) {
    case 'ai_leads':
      return renderByCompanyInstagramAILeads(data)
    default:
      return renderByCompanyInstagramAILeads(data)
  }
}
const renderByNameInstagram = (source: string, data: any) => {
  switch (source) {
    case 'social_hub':
      return renderByNameInstagramSocialHub(data)
    case 'ai_leads':
      return renderByNameInstagramAILeads(data)
    case 'ai_leads_companies':
      return renderByNameInstagramAILeads(data)
    case 'CSV':
      return renderByNameInstagramSocialHub(data)
    default:
      return renderByNameInstagramSocialHub(data)
  }
}
const renderByNameLinkedin = (source: string, data: any) => {
  switch (source) {
    case 'social_hub':
      return renderByNameLinkedinSocialHub(data)
    case 'ai_leads':
      return renderByNameInstagramAILeads(data)
    default:
      return null
  }
}
const renderByCompanyInstagramAILeads = (data: any) => {
  const text = data?.company
  return (
    <span className='flex_items'>
      {data.company_photo !== '' && (
        <FancyImage url={data.company_photo} className='smallRoundPicture' iscompany={false} />
      )}
      <div className={styles.companyDiv}>
        <div>
          <Tooltip placement='topLeft' title={text}>
            <span>{text?.length > 17 ? `${text.substring(0, 17)}...` : text}</span>
          </Tooltip>
          <CompanyInfo data={data} />
        </div>
        <div className={styles.logos}>
          <Tooltip title={data.company_website ? 'Visit Website' : 'No Website Available'}>
            <LinkOutlined
              onClick={() => data.company_website && window.open(data.company_website, '_blank')}
              style={{
                color: data.company_website ? '#1890ff' : 'gray',
                cursor: data.company_website ? 'pointer' : 'default',
              }}
            />
          </Tooltip>
          <Tooltip title={data.company_linkedin ? 'Visit LinkedIn' : 'No LinkedIn Available'}>
            <img
              onClick={() => data.company_linkedin && window.open(data.company_linkedin, '_blank')}
              src={lead_icon4}
              alt='LinkedIn'
              style={{
                filter: data.company_linkedin ? 'none' : 'grayscale(100%)',
                cursor: data.company_linkedin ? 'pointer' : 'default',
              }}
            />
          </Tooltip>
        </div>
      </div>
    </span>
  )
}

const renderByCompanyLinkedinSocialHub = (data: any) => {
  const text = data?.company
  return (
    <div className={styles.company_name}>
      <div style={{ height: '28px', width: '28px' }}>
        <FancyImage
          url={data.company_pic_url}
          className='smallRoundPicture'
          iscompany={false}
          // style={{ marginRight: '5px' }}
          size={28}
          name={text}
        />
      </div>
      <TruncatedTooltip
        text={text}
        maxLength={20}
        tooltipPlacement='topLeft'
        style={{ fontWeight: 600, fontSize: '14px' }}
      />
    </div>
  )
}

const renderByNameInstagramSocialHub = (data: any) => {
  const name = data?.name || data?.full_name || data?.username || data?.handle || data.recipient

  return (
    <div className={styles.full_name}>
      <FancyImage
        url={data.profile_pic_url}
        className='smallRoundPicture'
        iscompany={false}
        style={{ marginRight: '5px' }}
        size={28}
        name={name}
      />
      <div className={styles.contactName}>
        <Tooltip placement='topLeft' title={name}>
          <span style={{ fontWeight: 600, cursor: 'pointer' }}>
            {/* {name?.length > 12 ? `${name.substring(0, 12)}...` : name} */}
            {name}
          </span>
        </Tooltip>
      </div>
    </div>
  )
}
const renderByCompanyCSV = (data: any) => {
  const name = data?.company
  return (
    <div className={styles.full_name}>
      <FancyImage
        url={data.profile_pic_url}
        className='smallRoundPicture'
        iscompany={false}
        style={{ marginRight: '5px' }}
        size={28}
        name={name}
      />
      <div className={styles.contactName}>
        <Tooltip placement='topLeft' title={name}>
          <span style={{ fontWeight: 600, cursor: 'pointer' }}>
            {name?.length > 12 ? `${name.substring(0, 12)}...` : name}
            {/* {name} */}
          </span>
        </Tooltip>
      </div>
    </div>
  )
}
const renderByNameInstagramAILeads = (data: any) => {
  const text = data?.name
  return (
    <span className='flex_items'>
      <FancyImage url={data.lead_photo} className='smallRoundPicture' iscompany={false} />
      <div className={styles.contactName}>
        <Tooltip placement='topLeft' title={text}>
          <span style={{ fontWeight: 600 }}>{text?.length > 16 ? `${text.substring(0, 16)}...` : text}</span>
        </Tooltip>{' '}
        <Tooltip placement='topLeft' title={data.jobTitle}>
          <span style={{ fontSize: '12px' }}>
            {data?.jobTitle?.length > 15 ? `${data.jobTitle.substring(0, 15)}...` : data.jobTitle}
          </span>
        </Tooltip>
      </div>
    </span>
  )
}
const renderByNameLinkedinSocialHub = (data: any) => {
  const name = data?.name || data?.full_name
  const connectionLevel = data?.connection_level?.match(/\d+(st|nd|rd|th)/)?.[0]
  return (
    <div className={styles.full_name}>
      <FancyImage
        url={data.profile_pic_url}
        className='smallRoundPicture'
        iscompany={false}
        style={{ marginRight: '5px' }}
        size={28}
        name={name}
      />
      <div className={styles.contactName}>
        <div className={styles.flexRow}>
          <TruncatedTooltip
            text={name}
            maxLength={15}
            tooltipPlacement='top'
            style={{ fontWeight: 600, cursor: 'pointer', fontSize: '14px' }}
          />
          <span>· {connectionLevel} · </span>
          <Tooltip title={data.username ? 'Visit LinkedIn' : 'No Website Available'}>
            <LinkOutlined
              onClick={() => data.username && window.open(data.username, '_blank')}
              style={{
                color: data.username ? '#1890ff' : 'gray',
                cursor: data.username ? 'pointer' : 'default',
              }}
            />
          </Tooltip>
        </div>
        <TruncatedTooltip text={data.position || data.headline} maxLength={22} fontSize='12px' tooltipPlacement='top' />
      </div>
    </div>
  )
}
const renderByNameAiLeadsCompanies = (data: any) => {
  const text = data?.name

  const getPhoto = () => {
    if (data.company_photo) {
      return data.company_photo
    }
    if (data.profile_pic_url) {
      return data.profile_pic_url
    }
    return ''
  }
  const hasPhoto = getPhoto() !== ''
  const photo = hasPhoto ? getPhoto() : ''
  return (
    <div style={{ minWidth: 230 }}>
      <span className='flex_items'>
        <FancyImage url={photo} className='smallRoundPicture' iscompany={false} name={text} size={28} />
        <div className={styles.companyDiv}>
          <div>
            <Tooltip placement='topLeft' title={text}>
              <span>{text?.length > 15 ? `${text.substring(0, 15)}...` : text}</span>
            </Tooltip>
            <CompanyInfo data={data} />
          </div>
          <div className={styles.logos}>
            <Tooltip title={data.company_website ? 'Visit Website' : 'No Website Available'}>
              <LinkOutlined
                onClick={() => data.company_website && window.open(data.company_website, '_blank')}
                style={{
                  color: data.company_website ? '#1890ff' : 'gray',
                  cursor: data.company_website ? 'pointer' : 'default',
                }}
              />
            </Tooltip>
            {/* <Tooltip title={data.company_linkedin ? 'Visit LinkedIn' : 'No LinkedIn Available'}>
      <img
        onClick={() => data.company_linkedin && window.open(data.company_linkedin, '_blank')}
        src={lead_icon4}
        alt='LinkedIn'
        style={{
          filter: data.company_linkedin ? 'none' : 'grayscale(100%)',
          cursor: data.company_linkedin ? 'pointer' : 'default',
        }}
      />
    </Tooltip> */}
          </div>
        </div>
      </span>
    </div>
  )
}
const renderLocationLinkedinSocialHub = (data: any) => {
  return (
    <TruncatedTooltip text={data?.location} maxLength={40} tooltipPlacement='topLeft' style={{ fontSize: '14px' }} />
  )
}
const renderLinkedinPosition = (data: any) => {
  const text = data.position
  return <TruncatedTooltip text={text} maxLength={40} tooltipPlacement='top' style={{ fontSize: '14px' }} />
}
