import React, { useEffect, useState } from 'react'
import styles from './ViewLeadsList.module.scss'
// import CustomAccuracyTag from './UI/CustomAccuracyTag'
import { useAppSelector } from 'state'
import CustomStatusTag from './UI/CustomStatusTag'
import { columns as all_cols } from '../../../../GeneralComponents/ModularColumns'
import ModularTable from 'GeneralComponents/ModularTable'

const ViewLeadsList: React.FC = () => {
  const { selected_leads, stored_temp_leads } = useAppSelector((state) => state.outreachAICampaign)

  const [activeLeads, setActiveLeads] = useState([])

  const statusCol = {
    title: 'Status',
    dataIndex: 'last_step',
    key: 'last_step',
    width: 150,
    render: (text: string, data: any) => {
      return <CustomStatusTag status={text || 'Pending'} />
    },
  }
  const cols = [...all_cols, statusCol]
  const step_type: any = {
    telegram_outreach: 'Outreach',
    telegram_followup: 'Followup',
    instagram_follow: 'IG follow',
    instagram_send_message: 'IG DM',
    instagram_send_comment: 'Comment',
  }

  const selectedLeadsData = activeLeads?.map((item: any, index: any) => ({
    ...item,
    last_step: step_type[item.step_type] || item.last_step,
  }))

  useEffect(() => {
    // Filter out leads that are present in `stored_temp_leads`
    const filteredLeads =
      selected_leads?.filter((lead: any) => {
        return !lead.isTemp
      }) || []

    setActiveLeads(filteredLeads)
  }, [selected_leads, stored_temp_leads]) // Add dependencies to re-run on changes

  return (
    <div className={styles.container}>
      <div className={styles.matchesLine}>
        <span>
          Your Campaign has <span style={{ fontWeight: 600 }}>{activeLeads?.length} leads</span>!
        </span>
      </div>
      <ModularTable
        openDrawer={false}
        setOpenDrawer={() => {}}
        data={selectedLeadsData}
        columns={cols}
        pagination={{
          total: selectedLeadsData.length,
          pageSize: 15,
          showSizeChanger: false,
        }}
        rootClassName='ViewLeadsList Modular_Table_Expand'
        lastStep={true}
      />
    </div>
  )
}

export default ViewLeadsList
