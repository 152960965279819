import { useAppSelector } from 'state'

export default function IsLeadTemp({ lead }: any) {
  const { stored_leads_from_db } = useAppSelector((state) => state.outreachAICampaign)

  // Function to strip groupname from the stored lead
  const stripGroupname = (storedLeadKey: string) => {
    const firstUnderscoreIndex = storedLeadKey.indexOf('_') // Find the first underscore
    return firstUnderscoreIndex !== -1
      ? storedLeadKey.slice(firstUnderscoreIndex + 1) // Extract everything after the first underscore
      : storedLeadKey // If no underscore, return the whole string
  }

  // Check if the lead is stored
  const isStored = stored_leads_from_db.some((storedLeadKey: any) => stripGroupname(storedLeadKey) === lead.key)

  return <div>{isStored ? 'Added' : 'Selected'}</div>
}
