import React, { useEffect, useState } from 'react'
import { Layout, Spin, message } from 'antd'
import Sider from 'antd/es/layout/Sider'
import styles from './Chat.module.scss'
import ChatChannels from './ChatChannels'
import ChatTabs from './Tabs/ChatTabs'
import {
  useLazyGetDialogsQuery,
  useLazyInitUnifyQuery,
  // useLazyGetInstagramDialogsQuery,
  // useLazyUnifyInstagramQuery,
  useLazyUnifyQuery,
} from './state/api/Chat-API'
import {
  SET_DIALOGS_METADATA_BULK,
  SET_IS_UNIFY_FINISHED,
  SET_REUNIFY,
  SET_SELECTED_CHAT_RECIPIENT,
  SET_UNIFIED_ACCOUNTS,
} from './state/chatSlice'
import { useAppDispatch, useAppSelector } from 'state'
import { LoadingOutlined } from '@ant-design/icons'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
import { useTagDialog } from './Tag/Hook/useTagDialog'
import empty_state_messages from 'common/assets/svg/empty_state_messages.svg'

const { Content } = Layout

const Chat: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false)
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const [getDialogs] = useLazyGetDialogsQuery()
  const [dialogsData, setDialogsData] = useState<any>()
  const [initUnify] = useLazyInitUnifyQuery()
  const [unifyAccounts] = useLazyUnifyQuery()
  const { is_unify_finished, close_left_sidebar, selected_chat_platform, reunify, thread_from } = useAppSelector(
    (state) => state.chat,
  )
  const { userDetails } = useAuth()
  const [userData, setUserData] = useState(userDetails)
  const [unifiedFailed, setUnifiedFailed] = useState(false)
  const [noAccounts, setNoAccounts] = useState(false)
  const [retryCount, setRetryCount] = useState(0)
  const [isConnected, setIsConnected] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  useTagDialog('') // Init tags

  const contentStyle: React.CSSProperties = {
    minHeight: 120,
    lineHeight: '120px',
    overflow: 'scroll',
  }

  const layoutStyle = {
    overflow: 'hidden',
    height: '100%',
    paddingTop: '0px',
    paddingLeft: '0px',
    borderLeft: '1px solid #DFE1E6',
  }

  // useEffect(() => {
  //   const unifyAllAccounts = async () => {
  //     await unifyAccounts()
  //   }

  //   const getAllDialogs = async () => {
  //     await getDialogs()
  //   }

  //   unifyAllAccounts()
  //   getAllDialogs()
  // }, [])

  useEffect(() => {
    const getUserDetails = async () => {
      const { data } = await refetchGetUserDetails()
      setUserData(data)
    }
    getUserDetails()
  }, [])

  // const { data: unifyData } = await initUnify()
  // dispatch(SET_UNIFIED_ACCOUNTS(unifyData))
  // dispatch(SET_IS_UNIFY_FINISHED(true))

  useEffect(() => {
    const unifyAllAccounts = async () => {
      const { data: initData } = await initUnify()

      if (initData?.allEmpty) {
        setNoAccounts(true)
        return message.warning('You have 0 accounts connected to Convrt.')
      }
      await getAllDialogs()
      dispatch(SET_IS_UNIFY_FINISHED(true))
      dispatch(SET_UNIFIED_ACCOUNTS(initData))

      const { data: unifyData } = await unifyAccounts()

      if (unifyData?.isUnified === false) {
        setUnifiedFailed(true)
        return message.error('Data could not be synced. Please try again or contact support.')
      } else if (unifyData?.allSync === false) {
        // trigger again
        // message.warning('Sync did not complete, retrying.')
        return setRetryCount((prevCount) => prevCount + 1)
      } else if (unifyData?.allEmpty) {
        setNoAccounts(true)
        return message.warning('You have 0 accounts connected to Convrt.')
      }
      const dialogsRes = await getAllDialogs()

      if (!dialogsRes) {
        setRetryCount((prevCount) => prevCount + 1)
        return
      }

      setIsConnected(true)
      dispatch(SET_UNIFIED_ACCOUNTS(unifyData))
      dispatch(SET_IS_UNIFY_FINISHED(true))
      dispatch(SET_REUNIFY(false))
    }

    const getAllDialogs = async () => {
      const { data } = await getDialogs({
        thread_from,
      })
      if (Object.keys(data)?.length === 0) {
        return undefined
      }

      setDialogsData(data)

      const metadata = Object.entries(data).reduce((acc: any, [platform, platformData]: [string, any]) => {
        platformData.dialogData.forEach((dialog: any) => {
          acc[dialog.id] = {
            pin: dialog.pin,
            priority: dialog.priority,
            meeting_book: dialog.meeting_book,
          }
        })
        return acc
      }, {})

      dispatch(SET_DIALOGS_METADATA_BULK(metadata))

      switch (selected_chat_platform) {
        case 'telegram':
          dispatch(
            SET_SELECTED_CHAT_RECIPIENT({
              ...data?.telegram?.dialogData?.[0],
              senderData: data?.telegram?.dialogData?.[0]?.receiver_number,
            }),
          )
          return data
        case 'instagram':
          // dispatch(
          //   SET_SELECTED_CHAT_RECIPIENT({
          //     ...data?.instagram?.dialogData?.[0],
          //     senderData: data?.instagram?.dialogData?.[0]?.sender_username,
          //   }),
          // )
          return data
        case 'facebook':
          return data
        default:
          break
      }
    }

    // dispatch(SET_IS_UNIFY_FINISHED(false))
    if (userData?.addons?.unified_inbox && !is_unify_finished) {
      unifyAllAccounts()
      // post('/log', {
      //   action: 'Unified Inbox: User Unified',
      // })
    }

    if (reunify) {
      const reunifyData = async () => {
        dispatch(SET_IS_UNIFY_FINISHED(false))
        const { data: unifyData } = await unifyAccounts()

        if (unifyData?.isUnified === false) {
          setUnifiedFailed(true)
          return message.error('Data could not be synced. Please try again or contact support.')
        } else if (unifyData?.allSync === false) {
          return setRetryCount((prevCount) => prevCount + 1)
        } else if (unifyData?.allEmpty) {
          setNoAccounts(true)
          return message.warning('You have 0 accounts connected to Convrt.')
        }
        const dialogsRes = await getAllDialogs()

        if (!dialogsRes) {
          setRetryCount((prevCount) => prevCount + 1)
          return
        }

        setIsConnected(true)
        dispatch(SET_UNIFIED_ACCOUNTS(unifyData))
        dispatch(SET_IS_UNIFY_FINISHED(true))
        dispatch(SET_REUNIFY(false))
      }
      reunifyData()
    }
  }, [retryCount, reunify])

  const handleReconnect = async () => {
    if (!isConnected) {
      const { data } = await unifyAccounts()
      if (!data?.allSync) {
        setIsConnected(true)
        const { data } = await getDialogs({
          thread_from,
        })
        setDialogsData(data)
      }
    }
  }

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      const { data } = await getDialogs({ thread_from })
      setDialogsData(data)
      setLoading(false)
    }
    if (is_unify_finished) {
      fetch()
    }
  }, [thread_from])
  useEffect(() => {
    document.addEventListener('visibilitychange', handleReconnect)
    window.addEventListener('click', handleReconnect)

    if (dialogsData) {
      const outOfSync = Object.values(dialogsData).every((accountType: any) =>
        accountType.accounts.every((account: any) => account.status.is_syncing === false),
      )

      if (outOfSync) {
        setIsConnected(false)
      } else {
        setIsConnected(true)
      }
    }

    return () => {
      document.removeEventListener('visibilitychange', handleReconnect)
      window.removeEventListener('click', handleReconnect)
    }
  }, [isConnected, dialogsData])

  const LoadingOverlay = () => (
    <div className={styles.loadingOverlay}>
      <Spin size='large' indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      <span style={{ margin: '10px 0px' }}>Syncing Data...</span>
    </div>
  )

  const FailedOverlay = () => (
    <div className={styles.loadingOverlay}>
      <span style={{ margin: '10px 0px' }}>
        Syncing Data Did not complete. Please try again later or contact support.
      </span>
    </div>
  )

  const handleCollapse = () => {
    setCollapsed(!collapsed)
  }

  useEffect(() => {
    if (!collapsed && close_left_sidebar) {
      setCollapsed(true)
    }
  }, [close_left_sidebar])

  if (noAccounts) {
    return (
      <div className={styles.emptyState}>
        <img src={empty_state_messages} alt='empty' />
        <p>No Accounts are Connected to Convrt</p>
        <span>
          Please connect your first account through the{' '}
          <a href='/settings' className={styles.settingsLink}>
            Settings Page
          </a>
        </span>
      </div>
    )
  }

  return (
    <Layout>
      {unifiedFailed && <FailedOverlay />}
      {!is_unify_finished && userData?.addons?.unified_inbox && !unifiedFailed && <LoadingOverlay />}
      <Sider
        className='chat_sider'
        collapsible
        collapsed={collapsed}
        onCollapse={handleCollapse}
        width={370}
        style={{
          overflow: 'auto',
          height: '100%',
          position: 'sticky',
          top: 0,
          left: 0,
        }}>
        <div className={styles.top_section}>
          <h3>Channels</h3>
          {/* <Button className='hidebtn_collapse'>Manage</Button> */}
        </div>
        <div className='hide_collapse'>
          <p className='para'>Select Channel to view chats</p>
          <ChatChannels collapsed={collapsed} />
        </div>
      </Sider>
      <Content>
        <Layout style={layoutStyle}>
          <Content className='customScrollContent flex flex-row' style={contentStyle}>
            <ChatTabs data={dialogsData} loading={loading} />
          </Content>
        </Layout>
      </Content>
    </Layout>
  )
}

export default Chat
