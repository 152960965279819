import React, { useEffect, useState } from 'react'
import { Modal, Divider, Button, Layout, Table, Input, Select } from 'antd'
import { columns as all_cols } from 'GeneralComponents/ModularColumns'
import styles from './ModularModalAndFilters.module.scss'
import { Content } from 'antd/es/layout/layout'
import { RESET_SELECTED_LEADS, SET_STORED_LEADS_FROM_DB } from '../../state/outreachAICampaignSlice'
import { useAppDispatch, useAppSelector } from 'state'
import LoadingBox from 'common/components/LoadingBox/LoadingBox'
import { useLazyRemoveTempLeadsQuery } from '../../state/api/OutreachAI-API'
import ModularTable from 'GeneralComponents/ModularTable'
import purple_eye from 'common/assets/svg/purple_eye.svg'
import CustomStatusTag from '../UI/CustomStatusTag'

interface ModularModalAndFiltersProps {
  open: boolean
  onClose: () => void
  selectedLeadsData: any
  lastStep?: boolean
}

const ModularModalAndFilters: React.FC<ModularModalAndFiltersProps> = ({
  open,
  onClose,
  selectedLeadsData,
  lastStep = false,
}) => {
  const { selected_leads, campaign_id, stored_leads_from_db } = useAppSelector((state) => state.outreachAICampaign)

  const [loadingRemove, setLoadingRemove] = useState(false)
  const dispatch = useAppDispatch()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [visibleLeads, setVisibleLeads] = useState<any>([])
  const [removeTempLeads] = useLazyRemoveTempLeadsQuery()
  const [search, setSearch] = useState('')
  const [openDrawer, setOpenDrawer] = useState(false)
  const statusCol = {
    title: 'Status',
    dataIndex: 'last_step',
    key: 'last_step',
    width: 150,
    render: (text: string, data: any) => {
      return <CustomStatusTag status={text || 'Pending'} />
    },
  }
  const handleCloseModal = () => {
    onClose()
  }

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer)
  }

  const handleSelect = (value: string) => {
    if (value === 'az') {
      // sort visible leads by name / full_name from a to z
      setVisibleLeads((prev: any) =>
        [...prev].sort((a: any, b: any) => {
          const an = a.name || a.full_name
          const bn = b.name || b.full_name
          return an.localeCompare(bn)
        }),
      )
    }
    if (value === 'za') {
      setVisibleLeads((prev: any) =>
        [...prev].sort((a: any, b: any) => {
          const an = a.name || a.full_name
          const bn = b.name || b.full_name
          return bn.localeCompare(an)
        }),
      )
    }
  }
  const selectOptions = [
    {
      label: (
        <span>
          <span className={styles.sortByText}>Sort by </span>
          <span>Alphabetical (A to Z)</span>
        </span>
      ),
      value: 'az',
    },
    {
      label: (
        <span>
          <span className={styles.sortByText}>Sort by </span>
          <span>Alphabetical (Z to A)</span>
        </span>
      ),
      value: 'za',
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setSelectedRowKeys(selectedRows)
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  }
  const hasSelected = selectedRowKeys?.length > 0

  const handleRemoveLeads = async () => {
    setLoadingRemove(true)
    const result = selected_leads.filter(
      (item: any) => !selectedRowKeys.some((filterItem: any) => filterItem.key === item.key),
    )
    if (result.length === 0) {
      onClose()
    }
    const keys = selectedRowKeys?.map((row: any) => row.index)

    const indexes = selectedRowKeys?.map((row: any) => row.key)
    setSelectedRowKeys([])
    dispatch(RESET_SELECTED_LEADS(result))

    await removeTempLeads({ campaign_id: campaign_id, indexes: indexes })
    const afterRemoval = stored_leads_from_db?.filter((item: any) => !keys.includes(item))
    dispatch(SET_STORED_LEADS_FROM_DB(afterRemoval))
    setLoadingRemove(false)
  }

  useEffect(() => {
    if (!search) {
      setVisibleLeads(selectedLeadsData)
      return
    }
    const doesCategoryContain = (lead: any) => {
      if (!lead.categories) {
        return false
      }
      if (typeof lead.categories === 'string') {
        return lead.categories?.toLowerCase()?.includes(search.toLowerCase())
      }
      if (typeof lead.categories === 'object') {
        return lead.categories?.some((category: any) => category?.toLowerCase()?.includes(search.toLowerCase()))
      }
      return false
    }
    const filterBySearch = selectedLeadsData?.filter((lead: any) => {
      return (
        lead?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
        lead?.full_name?.toLowerCase()?.includes(search.toLowerCase()) ||
        lead?.username?.toLowerCase()?.includes(search.toLowerCase()) ||
        lead?.biography?.toLowerCase()?.includes(search.toLowerCase()) ||
        doesCategoryContain(lead) ||
        lead?.company?.toLowerCase()?.includes(search.toLowerCase())
      )
    })
    setVisibleLeads(filterBySearch)
  }, [search])
  useEffect(() => {
    setVisibleLeads(selected_leads) // Sync with updated Redux state
    setLoadingRemove(false)
  }, [selected_leads])
  return (
    <>
      <Modal
        centered
        title={false}
        footer={false}
        open={open}
        onCancel={handleCloseModal}
        className='LeadsModalAndFilters'
        maskClosable={false}
        // style={{ minHeight: 1000 }}
        width='85%'>
        <div className={`${styles['container']} `}>
          <div className={styles.LeadsModalAndFilters__TopSection}>
            <div className={`${styles['matchesLine']} LeadsModalAndFilters__TopSection`}>
              <span className={styles.LeadsModalAndFilters__LeftSection}>
                <span className={styles.LeadsModalAndFilters__Title}>{`Edit & Review`}</span>
                <Divider className='betterDivider' type='vertical' />
                <span>
                  {' '}
                  <div>
                    {hasSelected ? (
                      <>
                        <span style={{ fontWeight: 600 }}>{selectedRowKeys?.length}</span> leads{' '}
                        {selectedRowKeys?.length === 1 ? 'was' : 'were'} selected
                      </>
                    ) : (
                      <>
                        <span style={{ fontWeight: 600 }}>0 leads </span> were selected
                      </>
                    )}
                  </div>
                </span>
              </span>
              <div className={styles.importLeads}>
                <Button onClick={handleCloseModal}>Exit</Button>
              </div>
            </div>
            <div className={styles.search}>
              <div className={styles.input_box}>
                <Input
                  size='large'
                  placeholder={'Search'}
                  onChange={(v) => {
                    setSearch(v.target.value)
                  }}
                  value={search}
                />
              </div>
            </div>
          </div>
          <div className={styles.LeadsModalAndFilters__Content_Section}>
            <div className={styles.Content_Left}>
              <div
                style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Button
                  className={`removeLeads${hasSelected ? 'Visible' : 'Hidden'}`}
                  loading={loadingRemove}
                  onClick={handleRemoveLeads}
                  disabled={selectedRowKeys?.length === 0}
                  type='primary'
                  size='middle'>
                  Remove Leads
                </Button>
                <div className={styles.topSection}>
                  <Button onClick={toggleDrawer}>
                    <img src={purple_eye} alt='' />
                    <span>Adjust Columns</span>
                  </Button>
                  <Select
                    className='antSelectAZ'
                    rootClassName='antSelectAZ'
                    onSelect={(value) => handleSelect(value)}
                    options={selectOptions}
                    defaultValue={'az'}
                    placement={'bottomRight'}
                  />
                </div>
              </div>

              <Layout className='leadsSider'>
                <Content className={styles.content}>
                  {false ? (
                    <div className={styles.loading}>
                      <LoadingBox />
                    </div>
                  ) : selectedLeadsData?.length === 0 ? (
                    <div className={styles.noLeadsFound}>
                      <span>No leads found. Please add leads to edit and review.</span>
                    </div>
                  ) : (
                    <div style={{ height: '580px' }}>
                      <ModularTable
                        openDrawer={openDrawer}
                        setOpenDrawer={setOpenDrawer}
                        key={1}
                        scroll={{ x: 'max-content', y: window.innerHeight < 900 ? 230 : 300 }}
                        tableLayout='auto'
                        data={visibleLeads}
                        columns={[...all_cols, lastStep ? statusCol : null].filter((item) => item)}
                        rowSelection={{
                          type: 'checkbox',
                          ...rowSelection,
                        }}
                        pagination={{
                          total: selectedLeadsData?.length,
                          pageSize: 50,
                          showSizeChanger: false,
                        }}
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>
                  )}
                </Content>
              </Layout>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModularModalAndFilters
