import { logEvent } from 'eventLogger'

export const EVENTS = {
  ///////////////////////////
  //  OUSTIDE ONBOARDING  //
  /////////////////////////

  OUTSIDE_SIGNUP_FIRST_STEP: {
    name: 'Outside Signup: First step',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user starts the outside onboarding process after registration',
      trigger: 'User begins "Outside Signup: First step"',
      related_screen: 'Onboarding Page',
      key_data_points: [],
      expected_outcome: 'Confirm that users proceed from registration to onboarding',
      notes: 'This event indicates the transition to onboarding.',
      date_started: 'old',
    },
  },

  SIGNUP_EMAIL_VERIFIED: {
    name: 'Outside Signup: Second step (Email Verified)',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user completed the email verification',
      trigger: 'User clicked on continue',
      related_screen: 'Onboarding - Email verification',
      key_data_points: [],
      expected_outcome: 'Check if user completed this step',
      notes: 'This event indicates that the user completed the step and not just started.',
      date_started: 'old',
    },
  },

  SIGNUP_GET_TO_KNOW_1: {
    name: 'Outside Signup: Get to know 1 (Company)',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user completed the company domain step (1)',
      trigger: 'User clicked on continue',
      related_screen: 'Onboarding - Get to Know You 1',
      key_data_points: ['Company name', 'Company domain'],
      expected_outcome: 'Check if user completed this step',
      notes: 'This event means that the user completed the step and not started.',
      date_started: 'old',
    },
  },

  SIGNUP_GET_TO_KNOW_2: {
    name: 'Outside Signup: Get to know 2 (Where)',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user completed the “where did you find us” step (2)',
      trigger: 'User clicked on continue',
      related_screen: 'Onboarding - Get to Know You 2',
      key_data_points: ['Where did you find us'],
      expected_outcome: 'Check if user completed this step',
      notes: 'This event indicates that the user completed the step and not just started.',
      date_started: 'old',
    },
  },

  SIGNUP_GET_TO_KNOW_3: {
    name: 'Outside Signup: Get to know 3 (Company Domain)',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user completed the company domain step (3)',
      trigger: 'User clicked on continue',
      related_screen: 'Onboarding - Get to Know You 3',
      key_data_points: ['Company Domain'],
      expected_outcome: 'Check if user completed this step',
      notes: 'This event indicates that the user completed the step and not just started.',
      date_started: 'old',
    },
  },

  SIGNUP_GET_TO_KNOW_4: {
    name: 'Outside Signup: Get to know 4 (Goal)',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user completed the goal step (4)',
      trigger: 'User clicked on continue',
      related_screen: 'Onboarding - Get to Know You 4',
      key_data_points: ['Goal'],
      expected_outcome: 'Check if user completed this step',
      notes: 'This event indicates that the user completed the step and not just started.',
      date_started: 'old',
    },
  },

  SIGNUP_COMPLETION: {
    name: 'Outside Signup: User finished signup process',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track completion of the onboarding process.',
      trigger: 'User clicked continue on the final step.',
      related_screen: 'Onboarding - Final Step',
      key_data_points: [],
      expected_outcome: 'User navigates to the /upgrade page.',
      notes: 'Indicates that the user has finished onboarding and will proceed to the upgrade page.',
      date_started: 'old',
    },
  },

  ARRIVE_SIGNUP_GET_TO_KNOW_1: {
    name: 'Arrive: Outside Signup: Get to know 1 (Company)',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user completed the company domain step (1)',
      trigger: 'User clicked on continue',
      related_screen: 'Onboarding - Get to Know You 1',
      key_data_points: ['Company name', 'Company domain'],
      expected_outcome: 'Check if user completed this step',
      notes: 'This event means that the user completed the step and not just started.',
      date_started: '31.10.2024',
    },
  },

  ARRIVE_SIGNUP_GET_TO_KNOW_2: {
    name: 'Arrive: Outside Signup: Get to know 2 (Where)',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user completed the “where did you find us” step (2)',
      trigger: 'User clicked on continue',
      related_screen: 'Onboarding - Get to Know You 2',
      key_data_points: ['Where did you find us'],
      expected_outcome: 'Check if user completed this step',
      notes: 'This event indicates that the user completed the step and not just started.',
      date_started: '31.10.2024',
    },
  },

  ARRIVE_SIGNUP_GET_TO_KNOW_3: {
    name: 'Arrive: Outside Signup: Get to know 3 (Company Domain)',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user completed the company domain step (3)',
      trigger: 'User clicked on continue',
      related_screen: 'Onboarding - Get to Know You 3',
      key_data_points: ['Company Domain'],
      expected_outcome: 'Check if user completed this step',
      notes: 'This event indicates that the user completed the step and not just started.',
      date_started: '31.10.2024',
    },
  },

  ARRIVE_SIGNUP_GET_TO_KNOW_4: {
    name: 'Arrive: Outside Signup: Get to know 4 (Goal)',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user completed the goal step (4)',
      trigger: 'User clicked on continue',
      related_screen: 'Onboarding - Get to Know You 4',
      key_data_points: ['Goal'],
      expected_outcome: 'Check if user completed this step',
      notes: 'This event indicates that the user completed the step and not just started.',
      date_started: '31.10.2024',
    },
  },

  ///////////////////////////
  //       CAMPAIGNS      //
  /////////////////////////
  CAMPAIGN_REVIEW_LAUNCH: {
    name: 'Review & Launch Campaign',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user clicks Review & Launch to start a campaign',
      trigger: 'User clicked on Review & Launch',
      related_screen: 'Campaign - Review & Launch',
      key_data_points: [],
      expected_outcome: 'User starts the campaign review process.',
      notes: 'This event indicates that the user has initiated the campaign process.',
      date_started: '31.10.2024',
    },
  },

  CAMPAIGN_RUN: {
    name: 'Run Campaign',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user clicks Run Campaign to start running a campaign',
      trigger: 'User clicked on Run Campaign',
      related_screen: 'Campaign - Run Campaign',
      key_data_points: [],
      expected_outcome: 'User runs the campaign successfully.',
      notes: 'This event indicates that the user has officially launched the campaign.',
      date_started: '31.10.2024',
    },
  },

  COLLECT_LEADS: {
    name: 'Collect Leads',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user collects leads',
      trigger: 'User clicked on Collect Leads',
      related_screen: 'Leads - Collect Leads',
      key_data_points: [],
      expected_outcome: 'User collects leads successfully.',
      notes: 'This event indicates that the user has initiated the lead collection process.',
      date_started: '31.10.2024',
    },
  },

  IMPORT_CUSTOM_LEADS: {
    name: 'Import Custom Leads',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user imports custom leads',
      trigger: 'User clicked on Import Custom Leads',
      related_screen: 'Leads - Import Custom Leads',
      key_data_points: ['Platform: Facebook, LinkedIn Search, LinkedIn Sales Navigator, Instagram'],
      expected_outcome: 'User imports custom leads successfully.',
      notes: 'This event indicates that the user has chosen to import custom leads from specified platforms.',
      date_started: '31.10.2024',
    },
  },

  ADD_LEADS_TO_CAMPAIGN: {
    name: 'Add Leads to Campaign',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when individual leads are added to a campaign.',
      trigger: 'User adds leads to a campaign',
      related_screen: 'Campaign Management',
      key_data_points: ['user', 'date', 'platform', 'distinct_id', 'recipientsRes'],
      expected_outcome: 'Capture individual lead additions to campaigns',
      notes: 'Tracks user additions of individual leads to campaigns for targeting',
      date_started: 'old',
    },
  },
  ///////////////////////////
  //        SOCIAL        //
  /////////////////////////
  SOCIAL_ACCOUNT_LOGIN_STARTED: {
    name: 'Social Account Login Started',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user starts the social account login process',
      trigger: 'User clicked on Social Account Login',
      related_screen: 'Login - Social Account',
      key_data_points: [],
      expected_outcome: 'User initiates the social account login process.',
      notes: 'This event indicates that the user has begun logging in via a social account.',
      date_started: '31.10.2024',
    },
  },

  SOCIAL_ACCOUNT_2FA: {
    name: 'Social Account 2FA Step',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user is at the 2FA step during social account login',
      trigger: 'User reached 2FA step',
      related_screen: 'Login - Social Account 2FA',
      key_data_points: [],
      expected_outcome: 'User completes the 2FA step successfully.',
      notes: 'This event indicates that the user is in the 2FA process for social account login.',
      date_started: '31.10.2024',
    },
  },

  SOCIAL_ACCOUNT_LOGIN_FAILED: {
    name: 'Social Account Login Failed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user fails to log in to their social account',
      trigger: 'User login failed',
      related_screen: 'Login - Social Account',
      key_data_points: [],
      expected_outcome: 'User fails to log in to their social account.',
      notes: 'This event indicates that the user encountered an error during social account login.',
      date_started: '31.10.2024',
    },
  },

  ACCOUNT_DETAILS_SCREEN_VIEWED: {
    name: 'Account Details Screen Viewed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user views the Account Details screen',
      trigger: 'User navigates to the screen to input account credentials',
      related_screen: 'Login - Account Details',
      key_data_points: [],
      expected_outcome: 'User is presented with the screen to enter login credentials.',
      notes: 'This event indicates that the user is on the screen to input their account details.',
      date_started: '6.11.2024',
    },
  },

  ACCOUNT_DETAILS_SCREEN_EXITED: {
    name: 'Account Details Screen Exited',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user leaves the Account Details screen',
      trigger: 'User submits account credentials or navigates away',
      related_screen: 'Login - Account Details',
      key_data_points: [],
      expected_outcome: 'User completes the login credential input process.',
      notes: 'This event is triggered when the user leaves the screen after entering credential input.',
      date_started: '6.11.2024',
    },
  },

  LOADING_SCREEN_VIEWED: {
    name: 'Loading Screen Viewed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user sees the Loading screen',
      trigger: 'User submitted account details or waits for verification',
      related_screen: 'Login - Loading',
      key_data_points: [],
      expected_outcome: 'User waits while the system verifies account details.',
      notes: 'This event indicates that the user is in a loading state waiting for further actions.',
      date_started: '6.11.2024',
    },
  },

  REQUIRE_AUTH_CODE_SCREEN_VIEWED: {
    name: 'Require Authentication Code Screen Viewed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user views the Require Authentication Code screen',
      trigger: 'System requests authentication code',
      related_screen: 'Login - Require Authentication Code',
      key_data_points: [],
      expected_outcome: 'User is prompted to enter the authentication code received.',
      notes: 'This event is triggered when the user needs to enter an authentication code.',
      date_started: '6.11.2024',
    },
  },

  REQUIRE_AUTH_CODE_SCREEN_EXITED: {
    name: 'Require Authentication Code Screen Exited',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user leaves the Require Authentication Code screen',
      trigger: 'User submits authentication code',
      related_screen: 'Login - Require Authentication Code',
      key_data_points: [],
      expected_outcome: 'User proceeds after entering the code.',
      notes: 'This event is triggered when the user submits the authentication code process.',
      date_started: '6.11.2024',
    },
  },

  REQUIRE_ANOTHER_AUTH_CODE_SCREEN_VIEWED: {
    name: 'Require Another Authentication Code Screen Viewed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user views the Require Another Authentication Code screen',
      trigger: 'System requests a new authentication code',
      related_screen: 'Login - Require Another Authentication Code',
      key_data_points: [],
      expected_outcome: 'User is prompted to enter a new authentication code received.',
      notes: 'This event is triggered when the user is asked to enter another authentication code.',
      date_started: '6.11.2024',
    },
  },

  REQUIRE_ANOTHER_AUTH_CODE_SCREEN_EXITED: {
    name: 'Require Another Authentication Code Screen Exited',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user leaves the Require Another Authentication Code screen',
      trigger: 'User submits new authentication code',
      related_screen: 'Login - Require Another Authentication Code',
      key_data_points: [],
      expected_outcome: 'User proceeds after entering the new code.',
      notes: 'This event is triggered when the user submits a new code.',
      date_started: '6.11.2024',
    },
  },

  REQUIRE_2FA_AUTH_CODE_SCREEN_VIEWED: {
    name: 'Require 2FA Authentication Code Screen Viewed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user views the Require 2FA Authentication Code screen',
      trigger: 'System requests the 2FA authentication code',
      related_screen: 'Login - Require 2FA Authentication Code',
      key_data_points: [],
      expected_outcome: 'User is prompted to enter the 2FA code received via their authentication method.',
      notes: 'This event is triggered when the user is asked to provide a two-factor authentication (2FA) code.',
      date_started: '6.11.2024',
    },
  },

  REQUIRE_2FA_AUTH_CODE_SCREEN_EXITED: {
    name: 'Require 2FA Authentication Code Screen Exited',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user leaves the Require 2FA Authentication Code screen',
      trigger: 'User submits the 2FA authentication code or navigates away from the screen',
      related_screen: 'Login - Require 2FA Authentication Code',
      key_data_points: [],
      expected_outcome: 'User proceeds after successfully entering the 2FA code or cancels the process.',
      notes:
        'This event is triggered when the user submits the 2FA code or exits the screen before completing the process.',
      date_started: '6.11.2024',
    },
  },

  REQUIRE_ANOTHER_2FA_AUTH_CODE_SCREEN_VIEWED: {
    name: 'Require Another 2FA Authentication Code Screen Viewed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user views the Another Require 2FA Authentication Code screen',
      trigger: 'System requests the 2FA authentication code',
      related_screen: 'Login - Require Another 2FA Authentication Code',
      key_data_points: [],
      expected_outcome: 'User is prompted to enter the new 2FA code received via their authentication method.',
      notes: 'This event is triggered when the user is asked to provide a new two-factor authentication (2FA) code.',
      date_started: '20.11.2024',
    },
  },

  REQUIRE_ANOTHER_2FA_AUTH_CODE_SCREEN_EXITED: {
    name: 'Require Another 2FA Authentication Code Screen Exited',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user leaves the Require Another 2FA Authentication Code screen',
      trigger: 'User submits the new 2FA authentication code or navigates away from the screen',
      related_screen: 'Login - Require 2FA Authentication Code',
      key_data_points: [],
      expected_outcome: 'User proceeds after successfully entering the new 2FA code or cancels the process.',
      notes:
        'This event is triggered when the user submits the new 2FA code or exits the screen before completing the process.',
      date_started: '20.11.2024',
    },
  },

  REQUIRE_REGISTER_PHONE_SCREEN_VIEWED: {
    name: 'Require Register Phone Screen Viewed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user views the Require Register Phone screen',
      trigger: 'System requests user to enter phone number',
      related_screen: 'Login - Require Register Phone',
      key_data_points: [],
      expected_outcome: 'User is prompted to register their phone number.',
      notes: 'This event is triggered when the user is required to register a phone number.',
      date_started: '6.11.2024',
    },
  },

  REQUIRE_REGISTER_PHONE_SCREEN_EXITED: {
    name: 'Require Register Phone Screen Exited',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user leaves the Require Register Phone screen',
      trigger: 'User submits phone number',
      related_screen: 'Login - Require Register Phone',
      key_data_points: [],
      expected_outcome: 'User proceeds after entering phone number.',
      notes: 'This event is triggered when the user submits the phone registration.',
      date_started: '6.11.2024',
    },
  },

  REQUIRE_PIN_CODE_SCREEN_VIEWED: {
    name: 'Require Pin Code Screen Viewed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user views the Require Pin Code screen',
      trigger: 'System requests the pin code for authentication',
      related_screen: 'Login - Require Pin Code',
      key_data_points: [],
      expected_outcome: 'User is prompted to enter the pin code received or set for authentication.',
      notes: 'This event is triggered when the user is asked to provide a pin code for authentication.',
      date_started: '6.11.2024',
    },
  },

  REQUIRE_PIN_CODE_SCREEN_EXITED: {
    name: 'Require Pin Code Screen Exited',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user leaves the Require Pin Code screen',
      trigger: 'User submits the pin code or navigates away from the screen',
      related_screen: 'Login - Require Pin Code',
      key_data_points: [],
      expected_outcome: 'User proceeds after successfully entering the pin code or cancels the process.',
      notes:
        'This event is triggered when the user submits the pin code or exits the screen before completing the process.',
      date_started: '6.11.2024',
    },
  },

  VERIFY_ON_APP_SCREEN_VIEWED: {
    name: 'Verify on App Screen Viewed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user views the Verify on App screen',
      trigger: 'System requests user to approve connection on app',
      related_screen: 'Login - Verify on App',
      key_data_points: [],
      expected_outcome: 'User is prompted to confirm the connection within the app.',
      notes: 'This event is triggered when the user needs to approve the connection on their app.',
      date_started: '6.11.2024',
    },
  },

  ACTIVE_SCREEN_VIEWED: {
    name: 'Active Screen Viewed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user views the Active screen',
      trigger: 'User successfully connects account',
      related_screen: 'Login - Active',
      key_data_points: [],
      expected_outcome: 'User sees confirmation that their account is connected.',
      notes: 'This event is triggered upon successful account connection.',
      date_started: '6.11.2024',
    },
  },

  ERROR_SCREEN_VIEWED: {
    name: 'Error Screen Viewed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user views the Error screen',
      trigger: 'An error occurs during the login process',
      related_screen: 'Login - Error',
      key_data_points: [],
      expected_outcome: 'User is informed about the error encountered during login.',
      notes: 'This event is triggered when the system displays an error screen due to login failure.',
      date_started: '6.11.2024',
    },
  },

  ///////////////////////////
  //        GENERAL       //
  /////////////////////////
  POINTS_USED: {
    name: 'Points Used',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user uses points',
      trigger: 'User used points',
      related_screen: 'Rewards - Points Used',
      key_data_points: [],
      expected_outcome: 'User successfully uses points.',
      notes: 'This event indicates that the user has redeemed or used points in the system.',
      date_started: '31.10.2024',
    },
  },

  MAGIC_POINTS_USED: {
    name: 'Magic Points Used',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user uses magic points',
      trigger: 'User used magic points',
      related_screen: 'Rewards - Magic Points Used',
      key_data_points: [],
      expected_outcome: 'User successfully uses magic points.',
      notes: 'This event indicates that the user has redeemed or used magic points in the system.',
      date_started: '31.10.2024',
    },
  },

  ///////////////////////////
  //      OLD EVENTS      //
  /////////////////////////
  NEXT_OR_BACK_BUTTON: {
    name: 'next or back button',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user clicks the next or back button on the campaign builder page',
      trigger: 'User clicks next or back button',
      related_screen: 'Campaign Builder',
      key_data_points: [],
      expected_outcome: 'Identify user navigation behavior in the campaign builder',
      notes: 'This event captures user interactions with navigation buttons.',
      date_started: 'old',
    },
  },

  SETTINGS: {
    name: 'settings',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user is on the settings page',
      trigger: 'User visits settings page',
      related_screen: 'Settings',
      key_data_points: [],
      expected_outcome: 'Monitor user engagement with the settings page',
      notes: 'Tracks access to the settings area.',
      date_started: 'old',
    },
  },

  UNIFIED_INBOX_USER_SENT_A_MESSAGE: {
    name: 'Unified Inbox: User Sent a Message',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user sends a message from the unified inbox page',
      trigger: 'User sends a message',
      related_screen: 'Inbox Page',
      key_data_points: [],
      expected_outcome: 'Monitor user messaging activity',
      notes: 'Captures user message-sending behavior.',
      date_started: 'old',
    },
  },

  SAVE_CHANGES: {
    name: 'Save Changes',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user saves changes while editing manually on the campaign builder page',
      trigger: 'User saves changes',
      related_screen: 'Campaign Builder Page',
      key_data_points: [],
      expected_outcome: 'Capture manual editing and saving actions',
      notes: 'Tracks manual updates to sequences.',
      date_started: 'old',
    },
  },

  UNIFIED_INBOX_FILTER_BY_REPLIED: {
    name: 'Unified Inbox: Filter by Replied',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user filters by replied messages on the unified inbox page',
      trigger: 'User filters messages by replied',
      related_screen: 'Inbox Page',
      key_data_points: [],
      expected_outcome: 'Understand user preferences for message filtering',
      notes: 'Helps analyze filtering patterns.',
      date_started: 'old',
    },
  },

  OUTREACH_GENERATION_PROMPT: {
    name: 'Outreach Generation Prompt',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose:
        'Track when a user selects a sequence type (Template, Quick Start, Build from Scratch) on the campaign builder stage',
      trigger: 'User selects sequence type',
      related_screen: 'Campaign Builder Page',
      key_data_points: [],
      expected_outcome: 'Identify user preferences for outreach sequences',
      notes: 'Tracks outreach setup choices.',
      date_started: 'old',
    },
  },

  USE_GROUPS_TO_FIND_LEADS: {
    name: 'Use Groups to Find Leads',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user clicks on engagement hub on the campaign builder stage',
      trigger: 'User clicks "Use Groups to Find Leads"',
      related_screen: 'Campaign Builder Page',
      key_data_points: [],
      expected_outcome: 'Monitor user interactions with the engagement hub',
      notes: 'This event tracks when the user initiates group-based lead finding.',
      date_started: 'old',
    },
  },

  SCHEDULING_AND_CONFIGURATION: {
    name: 'Scheduling and Configuration',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Refactor needed',
      trigger: 'User initiates "Scheduling and Configuration"',
      related_screen: 'N/A',
      key_data_points: [],
      expected_outcome: 'Pending clarification for use case',
      notes: 'Details for this event need refinement.',
      date_started: 'old',
    },
  },

  SELECT_ACCOUNT: {
    name: 'Select account',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user selects a social account during campaign building',
      trigger: 'User selects a social account',
      related_screen: 'Campaign Building Page',
      key_data_points: [],
      expected_outcome: 'Identify user interactions with social account selection',
      notes: 'This event records user account selection actions.',
      date_started: 'old',
    },
  },

  CUSTOM_SELECT_TIME: {
    name: 'Custom Select time',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user changes wait/is replied type on the campaign building page',
      trigger: 'User customizes "Select time"',
      related_screen: 'Campaign Building Page',
      key_data_points: [],
      expected_outcome: 'Monitor user interactions with custom time selection',
      notes: 'This event tracks user time customization actions.',
      date_started: 'old',
    },
  },

  PERCENTAGE_OF_OUTREACH_ACCURACY: {
    name: 'Percentage of Outreach Accuracy',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user chooses a platform in the first step of campaign building',
      trigger: 'User selects "Percentage of Outreach Accuracy"',
      related_screen: 'Campaign Building Page',
      key_data_points: [],
      expected_outcome: 'Monitor platform selection during campaign building',
      notes: 'This event captures platform choices in the first campaign step.',
      date_started: 'old',
    },
  },

  ENTRY_TO_THE_RECIPIENTS_SCREEN: {
    name: 'Entry to the Recipients screen',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user enters the campaign recipients screen',
      trigger: 'User enters "Recipients Screen"',
      related_screen: 'Campaign Dashboards Page',
      key_data_points: [],
      expected_outcome: 'Identify navigation to the recipients screen',
      notes: 'This event logs entry to the recipients page.',
      date_started: 'old',
    },
  },

  ADD_NEW_CAMPAIGN_BUTTON: {
    name: 'Add new Campaign button',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user clicks the "Add new campaign" button from the campaigns dashboard',
      trigger: 'User clicks "Add new Campaign button"',
      related_screen: 'Campaign Dashboards Page',
      key_data_points: [],
      expected_outcome: 'Monitor the creation of new campaigns',
      notes: 'This event tracks user actions to start new campaigns.',
      date_started: 'old',
    },
  },

  ENTRY_TO_THE_MESSAGES_SCREEN: {
    name: 'Entry to the Messages screen',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user navigates to the messages screen on the campaign dashboard',
      trigger: 'User enters "Messages Screen"',
      related_screen: 'Campaign Dashboards Page',
      key_data_points: [],
      expected_outcome: 'Track navigation to the messages page',
      notes: 'This event logs user entry to the messages screen.',
      date_started: 'old',
    },
  },

  UNIFIED_INBOX_FILTER_BY_SENT: {
    name: 'Unified Inbox: Filter by Sent',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user filters by "Sent" in the unified inbox page',
      trigger: 'User selects "Filter by Sent"',
      related_screen: 'Inbox Page',
      key_data_points: [],
      expected_outcome: 'Monitor filter usage in the unified inbox',
      notes: 'This event captures filtering behavior in the inbox.',
      date_started: 'old',
    },
  },

  EDIT_MANUALLY: {
    name: 'Edit Manually',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user edits the sequence manually in the campaign building screen',
      trigger: 'User selects "Edit Manually"',
      related_screen: 'Campaign Building',
      key_data_points: [],
      expected_outcome: 'Identify user interactions with manual sequence editing',
      notes: 'This event logs editing actions in the campaign builder.',
      date_started: 'old',
    },
  },

  UNIFIED_INBOX_USER_USED_A_BOOKMARK: {
    name: 'Unified Inbox: User Used a Bookmark',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user clicks a bookmark in the unified inbox page',
      trigger: 'User clicks a bookmark',
      related_screen: 'Unified Inbox Page',
      key_data_points: [],
      expected_outcome: 'Monitor bookmark usage in the unified inbox',
      notes: 'This event logs bookmark interactions in the inbox.',
      date_started: 'old',
    },
  },

  LEARNING_CENTER_USER_CLICKED_SUPPORT: {
    name: 'Learning Center: User Clicked Support',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user clicks on support in the learning center page',
      trigger: 'User selects "Support"',
      related_screen: 'Support Page',
      key_data_points: [],
      expected_outcome: 'Identify support queries via the learning center',
      notes: 'This event logs interactions with the support link.',
      date_started: 'old',
    },
  },

  IMPORT_LEADS_FROM_CSV: {
    name: 'Import Leads From CSV',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user imports leads from a CSV on the campaign building page',
      trigger: 'User selects "Import from CSV"',
      related_screen: 'Campaign Building Page',
      key_data_points: [],
      expected_outcome: 'Monitor lead import actions',
      notes: 'This event logs CSV import interactions.',
      date_started: 'old',
    },
  },

  WHO_IS_THE_PERSONA_YOU_ARE_TARGETING: {
    name: 'Who is the persona you are targeting',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user selects the targeting prompt on the campaign building page',
      trigger: 'User selects "Persona Targeting"',
      related_screen: 'Campaign Building Page',
      key_data_points: [],
      expected_outcome: 'Monitor persona targeting actions',
      notes: 'This event logs user choices for persona targeting.',
      date_started: 'old',
    },
  },

  ONBOARDING_USER_SELECTED_PLATFORMS: {
    name: 'Onboarding: User selected platforms',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user selects platforms during onboarding',
      trigger: 'User selects "Platforms"',
      related_screen: 'Onboarding',
      key_data_points: [],
      expected_outcome: 'Monitor platform selection during onboarding',
      notes: 'This event logs user choices for platforms.',
      date_started: 'old',
    },
  },

  UNIFIED_INBOX_USER_ADDED_A_BOOKMARK: {
    name: 'Unified Inbox: User Added a Bookmark',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user adds a bookmark in the unified inbox page',
      trigger: 'User adds a bookmark',
      related_screen: 'Unified Inbox Page',
      key_data_points: [],
      expected_outcome: 'Monitor bookmark additions in the unified inbox',
      notes: 'This event logs bookmark creation actions.',
      date_started: 'old',
    },
  },

  USE_AI_TO_GENERATE_LEADS: {
    name: 'Use AI to Generate Leads',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user selects "Use AI to generate leads" during campaign building',
      trigger: 'User selects "Use AI to Generate Leads"',
      related_screen: 'Campaign Building Page',
      key_data_points: [],
      expected_outcome: 'Monitor the use of AI-generated leads functionality',
      notes: 'This event logs user preferences for AI-based lead generation.',
      date_started: 'old',
    },
  },

  USE_AI_TO_GENERATE_COMPANIES_LEADS: {
    name: "Use AI to Generate Companies' Leads",
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user selects AI to generate companies’ leads in the campaign building screen',
      trigger: "User selects 'AI to Generate Companies' Leads'",
      related_screen: 'Campaign Building Page',
      key_data_points: [],
      expected_outcome: 'Monitor AI usage for generating leads',
      notes: 'This event logs user choices for AI-based company lead generation.',
      date_started: 'old',
    },
  },

  UNIFIED_INBOX_GROUP_BY_TAGS: {
    name: 'Unified Inbox: Group By: tags',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user groups messages by tags in the unified inbox page',
      trigger: 'User selects "Group By: tags"',
      related_screen: 'Inbox Page',
      key_data_points: [],
      expected_outcome: 'Monitor grouping behavior in the unified inbox',
      notes: 'This event captures the grouping of messages by tags.',
      date_started: 'old',
    },
  },

  ADD_LEADS_TO_CAMPAIGN_AI_FILTERS: {
    name: 'Add Leads to Campaign AI filters',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user uses AI filters to add leads to a campaign on the campaign building page',
      trigger: 'User selects "AI filters" to add leads',
      related_screen: 'Campaign Building Page',
      key_data_points: [],
      expected_outcome: 'Monitor the use of AI filters for adding leads',
      notes: 'This event tracks AI-based lead addition actions.',
      date_started: 'old',
    },
  },

  UNIFIED_INBOX_USER_DELETED_A_BOOKMARK: {
    name: 'Unified Inbox: User Deleted a Bookmark',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user deletes a bookmark in the unified inbox page',
      trigger: 'User deletes a bookmark',
      related_screen: 'Inbox Page',
      key_data_points: [],
      expected_outcome: 'Monitor bookmark deletion actions',
      notes: 'This event captures when a user removes bookmarks.',
      date_started: 'old',
    },
  },

  ONBOARDING_USER_CONNECTED_PLATFORMS: {
    name: 'Onboarding: User connected platforms',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user connects platforms during onboarding',
      trigger: 'User selects "Connect Platforms"',
      related_screen: 'Onboarding',
      key_data_points: [],
      expected_outcome: 'Monitor platform connection actions during onboarding',
      notes: 'This event tracks successful platform connections.',
      date_started: 'old',
    },
  },

  ONBOARDING_USER_CREATED_PERSONA: {
    name: 'Onboarding: User created persona',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user creates a persona during onboarding',
      trigger: 'User selects "Create Persona"',
      related_screen: 'Onboarding',
      key_data_points: [],
      expected_outcome: 'Monitor persona creation actions during onboarding',
      notes: 'This event logs persona setup activities.',
      date_started: 'old',
    },
  },

  LEARNING_CENTER_USER_CLICKED_VIDEO: {
    name: 'Learning Center: User Clicked Video',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user clicks a video in the learning center page',
      trigger: 'User clicks a video',
      related_screen: 'Learning Center Page',
      key_data_points: [],
      expected_outcome: 'Monitor video interactions in the learning center',
      notes: 'This event captures engagement with learning center videos.',
      date_started: 'old',
    },
  },

  ONBOARDING_USER_COMPLETED_ONBOARDING: {
    name: 'Onboarding: User completed onboarding',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user completes the onboarding process',
      trigger: 'User completes onboarding',
      related_screen: 'Onboarding',
      key_data_points: [],
      expected_outcome: 'Monitor onboarding completion rates',
      notes: 'This event tracks successful completion of onboarding.',
      date_started: 'old',
    },
  },

  UNIFIED_INBOX_MEETING_BOOK_CHANGED_TO_INTERESTED: {
    name: 'Unified Inbox: Meeting Book Changed to: interested',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a meeting booking is changed to "interested" in the unified inbox page',
      trigger: 'User updates meeting status to "interested"',
      related_screen: 'Inbox Page',
      key_data_points: [],
      expected_outcome: 'Monitor changes in meeting booking status',
      notes: 'This event tracks when users update meeting bookings.',
      date_started: 'old',
    },
  },

  LEARNING_CENTER_USER_CLICKED_ARTICLE: {
    name: 'Learning Center: User Clicked Article',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user clicks an article in the learning center page',
      trigger: 'User clicks an article',
      related_screen: 'Learning Center Page',
      key_data_points: [],
      expected_outcome: 'Monitor article interactions in the learning center',
      notes: 'This event captures engagement with learning center articles.',
      date_started: 'old',
    },
  },

  ADD_ACCOUNT: {
    name: 'Add Account',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user adds an account on the settings page',
      trigger: 'User adds an account',
      related_screen: 'Settings Page',
      key_data_points: [],
      expected_outcome: 'Monitor account addition on the settings page',
      notes: 'This event tracks when users add accounts on the settings page.',
      date_started: 'old',
    },
  },

  ONBOARDING_USER_SKIPPED_SELECTING_GROUPS: {
    name: 'Onboarding: User skipped selecting groups',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user skips selecting groups during onboarding',
      trigger: 'User skips selecting groups',
      related_screen: 'Onboarding',
      key_data_points: [],
      expected_outcome: 'Monitor instances where users skip the group selection during onboarding',
      notes: 'This event tracks when users bypass selecting groups in the onboarding flow.',
      date_started: 'old',
    },
  },

  LEARNING_CENTER_USER_CLICKED_GETTING_STARTED: {
    name: 'Learning Center: User Clicked Getting Started',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user clicks the Getting Started button on the Learning Center page',
      trigger: 'User clicks Getting Started',
      related_screen: 'Learning Center page',
      key_data_points: [],
      expected_outcome: 'Monitor how many users interact with the Getting Started section of the Learning Center',
      notes: 'This event tracks user engagement with the Getting Started section in the Learning Center.',
      date_started: 'old',
    },
  },

  UNIFIED_INBOX_GROUP_BY_MEETING_BOOK: {
    name: 'Unified Inbox: Group By: meeting_book',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user groups inbox messages by meeting book on the unified inbox page',
      trigger: 'User groups by meeting book',
      related_screen: 'Inbox page',
      key_data_points: [],
      expected_outcome: 'Monitor how users interact with grouping messages by meeting book',
      notes: 'This event tracks when messages are grouped by meeting book in the unified inbox.',
      date_started: 'old',
    },
  },

  SET_ACTIVE_CAMPAIGN: {
    name: 'Set Active Campaign',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user sets an active campaign on the campaign dashboard page',
      trigger: 'User sets active campaign',
      related_screen: 'Campaigns',
      key_data_points: [''],
      expected_outcome: 'Monitor campaign activation on the dashboard',
      notes: 'This event tracks the action of setting a campaign as active on the campaign dashboard.',
      date_started: 'old',
    },
  },

  ONBOARDING_USER_SELECTED_GROUPS: {
    name: 'Onboarding: User selected groups',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user selects groups during onboarding',
      trigger: 'User selects groups',
      related_screen: 'Onboarding',
      key_data_points: [],
      expected_outcome: 'Monitor group selection during onboarding',
      notes: 'This event tracks when users select groups as part of the onboarding process.',
      date_started: 'old',
    },
  },

  UNIFIED_INBOX_GROUP_BY_CATEGORIES: {
    name: 'Unified Inbox: Group By: categories',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user groups inbox messages by categories on the unified inbox page',
      trigger: 'User groups by categories',
      related_screen: 'Inbox page',
      key_data_points: [],
      expected_outcome: 'Monitor how users group messages by categories in the inbox',
      notes: 'This event tracks when messages are grouped by categories in the unified inbox.',
      date_started: 'old',
    },
  },

  GENERATE_TEMPLATE_CAMPAIGN: {
    name: 'Generate Template Campaign',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user generates a template campaign on the campaign building page',
      trigger: 'User generates template campaign',
      related_screen: 'Campaign building page',
      key_data_points: [],
      expected_outcome: 'Monitor usage of the campaign template generation feature',
      notes:
        'This event tracks when a user chooses to generate a template campaign during the campaign building process.',
      date_started: 'old',
    },
  },

  BUY_CONVRT_COMPLETED: {
    name: 'Buy Convrt Completed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user completes a purchase of Convrt on the pricing page',
      trigger: 'User completes Convrt purchase',
      related_screen: 'Upgrade',
      key_data_points: [],
      expected_outcome: 'Monitor completed purchases of Convrt from the pricing page',
      notes: 'This event tracks the completion of a Convrt purchase on the pricing page.',
      date_started: 'old',
    },
  },

  UNIFIED_INBOX_CHAT_PINNED: {
    name: 'Unified Inbox: Chat Pinned',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a chat is pinned on the unified inbox page',
      trigger: 'User pins chat in inbox',
      related_screen: 'Inbox page',
      key_data_points: [],
      expected_outcome: 'Monitor user interaction with chat pinning in the unified inbox',
      notes: 'This event tracks when a user pins a chat in the unified inbox.',
      date_started: 'old',
    },
  },

  UNIFIED_INBOX_CHAT_UNPINNED: {
    name: 'Unified Inbox: Chat Unpinned',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a chat is unpinned on the unified inbox page',
      trigger: 'User unpins chat in inbox',
      related_screen: 'Inbox page',
      key_data_points: [],
      expected_outcome: 'Monitor user interaction with chat pinning in the unified inbox',
      notes: 'This event tracks when a user unpins a chat in the unified inbox.',
      date_started: 'old',
    },
  },

  UNIFIED_INBOX_GROUP_BY_CAMPAIGN_NAME: {
    name: 'Unified Inbox: Group By: campaign_name',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user groups messages by campaign name on the unified inbox page',
      trigger: 'User groups messages by campaign name',
      related_screen: 'Inbox page',
      key_data_points: [],
      expected_outcome: 'Monitor user interaction with campaign name-based grouping in the inbox',
      notes: 'This event tracks when a user groups messages by campaign name in the unified inbox.',
      date_started: 'old',
    },
  },

  UNIFIED_INBOX_GROUP_BY_PRIORITY: {
    name: 'Unified Inbox: Group By: priority',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user groups messages by priority on the unified inbox page',
      trigger: 'User groups messages by priority',
      related_screen: 'Inbox page',
      key_data_points: [],
      expected_outcome: 'Monitor how users group messages based on priority in the inbox',
      notes: 'This event tracks when messages are grouped by priority (high, medium, low) in the unified inbox.',
      date_started: 'old',
    },
  },

  BROWSE_IMPORT_FROM_CSV: {
    name: 'Browse - Import From CSV',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user imports data from a CSV file on the csv importer',
      trigger: 'User imports from CSV',
      related_screen: 'CSV Importer page',
      key_data_points: [],
      expected_outcome: 'Monitor usage of CSV import in campaign building',
      notes: 'This event tracks when users import campaign data via CSV.',
      date_started: 'old',
    },
  },

  UNIFIED_INBOX_PRIORITY_CHANGED_TO_LOW: {
    name: 'Unified Inbox: Priority Changed to: low',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when the priority of a message is changed to low on the unified inbox page',
      trigger: 'User changes message priority to low',
      related_screen: 'Inbox page',
      key_data_points: [],
      expected_outcome: 'Monitor changes to message priority to low',
      notes: 'This event tracks when a message’s priority is changed to low in the unified inbox.',
      date_started: 'old',
    },
  },

  UNIFIED_INBOX_PRIORITY_CHANGED_TO_HIGH: {
    name: 'Unified Inbox: Priority Changed to: high',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when the priority of a message is changed to high on the unified inbox page',
      trigger: 'User changes message priority to high',
      related_screen: 'Inbox page',
      key_data_points: [],
      expected_outcome: 'Monitor changes to message priority to high',
      notes: 'This event tracks when a message’s priority is changed to high in the unified inbox.',
      date_started: 'old',
    },
  },

  USER_STARTS_CAMPAIGN: {
    name: 'User Starts a Campaign',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when the user starts a campaign.',
      trigger: 'User starts campaign.',
      related_screen: 'Campaigns',
      key_data_points: [],
      expected_outcome: 'The campaign has started.',
      notes: 'This event tracks instances when users attempt to start a campaign.',
      date_started: 'old',
    },
  },

  SALES_NAVIGATOR: {
    name: 'Sales navigator',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user accesses the Sales Navigator feature',
      trigger: 'User accesses Sales Navigator',
      related_screen: 'Groups',
      key_data_points: [],
      expected_outcome: 'Monitor user interactions with Sales Navigator',
      notes: 'This event tracks user interaction with the Sales Navigator feature.',
      date_started: 'old',
    },
  },

  IMPORT_CUSTOM_LEADS_FB_LNKD_SEARCH_INSTA: {
    name: 'Import Custom leads (fb, lnkd search, instagram)',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when custom leads are imported from Facebook, LinkedIn search, or Instagram',
      trigger: 'User imports custom leads from Facebook, LinkedIn search, or Instagram',
      related_screen: 'Groups',
      key_data_points: [],
      expected_outcome: 'Monitor the import of custom leads from various social platforms',
      notes:
        'This event tracks the import of custom leads from social media sources like Facebook, LinkedIn search, and Instagram.',
      date_started: 'old',
    },
  },

  SOCIAL_ACCOUNT_2FA_STEP: {
    name: 'Social account 2fa step',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user reaches the two-factor authentication step during social account login',
      trigger: 'User reaches social account 2FA step',
      related_screen: 'Social Login Modal',
      key_data_points: [],
      expected_outcome: 'Monitor social account login progress during 2FA',
      notes: 'This event tracks when a user reaches the 2FA step during social login.',
      date_started: 'old',
    },
  },
}

export const SOCIAL_MAPPER: any = {
  incoming: 'ACCOUNT_DETAILS_SCREEN_VIEWED',
  require_check_signedin_app: 'VERIFY_ON_APP_SCREEN_VIEWED',
  verify_on_app: 'VERIFY_ON_APP_SCREEN_VIEWED',
  active: 'ACTIVE_SCREEN_VIEWED',
  pin_code_required: 'REQUIRE_PIN_CODE_SCREEN_VIEWED',
  require_register_phone: 'REQUIRE_REGISTER_PHONE_SCREEN_VIEWED',
  require_authentication_code: 'REQUIRE_AUTH_CODE_SCREEN_VIEWED',
  require_another_authentication_code: 'REQUIRE_ANOTHER_AUTH_CODE_SCREEN_VIEWED',
  require_2fa_code: 'REQUIRE_2FA_AUTH_CODE_SCREEN_VIEWED',
  require_another_2fa_code: 'REQUIRE_ANOTHER_2FA_AUTH_CODE_SCREEN_VIEWED',
  error: 'ERROR_SCREEN_VIEWED',
}

// Function to log an event
export const trackEvent = (eventKey: keyof typeof EVENTS, additionalData?: any) => {
  const event = EVENTS[eventKey]
  if (!event) {
    throw new Error(`Event with key "${eventKey}" is not defined.`)
  }

  logEvent(event.name, event.event_source, event.convrt_internal_description, additionalData)
}
