import visible_eye_icon from 'common/assets/svg/visible_eye_icon.svg'
import hidden_eye_icon from 'common/assets/svg/hidden_eye_icon.svg'

import React, { useEffect, useState } from 'react'
import { Modal, Button, Layout, Divider, Tabs, message, Tooltip } from 'antd'
import styles from './ImportLeadsFromGroupsModal.module.scss'
import {
  useLazyGetGroupDetailsQuery,
  useLazyGetGroupsQuery,
  useLazyGetSavedFiltersQuery,
} from 'common/components/OutreachAI/state/api/OutreachAI-API'
import GroupsSidebar from './GroupsSidebar'
import GroupsContent from './GroupsContent'
import GroupsFilters from './GroupsFilters'
import GroupsTable from './GroupsTable'
import { useAppDispatch, useAppSelector } from 'state'
import {
  SET_ARE_LEADS_FROM_GROUPS,
  SET_INITIAL_GROUP_DATA,
  SET_SELECTED_LEADS,
} from 'common/components/OutreachAI/state/outreachAICampaignSlice'
import { useLazySaveCampaignLeadsQuery } from 'features/Outreach/state/api/OutreachApi'
import { capitalize } from 'lodash'

const { Header } = Layout
const { TabPane } = Tabs

const sortData = (data: any[], sortKey: string, ascending: boolean = true) => {
  if (!data) return []
  return data?.sort((a, b) => {
    if (a[sortKey] < b[sortKey]) return ascending ? -1 : 1
    if (a[sortKey] > b[sortKey]) return ascending ? 1 : -1
    return 0
  })
}

const filterOutExistingGroups = (requestedGroups: any, displayedGroups: any) => {
  const normalize = (str: any) =>
    String(str || '')
      .toLowerCase()
      .trim()

  const displayedGroupKeys = new Set(
    displayedGroups?.map((group: any) => `${normalize(group.platform)}}-${normalize(group.username)}`),
  )

  return requestedGroups?.filter(
    (group: any) => !displayedGroupKeys.has(`${normalize(group.platform)}}-${normalize(group.username)}`),
  )
}

interface ImportLeadsFromGroupsModalProps {
  open: boolean
  onClose: () => void
  isLoading: boolean
}

const ImportLeadsFromGroupsModal: React.FC<ImportLeadsFromGroupsModalProps> = ({ open, onClose, isLoading }) => {
  const [loadingAdd, setLoadingAdd] = useState(false)
  const [saveCampaignLeads] = useLazySaveCampaignLeadsQuery()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const { initial_group_data: groupsData } = useAppSelector((state) => state.outreachAICampaign)
  const [getGroupDetails, { isFetching: isGroupDataFetching }] = useLazyGetGroupDetailsQuery()
  const [getSavedFilters, { data: savedFilters }] = useLazyGetSavedFiltersQuery()
  const [getGroups] = useLazyGetGroupsQuery()
  const [groupData, setGroupData] = useState<any>()
  const [loadingLeads, setLoadingLeads] = useState(false)
  const [showNewLeads, setShowNewLeads] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const [selectedChannel, setSelectedChannel] = useState<string>('')
  const [selectedGroup, setSelectedGroup] = useState<any>('')
  const [selectedGroupName, setSelectedGroupName] = useState('')
  const [groupType, setGroupType] = useState('')
  const { selected_leads, active_platforms, campaign_id } = useAppSelector((state) => state.outreachAICampaign)
  const [filteredData, setFilteredData] = useState(groupData)
  const [smallerThan, setSmallerThan] = useState<number | undefined>(0)
  const [greaterThan, setGreaterThan] = useState<number | undefined>(1000000)
  const dispatch = useAppDispatch()
  const [initialLoading, setInitialLoading] = useState(true)
  const [activeTabKey, setActiveTabKey] = useState('1')
  const [selectedTags, setSelectedTags] = useState<any>([])
  // For now. Change when implementing multi-channel campaigns
  const truePlatformsArray = Object.entries(active_platforms)
    .filter(([key, value]) => value)
    .map(([key, value]) => key)
  const selectedPlatform = truePlatformsArray?.[0]
  const handleCategoryChange = (category: string) => {
    // setSelectedChannel('')
    setSelectedCategory((prevCat) => (prevCat === category ? '' : category))
  }

  const handleChannelChange = (channel: string) => {
    // setSelectedCategory('')
    setSelectedChannel((prevChannel) => (prevChannel === channel ? '' : channel))
  }

  const onBack = () => {
    setSelectedGroup('')
    setSelectedGroupName('')
    setGroupData([])
  }

  // const getGroupsByPlatform = (platform: string, groupsData: any) => {
  //   return groupsData.data?.filter((group: any) => group.platform.toLowerCase() === platform.toLowerCase())
  //   // return {
  //   //   category: platform,
  //   //   data:,

  //   //   myGroups: groupsData.flatMap((groupData: any) => groupData?.myGroups),
  //   //   requestedGroups: groupsData.flatMap((groupData: any) =>
  //   //     groupData?.requestedGroups.filter((group: any) => group.platform.toLowerCase() === platform.toLowerCase()),
  //   //   ),
  //   // }
  // }

  // const displayedGroups = selectedChannel
  //   ? getGroupsByPlatform(selectedChannel, groupsData)
  //   : groupsData?.data?.filter((data: any) => !selectedCategory || data.tags?.includes(selectedCategory))

  const displayedGroups = groupsData?.data?.filter((data: any) => {
    const matchesCategory = !selectedCategory || data.tags?.includes(selectedCategory)
    const matchesChannel = !selectedChannel || data.platform?.toLowerCase() === selectedChannel.toLowerCase()
    return matchesCategory && matchesChannel
  })

  const handleAddLeads = async () => {
    setLoadingAdd(true)
    const selectedData = groupData.filter((d: any) => selectedRowKeys.includes(d.key))
    const newLeadsToAdd = selectedData.filter(
      (newLead: any) => !selected_leads.some((existingLead: any) => existingLead.key === newLead.key),
    )
    let source = newLeadsToAdd[0]?.followed_username
    if (!source) {
      source = selectedGroupName
    }
    const leadsForCampaign = newLeadsToAdd?.map((lead: any) => ({
      ...lead,
      platform: selectedPlatform,
      convrt_source: 'social_hub',
      categories: [lead?.category],
      source: source,
    }))
    dispatch(SET_ARE_LEADS_FROM_GROUPS(true))
    dispatch(SET_SELECTED_LEADS(leadsForCampaign))
    await saveCampaignLeads({
      leads: leadsForCampaign,
      campaign_id: campaign_id,
      mode: 'groups',
    })
    setSelectedRowKeys([])
    setLoadingAdd(false)
    onClose()
  }

  const checkFieldsForKeywords = (group: any, keyword: string) => {
    const lowerKeyword = keyword.toLowerCase()

    if (selectedPlatform === 'linkedin') {
      return (
        group?.biography?.toLowerCase().includes(lowerKeyword) ||
        group?.location?.toLowerCase().includes(lowerKeyword) ||
        group?.skills?.toLowerCase().includes(lowerKeyword) ||
        group?.company?.toLowerCase().includes(lowerKeyword) ||
        group?.experience?.toLowerCase().includes(lowerKeyword) ||
        group?.connection_level?.toLowerCase().includes(lowerKeyword) ||
        group?.headline?.toLowerCase().includes(lowerKeyword) ||
        group?.position?.toLowerCase().includes(lowerKeyword)
      )
    }

    return group?.biography?.toLowerCase().includes(lowerKeyword)
  }

  const handleFilterChange = ({ keywords, operator, conditionedKeywords }: any) => {
    let filtered = groupData

    if (keywords.length > 0) {
      filtered = filtered.filter((group: any) =>
        keywords.some((keyword: any) => checkFieldsForKeywords(group, keyword)),
      )
    }

    if (conditionedKeywords.length > 0) {
      if (operator === 'AND') {
        filtered = filtered.filter((group: any) =>
          conditionedKeywords.every((keyword: any) => checkFieldsForKeywords(group, keyword)),
        )
      } else if (operator === 'AND NOT') {
        filtered = filtered.filter((group: any) =>
          conditionedKeywords.every((keyword: any) => !checkFieldsForKeywords(group, keyword)),
        )
      }
    }

    if (
      selectedPlatform === 'instagram' &&
      smallerThan !== undefined &&
      smallerThan !== null &&
      typeof smallerThan === 'number' &&
      smallerThan >= 0
    ) {
      filtered = filtered?.filter((group: any) => group.follower_count && group.follower_count >= smallerThan)
    }

    if (
      selectedPlatform === 'instagram' &&
      greaterThan !== undefined &&
      greaterThan !== null &&
      typeof greaterThan === 'number' &&
      greaterThan >= 0
    ) {
      filtered = filtered?.filter((group: any) => group.follower_count && group.follower_count <= greaterThan)
    }
    if (selectedTags?.length > 0) {
      filtered = filtered.filter((lead: any) => selectedTags.includes(lead.category))
    }
    setFilteredData(filtered)
  }

  const handleOnLeads = async () => {
    if (showNewLeads === false) {
      setShowNewLeads(true)
      setLoadingLeads(true)
      const { data } = await getGroupDetails({ group: selectedGroup, showNewLeads: true })
      setGroupData(data)
      setFilteredData(data)
      setLoadingLeads(false)
    } else {
      setShowNewLeads(false)
      const { data } = await getGroupDetails({ group: selectedGroup })
      setGroupData(data)
      setFilteredData(data)
    }
  }

  const handleSelectGroup = (group: any) => {
    if (group.platform === selectedPlatform) {
      setSelectedGroup(group)
    } else {
      message.error(`You can only select leads from ${capitalize(selectedPlatform)} for this campaign.`)
    }
  }

  const handleCustomImport = () => {
    setActiveTabKey('1')
  }

  useEffect(() => {
    const groupDetails = async () => {
      await getSavedFilters()
      const { data } = await getGroupDetails({ group: selectedGroup, showNewLeads: showNewLeads })
      if (selectedGroup?.type) {
        setGroupType(selectedGroup?.type)
      }
      setGroupData(data)
      setFilteredData(data)
    }

    if (selectedGroup) {
      groupDetails()
    }
  }, [selectedGroup])

  useEffect(() => {
    const getAllGroups = async () => {
      const { data } = await getGroups({})
      if (initialLoading) {
        setInitialLoading(false)
      }
      dispatch(SET_INITIAL_GROUP_DATA(data))
    }

    const intervalId = setInterval(() => {
      getAllGroups()
    }, 5000)

    return () => clearInterval(intervalId)
  }, [])

  const headerStyle: React.CSSProperties = {
    color: 'black',
    fontWeight: 600,
    fontSize: '18px',
    height: 80,
    paddingInline: 24,
    lineHeight: '64px',
    backgroundColor: 'white',
    borderBottom: '1px solid #DFE1E6',
    alignContent: 'center',
  }

  const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: 'white',
    height: 'calc(100vh - 210px)',
  }

  return (
    <Modal
      className='groupsModal'
      centered
      closable={false}
      open={open}
      footer={null}
      onCancel={onClose}
      width='87%'
      maskClosable={false}>
      {selectedGroup ? (
        <Layout style={layoutStyle}>
          <Header style={headerStyle}>
            <div className={styles.header}>
              <span>
                Add Leads From {selectedGroupName || 'N/A'} <Divider type='vertical' />{' '}
                <span style={{ fontSize: '14px' }}>
                  {selectedRowKeys?.length || 0} leads <span style={{ fontWeight: 500 }}>were selected</span>
                </span>
              </span>
              <div className={styles.buttons}>
                <span style={{ fontSize: '12px', color: '#7043ff', cursor: 'pointer' }} onClick={onClose}>
                  Cancel
                </span>
                <span style={{ fontSize: '12px', color: '#7043ff', cursor: 'pointer' }} onClick={onBack}>
                  Back
                </span>
                <Button
                  loading={loadingAdd}
                  type='primary'
                  onClick={handleAddLeads}
                  disabled={!selectedRowKeys.length || isGroupDataFetching}>
                  Add Leads
                </Button>
              </div>
            </div>
          </Header>
          {/* <div className={styles.container}>
                <Layout style={{ width: '100%', height: '100%' }}>
                  <Layout.Content>
                    <GroupsTable
                      selectedGroup={selectedGroupName}
                      data={filteredData}
                      groupType={groupType}
                      isLoading={isGroupDataFetching}
                      selectedRowKeys={selectedRowKeys}
                      setSelectedRowKeys={setSelectedRowKeys}
                      onLeads={handleOnLeads}
                      loadingLeads={loadingLeads}
                      showNewLeads={showNewLeads}
                    />
                  </Layout.Content>
                  <GroupsFilters
                    onFilterChange={handleFilterChange}
                    selectedPlatform={selectedPlatform}
                    groupType={groupType}
                    smallerThan={smallerThan}
                    setSmallerThan={setSmallerThan}
                    greaterThan={greaterThan}
                    setGreaterThan={setGreaterThan}
                    data={groupData}
                    tagsSelected={selectedTags}
                    setTagsSelected={setSelectedTags}
                    savedFilters={savedFilters}
                    getSavedFilters={getSavedFilters}
                  />
                </Layout>
              </div> */}
          <div className={styles.title}>
            <span>
              Displaying{' '}
              <span style={{ fontWeight: 600 }}>all of {filteredData?.length?.toLocaleString()} available leads</span>{' '}
              from {selectedGroupName}
            </span>
            <Tooltip title={`${showNewLeads ? 'Show' : 'Hide'} leads from my other campaigns`}>
              <img
                className={styles.hiddenEye}
                onClick={handleOnLeads}
                src={showNewLeads ? hidden_eye_icon : visible_eye_icon}
                alt=''
              />
            </Tooltip>
          </div>

          <Layout
            style={{
              backgroundColor: 'white',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              gap: 10,
              alignSelf: 'stretch',
            }}>
            <Layout.Content
              style={{
                padding: 0,
                margin: 0,
                backgroundColor: 'white',
              }}>
              <GroupsTable
                selectedGroup={selectedGroupName}
                data={filteredData}
                groupType={groupType}
                isLoading={isGroupDataFetching}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                onLeads={handleOnLeads}
                loadingLeads={loadingLeads}
                showNewLeads={showNewLeads}
              />
            </Layout.Content>
            {/* <RawFilters onFilterChange={() => {}} /> */}
            <GroupsFilters
              onFilterChange={handleFilterChange}
              selectedPlatform={selectedPlatform}
              groupType={groupType}
              smallerThan={smallerThan}
              setSmallerThan={setSmallerThan}
              greaterThan={greaterThan}
              setGreaterThan={setGreaterThan}
              data={groupData}
              tagsSelected={selectedTags}
              setTagsSelected={setSelectedTags}
              savedFilters={savedFilters}
              getSavedFilters={getSavedFilters}
            />
          </Layout>
        </Layout>
      ) : (
        <Layout style={layoutStyle}>
          <Header style={headerStyle}>
            <div className={styles.header}>
              <span>Social Hub</span>
              <Tabs activeKey={activeTabKey} onChange={setActiveTabKey}>
                <TabPane tab={<span>My Hub</span>} key='1' />
                <TabPane tab='Convrt Market' key='2' />
              </Tabs>
              <span style={{ fontSize: '12px', color: '#7043ff', cursor: 'pointer' }} onClick={onClose}>
                Cancel
              </span>
            </div>
          </Header>
          <Layout>
            <GroupsSidebar
              categories={
                selectedChannel
                  ? groupsData?.categories?.[selectedChannel.toLocaleLowerCase()]
                  : Object.values(groupsData?.categories || {}).flat()
              }
              onCategoryChange={handleCategoryChange}
              onChannelChange={handleChannelChange}
              myGroups={activeTabKey === '1'}
            />
            <GroupsContent
              initialLoading={initialLoading}
              groupsData={sortData(displayedGroups, 'name', true)}
              selectedCategory={selectedCategory}
              selectedChannel={selectedChannel}
              setSelectedGroup={handleSelectGroup}
              setSelectedGroupName={setSelectedGroupName}
              myGroupsTab={activeTabKey === '1'}
              myGroupsData={groupsData?.myGroups?.filter((group: any) => group?.trackMode)}
              requestedGroups={filterOutExistingGroups(groupsData?.requestedGroups, displayedGroups)}
              handleCustomImport={handleCustomImport}
            />
          </Layout>
        </Layout>
      )}
    </Modal>
  )
}

export default ImportLeadsFromGroupsModal
