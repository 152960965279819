import React, { useEffect } from 'react'
import styles from './MainMessages.module.scss'
import MainMessagesTable from '../Tables/MessagesTable'
import { useLazyGetCampaignsMessagesQuery } from 'features/Outreach/state/api/OutreachApi'
import { useAppSelector } from 'state'
import { trackEvent } from 'eventConfig'

const MainMessages: React.FC = () => {
  const [getMessages, { data: campaignsMessagesData, isLoading }] = useLazyGetCampaignsMessagesQuery()
  const { campaign_filters, campaign_date_filters } = useAppSelector((state) => state.outreachCampaign)

  useEffect(() => {
    const getCampaignsMessages = async () => {
      await getMessages()
    }
    getCampaignsMessages()

    trackEvent('ENTRY_TO_THE_MESSAGES_SCREEN')
  }, [])

  const handleCampaignFilters = (data: any) => {
    const filterType = campaign_filters?.type
    const filterValue = campaign_filters?.value

    const fromDate = campaign_date_filters?.from
    const toDate = campaign_date_filters?.to

    let filteredData = data
    if (fromDate && toDate) {
      filteredData = filteredData.filter((item: any) => {
        const campaignDate = new Date(item.sent_time)
        return campaignDate >= fromDate && campaignDate <= toDate
      })
    }

    if (!filterType || !filterValue || filterValue?.length === 0 || !data) {
      return filteredData
    }
    switch (filterType) {
      case 'Senders':
        return filteredData.filter((item: any) => {
          const senders = JSON.parse(item?.senders)?.map((sender: any) => sender.id)
          return senders?.some((senderId: any) => filterValue.includes(senderId))
        })
      case 'Campaign Name':
        return filteredData.filter((item: any) => filterValue.includes(item.campaign_id))
      case 'Last Step':
        return filteredData.filter((item: any) => filterValue.includes(item.step_type))
      default:
        return filteredData
    }
  }

  return (
    <div className={styles.container}>
      {/* <MainCampaignHeader
        totalCampaigns={totalCampaigns}
        sentMessages={sentMessages}
        repliedMessages={repliedMessages}
        meetingsBooked={meetingsBooked}
      /> */}
      <div className={styles.table}>
        {/* <MainMessagesTable data={TABLE_DUMMY_DATA} /> */}
        {/* {isLoading ? <LoadingBox /> : <MainMessagesTable data={campaignsMessagesData?.response || []} />} */}
        {/* {isLoading ? <Skeleton active /> : <MainMessagesTable  data={campaignsMessagesData?.response || []}  isLoading={isLoading}/>} */}
        <MainMessagesTable
          data={campaignsMessagesData ? handleCampaignFilters(campaignsMessagesData) : []}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default MainMessages
