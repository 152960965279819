export const getSendRate = (key: string, plan: any) => {
  const sendRate = plan.features
    .filter((f: any) => f.name === 'Send rate')?.[0]
    ?.value?.[key].replace('Unique Contacts', '')
    .replace(' ', '')
  const toNum = Number(sendRate)
  if (isNaN(toNum)) {
    return sendRate
  }
  let formatter = Intl.NumberFormat('en', { notation: 'compact' })
  return formatter.format(sendRate)
}

export const getLeads = (option: any) => {
  let formatter = Intl.NumberFormat('en', { notation: 'compact' })
  const leads = option?.points
  const toNum = Number(leads * 2)
  return formatter.format(toNum)
}

export const numberToRoman = (number: number) => {
  let num = number
  const romanNumerals: any = [
    ['M', 1000],
    ['CM', 900],
    ['D', 500],
    ['CD', 400],
    ['C', 100],
    ['XC', 90],
    ['L', 50],
    ['XL', 40],
    ['X', 10],
    ['IX', 9],
    ['V', 5],
    ['IV', 4],
    ['I', 1],
  ]

  let result = ''
  for (let i = 0; i < romanNumerals.length; i++) {
    while (num >= romanNumerals[i][1]) {
      result += romanNumerals[i][0]
      num -= romanNumerals[i][1]
    }
  }
  return result
}
