export interface InitialStep {
  step_id: string
  previous_step_id: string | null
  type: string
  data?: {
    unit?: string
    amount?: number
    messages?: string[]
    sender?: string[]
    disabled?: boolean
  }
}

export interface Condition {
  type: string
  minutes: number
  next_step_id: number
}

export interface NewStep {
  step_id: number
  previous_step_id: number | null
  type: string
  conditions_to_next_step: Condition[]
  message_id?: string
  data?: any
}

// Helper function for mapping old types to new format based on the platform
const mapTypeToNewFormat = (type: string, platform: string): string => {
  const typeMapping: { [platform: string]: { [type: string]: any } } = {
    instagram: {
      follow_lead: 'instagram_follow',
      unfollow_lead: 'instagram_unfollow',
      is_wait: 'instagram_wait_after',
      lead_accepted_follow: {
        '1': 'instagram_not_follow_accepted_after',
        '2': 'instagram_follow_accepted_after',
      },
      lead_accepted: {
        // legacy
        '1': 'instagram_not_follow_accepted_after',
        '2': 'instagram_follow_accepted_after',
      },
      // lead_accepted_connect: {},
      like_post: 'instagram_like_post',
      send_message: 'instagram_send_message',
      ai_responder: 'instagram_auto_reply',
      // is_message_replied: 'instagram_reply_after',
      // is_message_replied: 'instagram_not_reply_after',
      is_message_replied: {
        '1': 'instagram_not_reply_after',
        '2': 'instagram_reply_after',
      },
      comment_on_post: 'instagram_send_comment',
      comment_on_story: 'instagram_send_comment',
      comment_on_photo: 'instagram_send_comment',
    },
    facebook: {
      follow_lead: 'facebook_follow',
      unfollow_lead: 'facebook_unfollow',
      connect_lead: 'facebook_addfriend',
      disconnect_lead: 'facebook_unfriend',
      is_wait: 'facebook_wait_after',
      lead_accepted: {
        // legacy
        '1': 'facebook_not_follow_accepted_after',
        '2': 'facebook_follow_accepted_after',
      },
      lead_accepted_follow: {
        '1': 'facebook_not_follow_accepted_after',
        '2': 'facebook_follow_accepted_after',
      },
      lead_accepted_connect: {
        '1': 'facebook_not_addfriend_accepted_after',
        '2': 'facebook_addfriend_accepted_after',
      },
      like_post: 'facebook_like_post',
      send_message: 'facebook_send_message',
      ai_responder: 'facebook_auto_reply',
      // is_message_replied: 'facebook_reply_after',
      is_message_replied: {
        '1': 'facebook_not_reply_after',
        '2': 'facebook_reply_after',
      },
      comment_on_post: 'facebook_send_comment',
      comment_on_story: 'facebook_send_comment',
      comment_on_photo: 'facebook_send_comment',
    },
    linkedin: {
      follow_lead: 'linkedin_follow',
      connect_lead: 'linkedin_connect',
      connect_lead_with_note: 'linkedin_connect',
      disconnect_lead: 'linkedin_disconnect',
      unfollow_lead: 'linkedin_unfollow',
      is_wait: 'linkedin_wait_after',
      lead_accepted: {
        // legacy
        '1': 'linkedin_not_connected_after',
        '2': 'linkedin_connected_after',
      },
      lead_accepted_follow: {
        '1': 'linkedin_wait_after',
        '2': 'linkedin_wait_after',
      },
      lead_accepted_connect: {
        '1': 'linkedin_not_connected_after',
        '2': 'linkedin_connected_after',
      },
      like_post: 'linkedin_like_post',
      send_message: 'linkedin_send_message',
      ai_responder: 'linkedin_auto_reply',
      // is_message_replied: 'linkedin_not_reply_after',
      // is_message_replied: 'linkedin_reply_after',
      is_message_replied: {
        '1': 'linkedin_not_reply_after',
        '2': 'linkedin_reply_after',
      },
      comment_on_post: 'linkedin_send_comment',
      comment_on_story: 'linkedin_send_comment',
      comment_on_photo: 'linkedin_send_comment',
    },
  }
  return typeMapping?.[platform]?.[type] || type
}

const stepOrCondition = (type: string) => {
  const typeMapping: any = {
    comment_on_photo: 'step',
    comment_on_story: 'step',
    comment_on_post: 'step',
    unfollow_lead: 'step',
    send_message: 'step',
    ai_responder: 'step',
    follow_lead: 'step',
    connect_lead: 'step',
    connect_lead_with_note: 'step',
    disconnect_lead: 'step',
    like_post: 'step',
    is_message_replied: 'condition',
    lead_accepted: 'condition_split',
    lead_accepted_follow: 'condition_split',
    lead_accepted_connect: 'condition_split',
    is_wait: 'condition',
  }
  return typeMapping[type] || type
}

const createCategory = (data: any) => {
  return data?.responders
    ?.filter((item: any) => item.isActive) // Filter objects with isActive = true
    ?.map((item: any) => item.id) // Return an array of ids
}

// Helper function to calculate minutes from the "data" property (1 day = 1440 minutes, ...)
const calculateMinutes = (unit: string, amount: number): number => {
  if (unit === 'hour') {
    return amount * 60
  } else if (unit === 'day') {
    return amount * 1440
  } else if (unit === 'week') {
    return amount * 1440 * 7
  } else {
    return amount
  }
}

// const findChildren = (initialSteps: any, stepId: string) => {
//   return initialSteps.filter((step: any) => step.previous_step_id === stepId)
// }

const findAllEnabledChildren = (initialSteps: InitialStep[], stepId: string): InitialStep[] => {
  const enabledChildren: InitialStep[] = []
  const directChildren = initialSteps.filter((step: InitialStep) => step.previous_step_id === stepId)

  for (const child of directChildren) {
    if (child.data?.disabled) {
      // If the child is disabled, find its enabled children
      const childrenOfDisabled = findAllEnabledChildren(initialSteps, child.step_id)
      enabledChildren.push(...childrenOfDisabled) // Spread operator to merge arrays
    } else {
      // If the child is enabled, add it to the results
      enabledChildren.push(child)
    }
  }

  return enabledChildren
}

const updateStepConditionsInPlace = (newSteps: any[], stepId: any, newConditions: any) => {
  const step = newSteps.find((s) => s.step_id === stepId)
  if (step) {
    step.conditions_to_next_step.push(newConditions)
  }
}

const transformData = (data: any, stepType: any, platform: string) => {
  // const unit = data?.unit
  const amount = data?.amount
  const typeMapping: { [platform: string]: { [type: string]: any } } = {
    instagram: {
      like_post: { ...data, max_posts: amount },
      // is_message_replied: { ...data },
    },
    linkedin: {
      like_post: { ...data, max_posts: amount },
    },
  }
  return typeMapping?.[platform]?.[stepType] || data
}

// const findNextEnabledStepId = (initialSteps: InitialStep[], currentStepId: string): string | null => {
//   const nextSteps = findChildren(initialSteps, currentStepId)
//   for (const nextStep of nextSteps) {
//     if (!nextStep.data?.disabled) {
//       return nextStep.step_id // Found an enabled step
//     } else {
//       // Recursively look further if the found step is disabled
//       return findNextEnabledStepId(initialSteps, nextStep.step_id)
//     }
//   }
//   return null // No enabled steps found
// }

const sortByStepId = (array: any) => {
  return array.sort((a: any, b: any) => {
    const aParts = a?.step_id?.split('_')?.map(Number)
    const bParts = b?.step_id?.split('_')?.map(Number)

    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      const aVal = aParts?.[i] || 0
      const bVal = bParts?.[i] || 0
      if (aVal !== bVal) {
        return aVal - bVal
      }
    }

    return 0
  })
}

// Main function
export const transformToNewStructure = (initialSteps: InitialStep[], platform: string): NewStep[] => {
  const newSteps: NewStep[] = []

  if (!platform) return []
  if (!Array.isArray(initialSteps)) return []

  let sortedInitialSteps: InitialStep[]
  const conditionNodes: InitialStep[] = []

  try {
    sortedInitialSteps = sortByStepId([...initialSteps])
  } catch (error) {
    console.log('Error sorting steps:', error)
    sortedInitialSteps = [...initialSteps]
  }

  sortedInitialSteps.forEach((step: any) => {
    if (!step?.data?.disabled) {
      if (stepOrCondition(step?.type) === 'step') {
        const newStep: NewStep = {
          step_id: step.step_id,
          previous_step_id: step.previous_step_id || null,
          type: mapTypeToNewFormat(step.type, platform?.toLocaleLowerCase()),
          conditions_to_next_step: [],
          message_id: step.message_id,
          ...(step.data !== undefined &&
            step.type !== 'ai_responder' && {
              data: transformData(step.data, step.type, platform?.toLocaleLowerCase()),
            }),
          ...(step.type === 'ai_responder' && {
            ai_responder_id: createCategory(step.data),
            configuration: step.data?.configuration,
          }),
        }

        newSteps.push(newStep)
      } else if (step.data && stepOrCondition(step?.type).includes('condition')) {
        conditionNodes.push(step)
      }
    }
  })

  conditionNodes.forEach((step: any) => {
    const minutes = Math.max(calculateMinutes(step.data.unit, step.data.amount), 1)
    const nextSteps = findAllEnabledChildren(initialSteps, step.step_id)
    const conditionType = mapTypeToNewFormat(step.type, platform?.toLocaleLowerCase())

    for (const nextStep of nextSteps) {
      // if (!nextStep) continue
      if (typeof conditionType === 'string') {
        const newCond = { type: conditionType, minutes: minutes, next_step_id: nextStep.step_id }
        updateStepConditionsInPlace(newSteps, step.previous_step_id, newCond)
      } else {
        const lastEl = nextStep.step_id.charAt(nextStep.step_id.length - 1)
        const newCond = { type: conditionType[lastEl], minutes: minutes, next_step_id: nextStep.step_id }
        updateStepConditionsInPlace(newSteps, step.previous_step_id, newCond)
      }
    }
  })

  return newSteps
}

// OLD Main function
// export const transformToNewStructure = (initialSteps: InitialStep[], platform: string): NewStep[] => {
//   const newSteps: NewStep[] = []
//   if (!platform) return []

//   initialSteps.forEach((step: any) => {
//     if (step.data && !step?.data?.disabled && stepOrCondition(step?.type).includes('condition')) {
//       const minutes = Math.max(calculateMinutes(step.data.unit, step.data.amount), 1)
//       const nextSteps = findAllEnabledChildren(initialSteps, step.step_id)
//       const conditionType = mapTypeToNewFormat(step.type, platform?.toLocaleLowerCase())

//       for (const nextStep of nextSteps) {
//         // if (!nextStep) continue
//         if (typeof conditionType === 'string') {
//           const newCond = { type: conditionType, minutes: minutes, next_step_id: nextStep.step_id }
//           updateStepConditionsInPlace(newSteps, step.previous_step_id, newCond)
//         } else {
//           const lastEl = nextStep.step_id.charAt(nextStep.step_id.length - 1)
//           const newCond = { type: conditionType[lastEl], minutes: minutes, next_step_id: nextStep.step_id }
//           updateStepConditionsInPlace(newSteps, step.previous_step_id, newCond)
//         }
//       }
//     } else if (stepOrCondition(step?.type) === 'step') {
//       if (!step?.data?.disabled) {
//         const newStep: NewStep = {
//           step_id: step.step_id,
//           previous_step_id: step.previous_step_id || null,
//           type: mapTypeToNewFormat(step.type, platform?.toLocaleLowerCase()),
//           conditions_to_next_step: [],
//           message_id: step.message_id,
//           ...(step.data !== undefined &&
//             step.type !== 'ai_responder' && {
//               data: transformData(step.data, step.type, platform?.toLocaleLowerCase()),
//             }),
//           ...(step.type === 'ai_responder' && {
//             ai_responder_id: createCategory(step.data),
//             configuration: step.data?.configuration,
//           }),
//         }

//         newSteps.push(newStep)
//       }
//     }
//   })
//   return newSteps
// }
