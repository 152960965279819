import styles from './AffiliateSignUp.module.scss'
import convrt_logo_login from 'common/assets/svg/convrt_logo_login.svg'
import rightSection from 'common/assets/png/RightSection-new.png'
import rightSection2 from 'common/assets/png/RightSection2-new.png'
import rightSection3 from 'common/assets/png/RightSection3-new.png'
import InitialForm from './UI/InitialForm/InitialForm'
import VerifyEmail from './UI/VerifyEmail/VerifyEmail'
import { useLocalStorage } from 'usehooks-ts'
import GetToKnow from './UI/GetToKnow/GetToKnow'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_SETTINGS } from './state/slice/signupSlice'
import { useLazyGetSettingsQuery, useLazyValidateRegistrationLinkQuery } from './state/api/SignUpApi'
import { message } from 'antd'

const imageMap: any = {
  0: <img src={rightSection} className={styles.rightImage} alt='rightSection' />,
  1: <img src={rightSection2} className={styles.rightImage} alt='rightSection' />,
  2: <img src={rightSection3} className={styles.rightImage} alt='rightSection' />,
  3: <img src={rightSection3} className={styles.rightImage} alt='rightSection' />,
  4: <img src={rightSection3} className={styles.rightImage} alt='rightSection' />,
  5: <img src={rightSection3} className={styles.rightImage} alt='rightSection' />,
}

export default function AffiliateSignUp() {
  const [user] = useLocalStorage<any>('user', null)
  const [step, setStep] = useLocalStorage('step', 0)
  const [checked, setChecked] = useState(false)
  const [getSettings] = useLazyGetSettingsQuery()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { isMobile } = useAppSelector((state) => state.General)
  const { uuid } = useParams<{ uuid: string }>()
  const [validateUrl] = useLazyValidateRegistrationLinkQuery()
  const [linkId, setLinkId] = useState<any>(uuid)

  const stepMap: any = {
    0: <InitialForm linkId={linkId} />,
    1: <VerifyEmail />,
    2: <GetToKnow />,
    3: <GetToKnow />,
    4: <GetToKnow />,
    5: <GetToKnow />,
  }

  const handleNavigate = () => {
    window.open('https://www.convrt.ai/', '_self')
  }

  const validateRegistrationLink = async (linkId: string) => {
    const { data, error } = await validateUrl({ linkId })
    if (data) {
      const { link_id, changeUrl } = data
      if (changeUrl) {
        const currentUrl = new URL(window.location.href)
        let newUrl = `${window.location.origin}/register/${link_id}`
        const token = currentUrl.searchParams.get('token')
        if (token) {
          newUrl += `?token=${token}`
        }
        setLinkId(link_id)
        window.history.replaceState(null, '', newUrl)
      }

      setChecked(true)
      return true
    }

    if (error) {
      message.error('Registration link is invalid.')
      navigate('/login')
      return false
    }
    return false
  }

  useEffect(() => {
    if (!checked) {
      if (uuid) {
        // // Validate the UUID format (basic validation for UUID structure)
        // const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
        // if (uuidRegex.test(uuid)) {
        validateRegistrationLink(uuid)
        // } else {
        //   console.error('Invalid UUID:', uuid)
        //   navigate('/login')
        // }
      } else {
        console.error('No UUID found in URL')
        console.log('???')
        navigate('/login')
      }
    }
  }, [uuid])

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getSettings()
      if (data) {
        dispatch(SET_SETTINGS(data?.settings))
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (user?.outside_onboarding?.step >= 0) {
      setStep(user?.outside_onboarding?.step)
    }
  }, [user])

  return (
    <>
      {isMobile ? (
        <div style={{ overflowY: 'auto', height: 'max-content' }}>
          <div
            style={{
              display: 'flex',
              height: 'fit-content',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <div
              className={styles.mainBody}
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
              }}>
              <div style={{ marginTop: 15, marginLeft: 15 }}>
                <img onClick={handleNavigate} style={{ cursor: 'pointer' }} src={convrt_logo_login} alt='logo' />
              </div>
              {stepMap[step]}
            </div>
          </div>
          <div style={{ height: '100px', width: '100%', background: 'transparent', zIndex: -5 }}></div>
        </div>
      ) : (
        // <div className={styles.page}>
        //   <div className={styles.innerPage}>
        //     <div className={styles.left}>
        //       <div className={styles.logo}>
        //         <img onClick={handleNavigate} style={{ cursor: 'pointer' }} src={convrt_logo_login} alt='logo' />
        //       </div>
        //       <div className={styles.main}>
        //         <div className={styles.content}>{stepMap[step]}</div>
        //       </div>
        //     </div>
        //     <div className={styles.right}>{imageMap[step]}</div>
        //   </div>
        // </div>
        <div className={styles.outerContainer}>
          <div className={styles.header}>
            <img onClick={handleNavigate} style={{ cursor: 'pointer' }} src={convrt_logo_login} alt='logo' />
          </div>
          <div className={styles.mainBodyWeb}>
            <div className={styles.leftMain}>{stepMap[step]}</div>
            <div className={styles.rightMain}>{imageMap[step]}</div>
          </div>
        </div>
      )}
    </>
  )
}
