import { Button, Form, Input, message } from 'antd'
import styles from './GetToKnow.module.scss'
import { useLocalStorage } from 'usehooks-ts'
import back from 'common/assets/svg/Back.svg'
import Where from './UI/Where'
import CompanyDomain from './UI/CompanyDomain'
import Goal from './UI/Goal'
import {
  useLazyUpdateOutsideLogQuery,
  useLazyUpdateUserQuery,
} from 'features/sign-up/AffiliateSignup/state/api/SignUpApi'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import { useNavigate } from 'react-router-dom'
// import { post } from 'common/api/axios'
import { SET_JUST_SIGNED_UP } from 'GeneralSlice'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
// import { logEvent } from 'eventLogger'
import { trackEvent } from 'eventConfig'
import nervous from 'common/assets/svg/EmotionatedEmoji.svg'

export default function GetToKnow() {
  const [step, setStep] = useLocalStorage('step', 1)
  const [updateUser] = useLazyUpdateUserQuery()
  const [user, setUser]: any = useLocalStorage('user', null)
  const outside_onboarding = user?.outside_onboarding
  const [buttonLoading, setButtonLoading] = useState(false)
  const innerStep = step - 1
  const { affiliateData } = useAppSelector((state) => state.signup)
  const isGetToKnow = !outside_onboarding?.settings?.skip_outside_onboarding
  const [updateOutsideLog] = useLazyUpdateOutsideLogQuery()
  const [where, setWhere] = useState('')
  const [whatSearch, setWhatSearch] = useState('')
  const [companyDomain, setCompanyDomain] = useState('')
  const [goal, setGoal] = useState('')
  const { isMobile } = useAppSelector((state) => state.General)
  const [getUserDetails] = useLazyGetUserDetailsQuery()
  const [oopsWeb3, setOopsWeb3] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isGoogle, setIsGoogle] = useState(false)

  const continueStep1 = async (values: any) => {
    setButtonLoading(true)
    await updateUser({
      updates: {
        company: values.companyName,
        company_domain: values.companyDomain,
        outside_onboarding: { ...outside_onboarding, step: 3 }, // here
      },
      id: user.id,
    })

    setButtonLoading(false)

    // logEvent(
    //   'Outside Signup: Get to know 1 (Company)',
    //   'frontend',
    //   {
    //     event_purpose: 'Track when a user completed the company domain step (1)',
    //     trigger: 'User clicked on continue',
    //     related_screen: 'Onboarding - Get to Know You 1',
    //     key_data_points: ['Company name, Company domain'],
    //     expected_outcome: 'Check if user completed this steps',
    //     notes: 'This events means that the user completed the step and not started.',
    //   },
    //   { affiliateData: affiliateData, user: user, company: values.companyName, company_domain: values.companyDomain },
    // )

    trackEvent('SIGNUP_GET_TO_KNOW_1', {
      affiliateData: affiliateData,
      user: user.email,
      details: user,
      company: values.companyName,
      company_domain: values.companyDomain,
    })

    isGetToKnow ? setStep(3) : finish()
  }
  const handleBack = async (step: any) => {
    setStep(step)
    await updateUser({
      updates: {
        outside_onboarding: { ...outside_onboarding, step: step }, // here
      },
      id: user.id,
    })
  }
  const continueStep2 = async () => {
    if (!where) {
      message.error('Please select where did you find us')
      return
    }

    await updateUser({
      updates: {
        outside_onboarding: { ...outside_onboarding, step: 4 }, // here
      },
      id: user.id,
    })

    await updateOutsideLog({ toUpdate: 'where_did_you_find_us', value: where, userID: user.id })
    await updateOutsideLog({ toUpdate: 'what_search', value: whatSearch, userID: user.id })
    // logEvent(
    //   'Outside Signup: Get to know 2 (Where)',
    //   'frontend',
    //   {
    //     event_purpose: 'Track when a user completed the “where did you find us” step (2)',
    //     trigger: 'User clicked on continue',
    //     related_screen: 'Onboarding - Get to Know You 2',
    //     key_data_points: ['Where did you find us'],
    //     expected_outcome: 'Check if user completed this step',
    //     notes: 'This event indicates that the user completed the step and not just started.',
    //   },
    //   { affiliateData: affiliateData, user: user, where_did_you_find_us: where },
    // )

    trackEvent('SIGNUP_GET_TO_KNOW_2', {
      affiliateData: affiliateData,
      user: user.email,
      details: user,
      where_did_you_find_us: where,
    })

    setStep(4)
  }
  const continueStep3 = async () => {
    if (!companyDomain) {
      message.error('Please select your company domain')
      return
    }

    await updateOutsideLog({ toUpdate: 'company_domain', value: companyDomain, userID: user.id })

    // logEvent(
    //   'Outside Signup: Get to know 3 (Company Domain)',
    //   'frontend',
    //   {
    //     event_purpose: 'Track when a user completed the company domain step (3)',
    //     trigger: 'User clicked on continue',
    //     related_screen: 'Onboarding - Get to Know You 3',
    //     key_data_points: ['Company Domain'],
    //     expected_outcome: 'Check if user completed this step',
    //     notes: 'This event indicates that the user completed the step and not just started.',
    //   },
    //   { affiliateData: affiliateData, user: user, company_domain: companyDomain },
    // )

    trackEvent('SIGNUP_GET_TO_KNOW_3', {
      affiliateData: affiliateData,
      user: user.email,
      details: user,
      company_domain: companyDomain,
    })

    if (companyDomain === 'web3') {
      setOopsWeb3(true)
      return
    }
    setStep(5)
    await updateUser({
      updates: {
        outside_onboarding: { ...outside_onboarding, step: 5 }, // here
      },
      id: user.id,
    })
  }
  const continueStep4 = async () => {
    if (!goal) {
      message.error('Please select your goal')
      return
    }

    await updateUser({
      updates: {
        outside_onboarding: null,
      },
      id: user.id,
    })

    await updateOutsideLog({ toUpdate: 'goal', value: goal, userID: user.id })

    // logEvent(
    //   'Outside Signup: Get to know 4 (Goal)',
    //   'frontend',
    //   {
    //     event_purpose: 'Track when a user completed the goal step (4)',
    //     trigger: 'User clicked on continue',
    //     related_screen: 'Onboarding - Get to Know You 4',
    //     key_data_points: ['Goal'],
    //     expected_outcome: 'Check if user completed this step',
    //     notes: 'This event indicates that the user completed the step and not just started.',
    //   },
    //   { affiliateData: affiliateData, user: user, goal: goal },
    // )

    trackEvent('SIGNUP_GET_TO_KNOW_4', { affiliateData: affiliateData, user: user.email, details: user, goal: goal })

    finish()
  }

  const skipStep = async (newStep: number | 'finish') => {
    if (newStep === 'finish') {
      await updateUser({
        updates: {
          outside_onboarding: null,
        },
        id: user.id,
      })
      finish()
    } else {
      setStep(newStep)

      await updateUser({
        updates: {
          outside_onboarding: { ...outside_onboarding, step: newStep }, // here
        },
        id: user.id,
      })
    }
  }

  const getUser = async () => {
    const { data } = await getUserDetails()
    if (data.id) {
      setUser(data)
    }
  }

  const finish = () => {
    getUser()
    trackEvent('SIGNUP_COMPLETION', { affiliateData, user: user.email, details: user })
    dispatch(SET_JUST_SIGNED_UP(true))
    navigate('/upgrade?pricing-first-time=true')
  }

  useEffect(() => {
    switch (step) {
      case 2:
        trackEvent('ARRIVE_SIGNUP_GET_TO_KNOW_1')
        break
      case 3:
        trackEvent('ARRIVE_SIGNUP_GET_TO_KNOW_2')

        break
      case 4:
        trackEvent('ARRIVE_SIGNUP_GET_TO_KNOW_3')
        break
      case 5:
        trackEvent('ARRIVE_SIGNUP_GET_TO_KNOW_4')

        break
      default:
        break
    }
  }, [step])

  return (
    <>
      <div
        className={
          isMobile
            ? innerStep === 1 || innerStep === 2 || innerStep === 3 || innerStep === 4
              ? styles.cardMobile
              : styles.card
            : styles.card
        }>
        <div className={styles.content}>
          {isGetToKnow && <div className={styles.step}>Step {innerStep} of 4</div>}
          {oopsWeb3 && (
            <>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <img src={nervous} alt='nervous' style={{ width: 60, height: 60 }}></img>
              </div>
            </>
          )}
          <div className={styles.lets}>
            {oopsWeb3 ? `Oops! you've reached our Web2 product!` : `Let's get to know you`}
          </div>
          <>
            {innerStep === 1 && (
              <>
                <Form onFinish={continueStep1} style={{ width: '100%', height: '100%' }}>
                  <div className={styles.inputs}>
                    <div className={styles.input_frame}>
                      <span className={styles.input_text}>Company name</span>
                      <div className={styles.input}>
                        <Form.Item
                          name='companyName'
                          style={{ padding: '0px', margin: '0px', width: '100%', fontSize: 18 }}
                          rules={[{ required: true, message: 'Company Name is required', whitespace: true }]}>
                          <Input placeholder='Company name' style={{ fontSize: 18 }} inputMode='text' />
                        </Form.Item>
                      </div>
                    </div>
                    <div className={styles.input_frame}>
                      <span className={styles.input_text}>Company Domain</span>
                      <div className={styles.input}>
                        <Form.Item
                          name='companyDomain'
                          style={{ padding: '0px', margin: '0px', width: '100%', zIndex: 100, fontSize: 18 }}
                          rules={[
                            {
                              required: true,
                              message: 'Company Domain is required',
                              whitespace: true,
                            },
                            // () => ({
                            //   validator(_, value) {
                            //     const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/
                            //     if (!value || domainRegex.test(value)) {
                            //       return Promise.resolve()
                            //     }
                            //     return Promise.reject(new Error('Please enter a valid domain.'))
                            //   },
                            // }),
                          ]}>
                          <Input
                            className='websiteInputSignup'
                            addonBefore={
                              <div className={styles.addon}>
                                <div className={styles.text} style={{ fontSize: 18 }}>
                                  http://
                                </div>
                              </div>
                            }
                            styles={{
                              input: {
                                fontSize: 18,
                              },
                              prefix: {
                                fontSize: 18,
                              },
                            }}
                            style={{ fontSize: 18 }}
                            placeholder='company.com'
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.button}
                    // Added this style to "fix" the card height on smaller screens
                    style={{ padding: '32px 0px 128px 0px' }}>
                    <Form.Item style={{}}>
                      <Button type='primary' htmlType='submit' style={{ marginTop: 70 }} loading={buttonLoading}>
                        Continue
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </>
            )}
            {innerStep === 2 && (
              <>
                {isMobile ? (
                  <>
                    <Where
                      where={where}
                      setWhere={setWhere}
                      whatSearch={whatSearch}
                      setWhatSearch={setWhatSearch}
                      isGoogle={isGoogle}
                      setIsGoogle={setIsGoogle}
                    />
                    <div className={styles.button_back}>
                      <Button
                        type='ghost'
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          paddingLeft: '2px',
                          marginTop: '2px',
                        }}
                        onClick={() => (isGoogle ? setIsGoogle(false) : handleBack(2))}>
                        <img src={back} style={{ marginBottom: '2.1px', fontWeight: '600' }} alt='Back' />
                        <span className={styles.back_text}>Back</span>
                      </Button>
                      <Button type='primary' onClick={continueStep2}>
                        Continue
                      </Button>
                    </div>
                    <div className={styles.skip_frame_mobile}>
                      <div className={styles.skip} onClick={() => skipStep(4)}>
                        Skip
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <Where
                      where={where}
                      setWhere={setWhere}
                      whatSearch={whatSearch}
                      setWhatSearch={setWhatSearch}
                      isGoogle={isGoogle}
                      setIsGoogle={setIsGoogle}
                    />
                    <div className={styles.button_back}>
                      <Button
                        type='ghost'
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          paddingLeft: '2px',
                          marginTop: '2px',
                        }}
                        onClick={() => (isGoogle ? setIsGoogle(false) : handleBack(2))}>
                        <img src={back} style={{ marginBottom: '2.1px', fontWeight: '600' }} alt='Back' />
                        <span className={styles.back_text}>Back</span>
                      </Button>
                      <Button type='primary' onClick={continueStep2}>
                        Continue
                      </Button>
                    </div>
                    <div className={styles.skip_frame}>
                      <div className={styles.skip} onClick={() => skipStep(4)}>
                        Skip
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {innerStep === 3 && (
              <>
                {isMobile ? (
                  <>
                    <CompanyDomain domain={companyDomain} setDomain={setCompanyDomain} oopsWeb3={oopsWeb3} />
                    <div className={styles.button_back}>
                      {oopsWeb3 ? (
                        <>
                          <Button
                            type='ghost'
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingLeft: '2px',
                              marginTop: '2px',
                            }}
                            onClick={() => setOopsWeb3(false)}>
                            <img src={back} style={{ marginBottom: '2.1px', fontWeight: '600' }} alt='Back' />
                            <span className={styles.back_text}>Back</span>
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            type='ghost'
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingLeft: '2px',
                              marginTop: '2px',
                            }}
                            onClick={() => handleBack(3)}>
                            <img src={back} style={{ marginBottom: '2.1px', fontWeight: '600' }} alt='Back' />
                            <span className={styles.back_text}>Back</span>
                          </Button>
                          <Button type='primary' onClick={continueStep3}>
                            Continue
                          </Button>
                        </>
                      )}
                    </div>
                    <div className={styles.skip_frame_mobile}>
                      {!oopsWeb3 && (
                        <div className={styles.skip} onClick={() => skipStep(5)}>
                          Skip
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <CompanyDomain domain={companyDomain} setDomain={setCompanyDomain} oopsWeb3={oopsWeb3} />

                    <div className={styles.button_back}>
                      {oopsWeb3 ? (
                        <>
                          <Button
                            type='ghost'
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingLeft: '2px',
                              marginTop: '2px',
                            }}
                            onClick={() => setOopsWeb3(false)}>
                            <img src={back} style={{ marginBottom: '2.1px', fontWeight: '600' }} alt='Back' />
                            <span className={styles.back_text}>Back</span>
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            type='ghost'
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingLeft: '2px',
                              marginTop: '2px',
                            }}
                            onClick={() => handleBack(3)}>
                            <img src={back} style={{ marginBottom: '2.1px', fontWeight: '600' }} alt='Back' />
                            <span className={styles.back_text}>Back</span>
                          </Button>
                          <Button type='primary' onClick={continueStep3}>
                            Continue
                          </Button>
                        </>
                      )}
                    </div>
                    <div className={styles.skip_frame_3}>
                      {!oopsWeb3 && (
                        <div className={styles.skip} onClick={() => skipStep(5)}>
                          Skip
                        </div>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
            {innerStep === 4 && (
              <>
                {isMobile ? (
                  <>
                    <Goal goal={goal} setGoal={setGoal} />
                    <div className={styles.button_back}>
                      <Button
                        type='ghost'
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          paddingLeft: '2px',
                          marginTop: '2px',
                        }}
                        onClick={() => handleBack(4)}>
                        <img src={back} style={{ marginBottom: '2.1px', fontWeight: '600' }} alt='Back' />
                        <span className={styles.back_text}>Back</span>
                      </Button>
                      <Button type='primary' onClick={continueStep4}>
                        Continue
                      </Button>
                    </div>
                    <div className={styles.skip_frame_mobile}>
                      <div className={styles.skip} onClick={() => skipStep('finish')}>
                        Skip
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <Goal goal={goal} setGoal={setGoal} />
                    <div className={styles.button_back}>
                      <Button
                        type='ghost'
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          paddingLeft: '2px',
                          marginTop: '2px',
                        }}
                        onClick={() => handleBack(4)}>
                        <img src={back} style={{ marginBottom: '2.1px', fontWeight: '600' }} alt='Back' />
                        <span className={styles.back_text}>Back</span>
                      </Button>
                      <Button type='primary' onClick={continueStep4}>
                        Continue
                      </Button>
                    </div>
                    <div className={styles.skip_frame_3}>
                      <div className={styles.skip} onClick={() => skipStep('finish')}>
                        Skip
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        </div>
      </div>
    </>
  )
}
