import React from 'react'
import { Row, Col } from 'antd'
import icons from 'common/utils/iconUtil'

interface IconPickerProps {
  setCurrentIcon: any
  onAdd: any
}
export default function IconPicker({ setCurrentIcon, onAdd }: IconPickerProps) {
  const onMouseEnter = (src: any) => {
    setCurrentIcon(src)
  }
  const onRowMouseEnter = () => {
    setCurrentIcon(null)
  }
  const handleClick = (name: string, src: any) => {
    onAdd(name)
  }
  return (
    <Row
      gutter={[16, 16]}
      justify='center'
      onMouseEnter={onRowMouseEnter}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}>
      {icons.map(({ name, src }: any) => (
        <Col key={name} span={4} style={{ textAlign: 'center' }}>
          <div
            onMouseEnter={() => onMouseEnter(src)}
            onClick={(e) => {
              handleClick(name, src)
            }}>
            <img
              src={src}
              alt={name}
              style={{ width: 25, height: 25, cursor: 'pointer' }}
              onClick={(e) => {
                handleClick(name, src)
              }}
            />
          </div>
        </Col>
      ))}
    </Row>
  )
}
