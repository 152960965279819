import React, { useEffect, useState } from 'react'
import { Modal, Divider, Button, Input, Table, Layout, notification, message, Tooltip, Skeleton } from 'antd'
// import PercentageControl from '../OutreachAndChannels/PercentageControl'
import funnel_icon from 'common/assets/svg/funnel_icon.svg'
import visible_eye_icon from 'common/assets/svg/visible_eye_icon.svg'
import hidden_eye_icon from 'common/assets/svg/hidden_eye_icon.svg'
import styles from './LeadsModalAndFilters.module.scss'
import { Content } from 'antd/es/layout/layout'
import Sider from 'antd/es/layout/Sider'
import LeadsFilter from './LeadsFilter'
// import Frameskeleton from 'common/assets/png/skeleton_icon.png'
import {
  SET_INITIAL_AI_COMPANIES_DATA_FILTERED,
  SET_INITIAL_AI_DATA_FILTERED,
  SET_SELECTED_LEADS,
} from '../../state/outreachAICampaignSlice'
import { useAppDispatch, useAppSelector } from 'state'
import RefineListModal from '../UI/RefineListModal'
import {
  useLazyGetAllLeadsQuery,
  useLazyRefineListQuery,
  useLazySaveCampaignLeadsQuery,
} from 'features/Outreach/state/api/OutreachApi'
import { NotificationPlacement } from 'antd/es/notification/interface'
import {
  useLazySetRecipientsAiQuery,
  // useLazyExposePlatformUseRemainingPointsQuery,
} from 'features/Outreach/state/api/OutreachApi'
import LoadingBox from 'common/components/LoadingBox/LoadingBox'
import TableRowSelectionDropdown from 'GeneralComponents/TableRowSelectionDropdown'
// import { useNavigate } from 'react-router-dom'

// import { useLazyExposePlatformCostQuery } from 'features/Outreach/state/api/OutreachApi'
// import { number } from 'yup'
// import OutOfPointsUseRemaining from 'common/components/OutOfPoints/OutOfPointsUseRemaining'

// import diamond_icon from 'common/assets/png/diamond_icon.png'
interface LeadsModalAndFiltersProps {
  open: boolean
  onClose: () => void
  data: any
  columns: any
  handleApplyFilters: (filters: any, resetData?: boolean) => void
  isLoading?: boolean
  totalData: any
  setAIData: any
  AIData: any
  filteredData: any
}

const LeadsModalAndFilters: React.FC<LeadsModalAndFiltersProps> = ({
  open,
  onClose,
  data,
  columns,
  handleApplyFilters,
  isLoading,
  totalData,
  setAIData,
  AIData,
  filteredData,
}) => {
  // const limitedData = data.slice(0, 5)
  // const navigate = useNavigate()

  const {
    saved_prompt_params,
    selected_leads,
    initial_ai_data,
    initial_ai_companies_data,
    campaign_id,
    ai_leads_type,
    // initial_ai_companies_data_filtered,
    // initial_ai_data_filtered,
    // ai_campaign_name,
    // selected_platforms_accounts,
    active_platforms,
  } = useAppSelector((state) => state.outreachAICampaign)

  const columnKeysToModify = {
    name: { width: 200, fixed: 'left' },
    company: { width: 210 },
    location: { width: 245 },
    technologies: { width: 130 },
    // industry: { width: 0 },
    // businessType: { width: 0 },
    accuracy: { width: 80 },
    // source: { width: 10 },
    // channels: { width: 150 },
  }

  const modifyColumnWidth = (columns: any, columnKeysToModify: any) => {
    return columns.map((column: any) => {
      if (columnKeysToModify[column.key]) {
        return { ...column, ...columnKeysToModify[column.key] }
      }
      return column
    })
  }

  const modifiedColumns = modifyColumnWidth(columns, columnKeysToModify)
  const [showNewLeads, setShowNewLeads] = useState(false)
  const [numberOfLeads, setNumberOfLeads] = useState(totalData)
  const [totalNumberOfLeads, setTotalNumberOfLeads] = useState(totalData)
  const [loadingAdd, setLoadingAdd] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  // const [refetcExposePlatformUseRemainingPoints, { data: exposePlatformUseRemainingPointsData }] =
  //   useLazyExposePlatformUseRemainingPointsQuery()
  const [saveCampaignLeads] = useLazySaveCampaignLeadsQuery()
  // eslint-disable-next-line
  const [refetcSetRecipients, { data: campaignRecipientsData }] = useLazySetRecipientsAiQuery()
  const [isLoadingRecipients, setIsLoadingRecipients] = useState(false)
  const dispatch = useAppDispatch()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [openRefineListModal, setOpenRefineListModal] = useState(false)
  const [yAxis, setYAxis] = useState<number>(window.innerHeight - 320)
  const [siderCollapsed, setSiderCollapsed] = useState(true)
  const [refinement, setRefinement] = useState('')
  const [refineList] = useLazyRefineListQuery()
  const [getAllLeads] = useLazyGetAllLeadsQuery()
  const platforms = Object.keys(active_platforms).filter((key) => active_platforms[key] === true)
  const [clearFilters, setClearFilters] = useState(false)
  const pageSize = 10
  const [currentPage, setCurrentPage] = useState(1)

  // const { data: leadsDataFetched, total_leads } = refinedListData ?? {}
  const [api, contextHolder] = notification.useNotification()
  // const [refetcExposePlatformCost, { data: exposePlatformCostData }] = useLazyExposePlatformCostQuery()

  const handleCloseModal = () => {
    handleResetToDefault()
    onClose()
  }

  const openNotification = (placement: NotificationPlacement, numLeads: number) => {
    api.success({
      message: `${numLeads} Leads were added to your campaign!`,
      placement,
      style: {
        background: '#E2FFF4',
        border: '1px solid #48BB78',
        boxShadow: '0px 8px 40px rgba(72, 187, 120, 0.16)',
        borderRadius: '8px',
        height: 70,
        width: 400,
        zIndex: 100000,
      },
    })
  }

  const handleHideLeads = async () => {
    // if (showNewLeads === true) {
    //   setShowNewLeads(false)
    //   setNumberOfLeads(ai_leads_type === 'leads' ? initial_ai_data.total_leads : initial_ai_companies_data.total_leads)
    //   setTotalNumberOfLeads(
    //     ai_leads_type === 'leads' ? initial_ai_data.total_leads : initial_ai_companies_data.total_leads,
    //   )
    //   return setAIData(ai_leads_type === 'leads' ? initial_ai_data : initial_ai_companies_data)
    // }
    if (!loadingTable) {
      setShowNewLeads(!showNewLeads)
      // if (ai_leads_type === 'leads' && initial_ai_data_filtered?.data?.length > 0) {
      //   setNumberOfLeads(initial_ai_data_filtered.total_leads)
      //   setTotalNumberOfLeads(initial_ai_data_filtered.total_leads)
      //   return setAIData(initial_ai_data_filtered)
      // } else if (ai_leads_type === 'companies_leads' && initial_ai_companies_data_filtered?.data?.length > 0) {
      //   setNumberOfLeads(initial_ai_companies_data_filtered.total_leads)
      //   setTotalNumberOfLeads(initial_ai_companies_data_filtered.total_leads)
      //   return setAIData(initial_ai_companies_data_filtered)
      // } else {

      setClearFilters(true)
      setLoadingTable(true)
      // Fetch data
      const { data } = refinement
        ? await refineList({
            ...saved_prompt_params,
            refinement: refinement,
            table: ai_leads_type === 'companies_leads' ? 'companies' : 'leads',
            platforms: platforms,
            showNewLeads: !showNewLeads,
          })
        : await getAllLeads({
            ...saved_prompt_params,
            platforms,
            table: ai_leads_type === 'leads' ? 'leads' : 'companies',
            showNewLeads: !showNewLeads,
          })
      // Set data
      if (data) {
        // setSelectedRowKeys([])
        setAIData(data)
        setNumberOfLeads(refinement ? data?.refined_total_leads : data?.total_leads)
        setTotalNumberOfLeads(refinement ? data?.refined_total_leads : data?.total_leads)
        if (ai_leads_type === 'leads') {
          dispatch(SET_INITIAL_AI_DATA_FILTERED(data))
        } else {
          dispatch(SET_INITIAL_AI_COMPANIES_DATA_FILTERED(data))
        }
      }
      setLoadingTable(false)
      setClearFilters(false)

      // }
    }
  }

  // const openNotificationCost = (placement: NotificationPlacement, numLeads: number) => {
  //   api.success({
  //     message: `Expose Leads up to ${numLeads}!`,
  //     placement,
  //     style: {
  //       background: '#E2FFF4',
  //       border: '1px solid #48BB78',
  //       boxShadow: '0px 8px 40px rgba(72, 187, 120, 0.16)',
  //       borderRadius: '8px',
  //       height: 70,
  //       width: 400,
  //       zIndex: 100000,
  //     },
  //   })
  // }

  const handleRefineList = async () => {
    const { data } = await refineList({
      ...saved_prompt_params,
      refinement: refinement,
      table: ai_leads_type === 'companies_leads' ? 'companies' : 'leads',
      platforms: platforms,
    })
    setAIData({
      data: data.data,
      total_leads: data.total_leads,
    })
    handleApplyFilters(
      {
        company: [],
        location: [],
        industry: [],
        businessType: [],
        jobTitle: [],
        accuracy: [],
        source: [],
        channels: [],
      },
      true,
    )

    setNumberOfLeads(refinement ? data?.refined_total_leads : data?.total_leads)
    setTotalNumberOfLeads(refinement ? data?.refined_total_leads : data?.total_leads)
  }

  useEffect(() => {
    if (filteredData?.length >= 0) {
      setNumberOfLeads(filteredData?.length)
    }
  }, [filteredData])

  const onSearchChange = (e: any) => {
    setRefinement(e.target.value)
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    // selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    columnTitle: (
      <TableRowSelectionDropdown
        data={data}
        pageSize={pageSize}
        currentPage={currentPage}
        selectedRowKeys={selectedRowKeys}
        onSelectChange={setSelectedRowKeys}
      />
    ),
  }
  const hasSelected = selectedRowKeys?.length > 0

  const handleResetToDefault = () => {
    // setAIData(ai_leads_type === 'companies_leads' ? initial_ai_companies_data : initial_ai_data)
    if (ai_leads_type === 'companies_leads') {
      setAIData({
        data: initial_ai_companies_data?.data,
        total_leads: initial_ai_companies_data?.total_leads || initial_ai_companies_data?.data?.length || 0,
      })
      setNumberOfLeads(initial_ai_companies_data?.total_leads || initial_ai_companies_data?.data?.length || 0)
      setTotalNumberOfLeads(initial_ai_companies_data?.total_leads || initial_ai_companies_data?.data?.length || 0)
    } else {
      setAIData({
        data: initial_ai_data?.data,
        total_leads: initial_ai_data?.total_leads || initial_ai_data?.data?.length || 0,
      })
      setNumberOfLeads(initial_ai_data?.total_leads || initial_ai_data?.data?.length || 0)
      setTotalNumberOfLeads(initial_ai_data?.total_leads || initial_ai_data?.data?.length || 0)
    }
    handleApplyFilters(
      {
        company: [],
        location: [],
        industry: [],
        businessType: [],
        jobTitle: [],
        accuracy: [],
        source: [],
        channels: [],
      },
      true,
    )
    setRefinement('')
  }

  const handleRefreshLeads = async () => {
    setLoadingTable(true)
    const { data } = refinement
      ? await refineList({
          ...saved_prompt_params,
          platforms: platforms,
          table: ai_leads_type === 'companies_leads' ? 'companies' : '',
          selectedLeads: selected_leads?.map((lead: any) => lead.key),
          refinement: refinement,
        })
      : await getAllLeads({
          ...saved_prompt_params,
          platforms,
          table: ai_leads_type === 'leads' ? 'leads' : 'companies',
          selectedLeads: selected_leads?.map((lead: any) => lead.key),
        })
    if (data) {
      setSelectedRowKeys([])
      setAIData(data)
    }
    setLoadingTable(false)
  }

  const handleAddLeads = async () => {
    setLoadingAdd(true)
    const selectedData = data.filter((d: any) => selectedRowKeys.includes(d.key))
    const newLeadsToAdd = selectedData.filter(
      (newLead: any) => !selected_leads.some((existingLead: any) => existingLead.key === newLead.key),
    )
    const leadsForCampaign = newLeadsToAdd?.map((lead: any) => ({
      ...lead,
      platform: platforms?.[0],
      convrt_source: ai_leads_type === 'leads' ? 'ai_leads' : 'ai_leads_companies',
    }))
    await saveCampaignLeads({
      leads: leadsForCampaign,
      campaign_id: campaign_id,
      mode: 'convrt_ai',
    })

    // const updatedSelectedLeads = [...selected_leads, ...newLeadsToAdd]

    //daniel
    dispatch(SET_SELECTED_LEADS(leadsForCampaign))
    setSelectedRowKeys([])
    setIsLoadingRecipients(true)
    // const filtered_active_platforms = Object.entries(active_platforms)
    //   .filter(([platform, isActive]) => isActive === true)
    //   .map(([platform]) => platform)
    // const indexes = newLeadsToAdd.map((item: any) => item.index)
    // const dataExposePlatform = await refetcExposePlatformCost({ index: indexes, platform: filtered_active_platforms })

    // openNotificationCost('bottom', dataExposePlatform.data.cost_point || 0)

    openNotification('bottom', newLeadsToAdd?.length || 0)
    const num = newLeadsToAdd?.length === 1 ? 'Lead was' : 'Leads were'
    message.success(`${newLeadsToAdd?.length || 0} ${num} added to your campaign!`)
    setLoadingAdd(false)

    handleCloseModal()
  }

  useEffect(() => {
    const newSelectedRowKeys = selected_leads.map((lead: any) => lead.key)
    setSelectedRowKeys(newSelectedRowKeys)
  }, [selected_leads, open])

  useEffect(() => {
    const updateYAxis = () => {
      const tableElement = document.querySelector('[class^="ant-table-wrapper Leads_Table dummy"]')?.clientHeight || 0
      setYAxis(tableElement)
    }
    updateYAxis()
    window.addEventListener('resize', updateYAxis)
    return () => {
      window.removeEventListener('resize', updateYAxis)
    }
  }, [window.innerHeight, window.innerWidth])

  // console.log('rashmi--', data.length)
  // console.log('rashmi12--', limitedData)
  // Calculate the number of records to display before showing the paywall
  // eslint-disable-next-line
  const recordsToShowBeforePaywall = 5
  // const [outOfPoints, setOutOfPoints] = useState(false)
  // const [costPoints, setCostPoints] = useState(0)

  // const retryWithRemainingPoints = async () => {
  //   setOutOfPoints(false)
  //   setIsLoadingRecipients(true)
  //   const selectedData = data.filter((d: any) => selectedRowKeys.includes(d.key))
  //   const newLeadsToAdd = selectedData.filter(
  //     (newLead: any) => !selected_leads.some((existingLead: any) => existingLead.key === newLead.key),
  //   )

  //   const filtered_active_platforms = Object.entries(active_platforms)
  //     .filter(([platform, isActive]) => isActive === true)
  //     .map(([platform]) => platform)

  //   const indexes = newLeadsToAdd.map((item: any) => item.index)

  //   const newData = await refetcExposePlatformUseRemainingPoints({
  //     index: indexes,
  //     platform: filtered_active_platforms,
  //   })
  //   // setCostPoints(dataExposePlatform?.error?.data?.cost_point)
  //   // setOutOfPoints(true)
  //   // setIsLoadingRecipients(false)

  //   openNotification('bottom', newData?.data?.leads_index?.length || 0)
  //   setTimeout(() => {
  //     setSelectedRowKeys([])
  //     onClose()
  //   }, 1000)
  // }

  useEffect(() => {
    if (AIData?.total_leads) {
      setNumberOfLeads(AIData?.total_leads)
    }
  }, [AIData])

  useEffect(() => {
    if (isLoading) {
      setLoadingTable(isLoading)
    } else {
      setLoadingTable(false)
    }
  }, [isLoading])

  return (
    <>
      <Modal
        centered
        title={false}
        footer={false}
        open={open}
        onCancel={handleCloseModal}
        className='LeadsModalAndFilters'
        maskClosable={false}
        // style={{ minHeight: 1000 }}
        width='85%'>
        {contextHolder}
        <div className={`${styles['container']} `}>
          <div className={styles.LeadsModalAndFilters__TopSection}>
            <div className={`${styles['matchesLine']} LeadsModalAndFilters__TopSection`}>
              <span className={styles.LeadsModalAndFilters__LeftSection}>
                <span className={styles.LeadsModalAndFilters__Title}>{`Add ${
                  ai_leads_type === 'companies_leads' ? 'Company ' : ' '
                }Leads to Campaign`}</span>
                <Divider className='betterDivider' type='vertical' />
                <span>
                  {' '}
                  <div>
                    {hasSelected ? (
                      <>
                        <span style={{ fontWeight: 600 }}>{selectedRowKeys?.length}</span> leads{' '}
                        {selectedRowKeys?.length === 1 ? 'was' : 'were'} selected
                      </>
                    ) : (
                      <>
                        <span style={{ fontWeight: 600 }}>0 leads </span> were selected
                      </>
                    )}
                  </div>
                </span>
              </span>
              <div className={styles.importLeads}>
                {selected_leads?.length > 0 && (
                  <Tooltip title='Get new, unselected leads from Convrt AI'>
                    <Button onClick={handleRefreshLeads}>Get New Leads Only</Button>
                  </Tooltip>
                )}
                <Button onClick={handleResetToDefault}>Reset to Default</Button>
                <Button onClick={handleCloseModal}>Cancel</Button>
                <Button
                  loading={loadingAdd}
                  onClick={handleAddLeads}
                  disabled={selectedRowKeys?.length === 0}
                  type='primary'
                  size='middle'>
                  Add Leads
                </Button>
              </div>
            </div>
            <div className={styles.search}>
              <div className={styles.input_box}>
                <Input
                  size='large'
                  placeholder={
                    ai_leads_type === 'companies_leads'
                      ? 'Only companies from New York'
                      : 'More CEOs in "location name"'
                  }
                  onChange={onSearchChange}
                  value={refinement}
                  onKeyDown={(e: any) => {
                    e.key === 'Enter' && setOpenRefineListModal(true)
                  }}
                />
              </div>
              <Button
                onClick={() => {
                  setOpenRefineListModal(true)
                }}
                className={styles.refineBtn}
                disabled={!refinement}>
                Refine List
              </Button>
            </div>
          </div>
          <div className={styles.LeadsModalAndFilters__Content_Section}>
            <div className={styles.Content_Left}>
              <div className={styles.top}>
                <span className={styles.top__title}>
                  Displaying the{' '}
                  <span style={{ fontWeight: 600, color: '#000000' }}>
                    Top{' '}
                    {Math.min(1000, Number(numberOfLeads || '0'), Number(totalNumberOfLeads || '0'))?.toLocaleString()}
                  </span>{' '}
                  of {Number(totalNumberOfLeads || '0')?.toLocaleString()} leads that Convrt AI has found and can match
                  your campaign!
                </span>
                <span className={styles.right__content}>
                  {/* <span style={{ fontWeight: 600, color: '#5A5A5A', marginRight: '15px' }}>Outreach Accuracy</span>
                  <PercentageControl /> */}
                  <Tooltip title={`${showNewLeads ? 'Show' : 'Hide'} leads from my other campaigns`}>
                    <img
                      className={styles.hiddenEye}
                      onClick={handleHideLeads}
                      src={showNewLeads ? hidden_eye_icon : visible_eye_icon}
                      alt=''
                    />
                  </Tooltip>
                  <Button
                    style={{
                      marginLeft: '15px',
                      // border: 'none',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={() => setSiderCollapsed(!siderCollapsed)}>
                    <img src={funnel_icon} alt='' />
                  </Button>
                </span>
              </div>
              <Layout className='leadsSider'>
                <Content className={styles.content}>
                  {isLoadingRecipients ? (
                    <div className={styles.loading}>
                      <LoadingBox />
                    </div>
                  ) : filteredData?.length === 0 && (!data || data?.length === 0) ? (
                    <div className={styles.noLeadsFound}>
                      <span>No leads found. Please refine your list.</span>
                    </div>
                  ) : (
                    <div style={{ minHeight: '580px' }}>
                      <Table
                        scroll={{ y: '455px' }}
                        // dataSource={data}
                        // dataSource={filteredData?.length > 0 ? filteredData : data}
                        // columns={modifiedColumns}
                        dataSource={
                          loadingTable
                            ? [...Array(10)].map((_, index) => ({
                                key: `key${index}`,
                              }))
                            : filteredData?.length > 0
                            ? filteredData
                            : data
                        }
                        columns={
                          loadingTable
                            ? modifiedColumns.map((column: any) => {
                                return {
                                  ...column,
                                  render: function renderPlaceholder() {
                                    return (
                                      <Skeleton
                                        className='campaignsPageTable'
                                        key={column.dataIndex}
                                        title={true}
                                        paragraph={false}
                                        active
                                      />
                                    )
                                  },
                                }
                              })
                            : modifiedColumns
                        }
                        rowSelection={rowSelection}
                        pagination={{
                          total: filteredData?.length > 0 ? filteredData?.length : data?.length,
                          pageSize: pageSize,
                          showSizeChanger: false,
                          position: ['bottomCenter'],
                          onChange: (num) => setCurrentPage(num),
                        }}
                        className='Leads_Table dummy'
                        // scroll={tablePaywall ? {} : undefined}
                        // scroll={tablePaywall ? undefined:'auto'}
                      />
                    </div>
                  )}
                </Content>
                <Sider
                  style={{ maxHeight: yAxis }}
                  width='20%'
                  className={`${styles.sider} leadsSider`}
                  collapsedWidth='0'
                  collapsed={siderCollapsed}>
                  <LeadsFilter
                    onFilter={(e) => {
                      handleApplyFilters(e)
                      setSiderCollapsed(true)
                    }}
                    clearFilters={clearFilters}
                    data={filteredData?.length > 0 ? filteredData : data}
                  />
                </Sider>
                {/* Display the leadsbackground image after the table */}
              </Layout>
            </div>
          </div>
        </div>
        {openRefineListModal && (
          <RefineListModal
            open={openRefineListModal}
            onCancel={() => setOpenRefineListModal(false)}
            onConfirm={handleRefineList}
          />
        )}
      </Modal>
      {/* {outOfPoints && (
        <OutOfPointsUseRemaining
          open={outOfPoints}
          onClose={() => {
            setOutOfPoints(false)
          }}
          onUseRemainingPoints={retryWithRemainingPoints}
          cost_points={costPoints}
        />
      )} */}
    </>
  )
}

export default LeadsModalAndFilters
