import React, { useEffect } from 'react'
import { Avatar, Dropdown, Space, Select, Tag, Tooltip } from 'antd'
import { EllipsisOutlined, PushpinFilled } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import Pin from 'common/assets/svg/pin_icon.svg'
// import pin_icon from 'common/assets/svg/pin_chat.svg'
// import pinned_chat from 'common/assets/svg/pinned_chat.svg'
import Enrich from 'common/assets/png/Enrich_img.png'
import styles from './ChatDialog.module.scss'
import { useAppDispatch, useAppSelector } from 'state'
import {
  useLazyChangeMetadataQuery,
  useLazyGetDialogsQuery,
  useLazyUnifySpecificAccountsQuery,
} from '../state/api/Chat-API'
import { SET_DIALOGS_METADATA, SET_PRIORITY, SET_SELECTED_CHAT_RECIPIENT } from '../state/chatSlice'
import { post } from 'common/api/axios'
import TagItem from '../Tag/TagItem'
import CustomAvatar from '../UI/CustomAvatar'
import { trackEvent } from 'eventConfig'

type ChatDialogProps = {
  item: any
  recipientId: any
  recipientName: string
  timeAgo: string
  additionalTags: string[]
  latestMessage: string
  senderData: any
  platform: string
  photo: any
  unread_count: any
  pin: boolean
  priority: string

  onClick: any
}

const ChatDialog: React.FC<ChatDialogProps> = ({
  item,
  recipientId,
  recipientName,
  timeAgo,
  additionalTags,
  latestMessage,
  senderData,
  platform,
  photo,
  unread_count,
  pin,
  priority,
  onClick,
}) => {
  const { selected_chat_recipient, priorities, selected_account, dialogs_metadata } = useAppSelector(
    (state) => state.chat,
  )
  const [unifiySpecificAccounts] = useLazyUnifySpecificAccountsQuery()
  const [changeMetadata] = useLazyChangeMetadataQuery()
  const [getDialogs] = useLazyGetDialogsQuery()
  const dispatch = useAppDispatch()
  const isChatSelected = selected_chat_recipient.id === recipientId

  const { current_tags_dialog, thread_from } = useAppSelector((state) => state.chat)
  const filteredTagsDialog = current_tags_dialog?.find((record: any) => record.id === item.id)
  const tagsOfDialog = filteredTagsDialog?.data

  const handleOnClick = async () => {
    dispatch(
      SET_SELECTED_CHAT_RECIPIENT({
        ...item,
        tags: JSON.stringify(additionalTags),
        senderData: senderData,
      }),
    )
    onClick(recipientId, senderData, platform)

    if (item?.is_syncing === false && !selected_account?.status?.is_init) {
      const { sender_username: username, platform } = item
      const account = { username, platform }
      await unifiySpecificAccounts({ accounts: [account] })
      await getDialogs({ thread_from })
    }
  }

  useEffect(() => {
    if (item.can_be_used && !selected_chat_recipient.can_be_used) {
      dispatch(
        SET_SELECTED_CHAT_RECIPIENT({
          ...selected_chat_recipient,
          can_be_used: item.can_be_used,
        }),
      )
    }
  }, [item])
  // const [defaultPriority, setDefaultPriority] = useState(priority)

  const handleChangePriority = async (priority: string) => {
    dispatch(SET_PRIORITY({ recipientId, priority: priority }))
    await changeMetadata({
      platform: platform,
      dialog_id: recipientId,
      senderData: senderData,
      metadata: { priority: priority },
    })
    // setDefaultPriority(priority)

    const dialogId = recipientId
    const newMetadata = { ...dialogs_metadata?.[dialogId], priority: priority }
    dispatch(SET_DIALOGS_METADATA({ dialogId: dialogId, metadata: newMetadata }))

    await getDialogs({ thread_from })
    post('/log', {
      action: `Unified Inbox: Priority Changed to: ${priority}`,
      event_source: 'frontend',
      convrt_internal_description: {
        event_purpose: 'Track when the priority of a message is changed to high on the unified inbox page',
        trigger: 'User changes message priority to high',
        related_screen: 'Inbox page',
        key_data_points: [],
        expected_outcome: 'Monitor changes to message priority to high',
        notes: 'This event tracks when a message’s priority is changed to high in the unified inbox.',
        date_started: 'old',
      },
      data: {
        id: recipientId,
        platform: platform,
        senderData: senderData,
      },
    })
  }

  const handleEnrichClick = () => {
    console.log('Enrich chat clicked')
  }

  const handlePinChatClick = async () => {
    const dialogId = recipientId

    await changeMetadata({
      platform: platform,
      dialog_id: recipientId,
      senderData: senderData,
      metadata: { pin: !pin },
    })

    const newMetadata = { ...dialogs_metadata?.[dialogId], pin: !pin }
    dispatch(SET_DIALOGS_METADATA({ dialogId: dialogId, metadata: newMetadata }))

    await getDialogs({ thread_from })
    if (!pin) {
      trackEvent(!pin ? 'UNIFIED_INBOX_CHAT_PINNED' : 'UNIFIED_INBOX_CHAT_UNPINNED', {
        id: recipientId,
        platform: platform,
        senderData: senderData,
      })
    }
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <Tooltip title='Coming Soon!'>Enrich</Tooltip>,
      icon: <img src={Enrich} alt='' />,
      disabled: true,
      onClick: handleEnrichClick,
    },
    {
      key: '2',
      label: <span>{pin ? 'Unpin Chat' : 'Pin Chat'}</span>,
      icon: <img src={Pin} alt='' />,
      onClick: handlePinChatClick,
    },
  ]

  const handleStopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
  }

  const handleOpenPlatform = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    switch (platform) {
      case 'telegram':
        break
      case 'instagram':
        window.open(`https://www.instagram.com/${item.recipient_username}`, '_blank')
        break
    }
  }

  const formatDate = (inputDate: string) => {
    const now = new Date()
    const sentDate = new Date(inputDate)
    const dateDiff = now.getTime() - sentDate.getTime()
    const seconds = Math.round(dateDiff / 1000)
    const minutes = Math.round(seconds / 60)
    const hours = Math.round(minutes / 60)
    const days = Math.round(hours / 24)

    const formatTime = (date: Date) => date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

    if (seconds < 60) {
      return 'A few seconds ago'
    } else if (minutes < 60) {
      return `${minutes} minute${minutes === 1 ? '' : 's'} ago`
    } else if (hours < 24) {
      return `${hours} hour${hours === 1 ? '' : 's'} ago`
    } else if (hours < 48) {
      // Yesterday
      return `Yesterday at ${formatTime(sentDate)}`
    } else if (days < 3) {
      // 2 days ago
      return `${days} days ago at ${formatTime(sentDate)}`
    } else {
      // Formatting date in MM/DD/YYYY, HH:MM format
      return sentDate.toLocaleDateString() + ' at ' + formatTime(sentDate)
    }
  }

  // useEffect(() => {
  //   setDefaultPriority(priority)
  // }, [priority])

  return (
    <div
      className={`${styles['chat_receipt_box']} chat_receipt_box`}
      style={{
        ...(isChatSelected ? { outline: '1px solid #7043ff' } : {}),
        // backgroundColor: !item.can_be_used ? '#ffe6e6' : '',
      }}
      onClick={handleOnClick}>
      <div className='chat_receipt_box__main'>
        <div className={styles.chat_receipt_box__topSection}>
          <h3 style={{ display: 'flex', alignItems: 'center' }} onClick={handleOpenPlatform}>
            {unread_count > 0 && (
              <div className={styles.unRead}>
                <span className={styles.redDot}></span>
              </div>
            )}
            <CustomAvatar src={photo} name={recipientName} size='small' />
            {recipientName?.length > 30 ? (
              <Tooltip title={recipientName}>{recipientName?.slice(0, 30)?.trim()}...</Tooltip>
            ) : (
              recipientName
            )}
          </h3>
          <div className={styles.chat_receipt_box__topSection__right} onClick={handleStopPropagation}>
            <span className={styles.chat_receipt_box__topSection__time}>{formatDate(timeAgo)}</span>
            <Dropdown menu={{ items }}>
              <Space>
                <Avatar
                  style={{ backgroundColor: 'transparent', color: '#000000', fontSize: '18px' }}
                  size={24}
                  icon={<EllipsisOutlined />}
                />
              </Space>
            </Dropdown>
          </div>
        </div>
        <div className={styles.chat_receipt_box__middleSection}>
          <p>
            {latestMessage?.length > 100 ? (
              <Tooltip title={latestMessage}>{latestMessage.slice(0, 100)?.trim()}...</Tooltip>
            ) : (
              latestMessage
            )}
          </p>
          <div className={styles.chat_receipt_box__middleSection__icons}>
            {pin && <PushpinFilled style={{ color: '#7043ff' }} />}
            {unread_count > 0 && <span style={{ color: '#97a0af' }}>{unread_count}</span>}
          </div>
        </div>
        <div className={`${styles['chat_receipt_box__bottomSection']} chat_receipt_box__bottomSection`}>
          <Select
            className='selectPriority'
            size='small'
            // defaultValue={defaultPriority}
            // value={defaultPriority}
            value={dialogs_metadata[recipientId]?.priority || priorities[recipientId] || priority}
            style={{ width: 99 }}
            onClick={handleStopPropagation}
            placeholder='Priority'
            onChange={handleChangePriority}>
            <Select.Option value='high'>
              <div className={styles.priority}>
                <span className='select_dots'></span>
                <span>High</span>
              </div>
            </Select.Option>
            <Select.Option value='medium'>
              <div className={styles.priority}>
                <span className='select_dots' style={{ backgroundColor: '#FFC700' }}></span>
                <span>Medium</span>
              </div>
            </Select.Option>
            <Select.Option value='low'>
              <div className={styles.priority}>
                <span className='select_dots' style={{ backgroundColor: 'green' }}></span>
                <span>Low</span>
              </div>
            </Select.Option>
          </Select>
          {additionalTags.slice(0, 1).map((tag) => (
            <Tag style={{ margin: 0 }} key={tag}>
              {tag?.length > 18 ? <Tooltip title={tag}>{tag.slice(0, 18)?.trim()}...</Tooltip> : tag}
            </Tag>
          ))}
          {additionalTags.length > 1 && (
            <Tooltip
              overlayInnerStyle={{ background: 'white' }}
              title={
                <div className={styles.tagsTooltip}>
                  {additionalTags.slice(1).map((tag, index, array) => (
                    <Tag
                      style={{
                        backgroundColor: '#f2efff',
                        color: '#7043ff',
                        borderColor: '#f2f0ff',
                        marginRight: index === array.length - 1 ? '0px' : undefined,
                      }}
                      key={tag}>
                      {tag}
                    </Tag>
                  ))}
                </div>
              }>
              <Tag
                style={{
                  backgroundColor: '#f2efff',
                  color: '#7043ff',
                  borderColor: '#f2f0ff',
                  margin: 0,
                }}>
                +{additionalTags.length - 1}
              </Tag>
            </Tooltip>
          )}
          {tagsOfDialog?.slice(0, 1).map((tag: any) =>
            tag?.tag_name?.length > 11 ? (
              <Tooltip title={tag}>
                <TagItem key={tag.id} title={`${tag.tag_name?.slice(0, 11)?.trim()}...`} />
              </Tooltip>
            ) : (
              <TagItem key={tag.id} title={tag.tag_name} />
            ),
          )}
          {tagsOfDialog?.length > 1 && (
            <Tooltip
              overlayInnerStyle={{ background: 'white' }}
              title={
                <div className={styles.tagsTooltip}>
                  {tagsOfDialog?.slice(1).map((tag: any, index: number) => (
                    <TagItem key={tag.id} title={tag.tag_name} />
                  ))}
                </div>
              }>
              <span className={styles['chat_receipt_box__bottomSection__tag__more']}>+{tagsOfDialog?.length - 1}</span>
            </Tooltip>
          )}
          {/* {!item.can_be_used && (
            <div className={styles.outOfSync}>
              <Tooltip title='Out of Sync'>
                <WarningOutlined style={{ color: 'red', fontSize: '18px' }} />
              </Tooltip>
            </div>
          )} */}
        </div>
      </div>
    </div>
  )
}

export default React.memo(ChatDialog)
