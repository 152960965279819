import { message } from 'antd'
import { isArray, lowerCase } from 'lodash'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import {
  useLazyAttachTagToDialogQuery,
  useLazyCreateTagQuery,
  useLazyGetAllTagByUserIdQuery,
  useLazyGetDialogsQuery,
  useLazyGetTagMultiDialogQuery,
  useLazyRemoveTagFromDialogQuery,
  useLazyRemoveTagQuery,
  useLazyUpdateTagQuery,
} from '../../state/api/Chat-API'
import { SET_ALL_TAG_DIALOG, SET_TAG_DIALOG } from '../../state/chatSlice'

export const useTagDialog = (dialogId: any) => {
  const dispatch = useAppDispatch()
  const { current_tags_dialog, is_unify_finished, thread_from } = useAppSelector((state) => state.chat)

  const [loading, setLoading] = React.useState(false)
  const [deleteSuccess, setDeleteSuccess] = React.useState(false)
  const [userTag, setUserTag] = React.useState<any>()

  const filteredTagsDialog = current_tags_dialog?.find((record: any) => record.id === dialogId)

  const [getAllTagByUserId] = useLazyGetAllTagByUserIdQuery()
  const [attachTagToDialog] = useLazyAttachTagToDialogQuery()
  const [removeTagFromDialog] = useLazyRemoveTagFromDialogQuery()
  const [createTag] = useLazyCreateTagQuery()
  const [updateTag] = useLazyUpdateTagQuery()
  const [removeTag] = useLazyRemoveTagQuery()
  const [getDialogs] = useLazyGetDialogsQuery()
  const [getTagMultiDialog] = useLazyGetTagMultiDialogQuery()

  const fetchTagAllDialog = async () => {
    const resListDialog = await getDialogs({ thread_from })
    if (resListDialog?.data) {
      const response = resListDialog.data
      const allDialog = Object.keys(response).map((flatform: any) => {
        return response?.[flatform]?.['dialogData']
      })

      const listIDDialog = allDialog.flat().map((dialog: any) => dialog.id)
      const resListTagDialog = await getTagMultiDialog(listIDDialog)
      if (resListTagDialog) {
        const data = resListTagDialog.data.response
        dispatch(SET_ALL_TAG_DIALOG(Array.isArray(data) ? data : []))

        isArray(data) &&
          data.length > 0 &&
          data.forEach((item: any) => {
            const tagList =
              isArray(item.tags) && item.tags.length > 0
                ? item.tags.map((tag: any) => {
                    return {
                      ...tag,
                      dialog_id: item.dialog_id,
                    }
                  })
                : []
            dispatch(
              SET_TAG_DIALOG({
                id: item?.dialog_id,
                data: tagList,
              }),
            )
          })
      }
    }
  }

  const fetchTags = async () => {
    setLoading(true)
    try {
      const res = await getTagMultiDialog([dialogId])

      if (res) {
        const data = res.data.response

        const tagList =
          isArray(data) && data.length > 0
            ? data[0]['tags'].map((item: any) => {
                return {
                  ...item,
                  dialog_id: dialogId,
                }
              })
            : []

        dispatch(
          SET_TAG_DIALOG({
            id: dialogId,
            data: tagList,
          }),
        )
      }
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const fetchUserTag = async () => {
    try {
      const res = await getAllTagByUserId()
      if (res) {
        setUserTag(res.data.response)
      }
    } catch (error) {}
  }

  const attachTagToDialogAsync = async (tag: any) => {
    await attachTagToDialog({
      tagId: tag.id,
      dialogId: dialogId,
    })
    message.success({ content: `Attached tag ${tag.tag_name} successfully`, duration: 1 })
    await fetchTags()
    setLoading(false)
    await fetchTagAllDialog()
  }

  const handleAddTagToDialog = async (tagName: string) => {
    const body = { tagName }

    try {
      setLoading(true)

      const isAttachedTag = filteredTagsDialog?.data.find((tag: any) => lowerCase(tag.tag_name) === lowerCase(tagName))

      if (isAttachedTag) {
        message.error({ content: 'Tag is already attached', duration: 1 })
        setLoading(false)
        return
      }

      const tagIfExist = isArray(userTag) && userTag.find((tag) => lowerCase(tag.tag_name) === lowerCase(tagName))
      if (tagIfExist) {
        await attachTagToDialogAsync(tagIfExist)
      } else {
        const dataCreateTab = await createTag(body)
        if (dataCreateTab) {
          const tagId = dataCreateTab.data.response.id
          await attachTagToDialogAsync({
            id: tagId,
            tag_name: tagName,
          })
        }
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const handleRemoveTagFromDialog = async (tagId: string) => {
    setLoading(true)
    await removeTagFromDialog({
      tagId,
      dialogId,
    })
    await fetchTags()
    await fetchTagAllDialog()
    setLoading(false)
  }

  const handleUpdateTag = async (body: any) => {
    try {
      setLoading(true)
      setDeleteSuccess(false)
      await updateTag(body)
      await fetchTags()
      setLoading(false)
      setDeleteSuccess(true)
    } catch (error: any) {
      alert({ error })
      message.error({ content: error, duration: 1 })
    }
  }

  const handleRemoveTag = async (tagId: any) => {
    try {
      setLoading(true)
      setDeleteSuccess(false)
      await removeTag(tagId)
      await fetchTags()
      await fetchUserTag()
      setLoading(false)
      setDeleteSuccess(true)
    } catch (error: any) {
      message.error({ content: error, duration: 1 })
    }
  }

  React.useEffect(() => {
    if (is_unify_finished) {
      fetchUserTag()
      fetchTagAllDialog()
    }
  }, [is_unify_finished])

  return {
    loading,
    tagsOfDialog: filteredTagsDialog?.data,
    deleteSuccess,
    setLoading,
    handleRemoveTagFromDialog,
    handleAddTagToDialog,
    handleUpdateTag,
    handleRemoveTag,
  }
}
