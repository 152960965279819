import { Button, Card, Checkbox, Divider, Form, Input, message, Table, Typography } from 'antd'
import { useEffect, useState } from 'react'
import styles from './GenerateRegistrationCode.module.scss'
import { useLazyGetAffiliatesTableQuery, useLazyGetRegistrationURLQuery } from '../state/api/SettingsApi'

const { Title } = Typography

const GenerateRegistrationCode: React.FC = () => {
  const [url, setUrl] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingTable, setLoadingTable] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [skipOutside, setSkipOutside] = useState<boolean>(false)
  const [skipEmailVerification, setSkipEmailVerification] = useState<boolean>(false)
  const [generateUrl] = useLazyGetRegistrationURLQuery()
  const [getTable] = useLazyGetAffiliatesTableQuery()
  const [tableData, setTableData] = useState<any>([])

  const handleGenerateToken = async () => {
    if (!name) {
      message.error('Name is required')
      return
    }

    try {
      setLoading(true)
      const response = await generateUrl({
        name,
        settings: { skip_outside_onboarding: skipOutside, skip_email_verification: skipEmailVerification },
      })
      setUrl(response.data.url)
      message.success('URL generated successfully')
    } catch (error) {
      message.error('Error generating URL')
      console.error('Error generating URL', error)
    } finally {
      setLoading(false)
    }
  }

  const refinedData = tableData.map((data: any) => ({
    id: data.id,
    email: data.email,
    link_name: data?.affiliate_data?.name,
    is_skip_outside_onboarding: data?.affiliate_data?.is_skip_outside_onboarding,
    subscription_type: data?.subscription_type,
  }))

  const init = async () => {
    try {
      const res = await getTable()
      setTableData(res.data?.affiliates)
    } catch (error) {
      console.error('Error fetching table data', error)
    }
  }

  useEffect(() => {
    setLoadingTable(true)
    init().then(() => setLoadingTable(false))

    const interval = setInterval(() => {
      init()
    }, 10000)

    return () => clearInterval(interval)
  }, [])

  return (
    <Card className={styles.profileCard}>
      <div>
        <Title level={3}>Generate Registration URL</Title>
        <Form layout='vertical' className={styles.form}>
          <Form.Item label='Name'>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder='Enter Name'
              style={{ maxWidth: '300px' }}
            />
          </Form.Item>
          <Form.Item label='Description'>
            <Input
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder='Enter Name'
              style={{ maxWidth: '300px' }}
            />
          </Form.Item>
          <Form.Item>
            <Checkbox checked={skipOutside} onChange={(e) => setSkipOutside(e.target.checked)}>
              Skip Outside Onboarding
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox checked={skipEmailVerification} onChange={(e) => setSkipEmailVerification(e.target.checked)}>
              Skip Email Verification
            </Checkbox>
          </Form.Item>
          {url && (
            <Form.Item label='Generated URL' style={{ marginTop: '20px' }}>
              <Input.TextArea value={url} readOnly autoSize={{ minRows: 2, maxRows: 4 }} />
            </Form.Item>
          )}
          <Form.Item style={{ marginTop: '20px' }}>
            <Button type='primary' onClick={handleGenerateToken} loading={loading}>
              Generate Registration URL
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Divider />
      <div>
        <Title level={3}>Signed Up Affiliates</Title>
        <Table
          dataSource={refinedData}
          loading={loadingTable}
          rowKey={(record) => record.id}
          scroll={{ y: 200 }}
          columns={[
            {
              title: 'Link Name',
              dataIndex: 'link_name',
              key: 'link_name',
            },
            {
              title: 'Email',
              dataIndex: 'email',
              key: 'email',
            },
            {
              title: 'Skip Outside Onboarding',
              dataIndex: 'is_skip_outside_onboarding',
              key: 'is_skip_outside_onboarding',
              render: (is_skip_outside_onboarding: boolean) => (is_skip_outside_onboarding ? '✅' : '❌'),
            },
            {
              title: 'Finished',
              dataIndex: 'subscription_type',
              key: 'subscription_type',
              render: (subscription_type: string) => (subscription_type !== 'REGISTERED' ? '✅' : '❌'),
            },
          ]}
        />
      </div>
    </Card>
  )
}

export default GenerateRegistrationCode
