import styles from './Where.module.scss'
import twitter from 'common/assets/svg/twitter_logo.svg'
import linkedin from 'common/assets/svg/linkedin_logo.svg'
import google from 'common/assets/svg/google_icon.svg'
import youtube from 'common/assets/png/Youtube.png'
import facebook from 'common/assets/svg/facebook_logo.svg'
import tiktok from 'common/assets/svg/TikTok.svg'
import instagram from 'common/assets/svg/instagram_icon.svg'
import reddit from 'common/assets/svg/reddit_icon.svg'
import { useAppSelector } from 'state'
import { Input } from 'antd'
interface WhereProps {
  where: string
  setWhere: (value: string) => void
  whatSearch: string
  setWhatSearch: (value: string) => void
  isGoogle: boolean
  setIsGoogle: (value: boolean) => void
}

export default function Where({ where, setWhere, whatSearch, setWhatSearch, isGoogle, setIsGoogle }: WhereProps) {
  const { settings } = useAppSelector((state) => state.signup)
  const wheres: any = settings?.where_did_you_find_us || []
  const { isMobile } = useAppSelector((state) => state.General)
  const valueToImg: any = {
    twitter: twitter,
    linkedin: linkedin,
    google: google,
    youtube: youtube,
    facebook: facebook,
    tiktok: tiktok,
    instagram: instagram,
    reddit: reddit,
  }

  const clickedStyle = {
    borderRadius: ' 10px',
    background: ' #faf9ff',
    border: '1px solid #7144FF',
  }
  const handleGoogle = () => {
    setWhere('google')
    setIsGoogle(true)
  }
  return (
    <div className={styles.frame}>
      <div className={styles.frame_title}>
        <span className={styles.text}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: 5 }}>
            {isGoogle && <img src={google} alt='Google' style={{ width: 15, height: 15 }} />}
            {isGoogle ? 'What were you searching on Google? ' : 'Where did you find us?'}
          </div>
        </span>
      </div>
      {isMobile ? (
        <>
          {isGoogle ? (
            <>
              <Input placeholder='What did you search?'></Input>
            </>
          ) : (
            <div className={styles.placesMobile}>
              {wheres
                .filter((where: any) => where.value === 'google')
                .map((place: any) => (
                  <div
                    className={styles.socialMobile}
                    style={{ cursor: 'pointer', ...(where === place.value ? clickedStyle : {}) }}
                    onClick={() => handleGoogle()}>
                    <div className={styles.text_frame}>
                      <span className={styles.text}>{place.label}</span>
                    </div>
                    <div className={styles.logo}>
                      <img src={valueToImg[place.value]} alt='Social' />
                    </div>
                  </div>
                ))}
              <div
                className={styles.friendMobile}
                style={{ cursor: 'pointer', ...(where === 'friend' ? clickedStyle : {}) }}
                onClick={() => setWhere('friend')}>
                <span className={styles.text}>A friend</span>
              </div>
              {wheres
                .filter((where: any) => where.value !== 'google')
                .map((place: any) => (
                  <div
                    className={styles.socialMobile}
                    style={{ cursor: 'pointer', ...(where === place.value ? clickedStyle : {}) }}
                    onClick={() => setWhere(place.value)}>
                    <div className={styles.text_frame}>
                      <span className={styles.text}>{place.label}</span>
                    </div>
                    <div className={styles.logo}>
                      <img src={valueToImg[place.value]} alt='Social' />
                    </div>
                  </div>
                ))}
            </div>
          )}
        </>
      ) : (
        <>
          {isGoogle ? (
            <>
              <>
                <Input placeholder='What was your search?' onChange={(e) => setWhatSearch(e.target.value)}></Input>
              </>
            </>
          ) : (
            <div className={styles.places}>
              {wheres
                .filter((where: any) => where.value === 'google')
                .map((place: any) => (
                  <div
                    className={styles.social}
                    style={{ cursor: 'pointer', ...(where === place.value ? clickedStyle : {}) }}
                    onClick={() => handleGoogle()}>
                    <div className={styles.text_frame}>
                      <span className={styles.text}>{place.label}</span>
                    </div>
                    <div className={styles.logo}>
                      <img src={valueToImg[place.value]} alt='Social' />
                    </div>
                  </div>
                ))}

              <div
                className={styles.friend}
                style={{ cursor: 'pointer', ...(where === 'friend' ? clickedStyle : {}) }}
                onClick={() => setWhere('friend')}>
                <span className={styles.text}>A friend</span>
              </div>
              {wheres
                .filter((where: any) => where.value !== 'google')
                .map((place: any) => (
                  <div
                    className={styles.social}
                    style={{ cursor: 'pointer', ...(where === place.value ? clickedStyle : {}) }}
                    onClick={() => setWhere(place.value)}>
                    <div className={styles.text_frame}>
                      <span className={styles.text}>{place.label}</span>
                    </div>
                    <div className={styles.logo}>
                      <img src={valueToImg[place.value]} alt='Social' />
                    </div>
                  </div>
                ))}
            </div>
          )}
        </>
      )}
    </div>
  )
}
