import React, { useEffect, useState } from 'react'
import { Table, Skeleton, Drawer, Button, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import gray_eye from 'common/assets/svg/gray_eye.svg'
import drag from 'common/assets/svg/drag.svg'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import type { TableProps } from 'antd'
import styles from './ModularTable.module.scss'
import { useAppDispatch, useAppSelector } from 'state'
import {
  SET_DEFAULT_COLUMNS,
  SET_MODULAR_HIDDEN_COLUMNS,
  SET_MODULAR_VISIBLE_COLUMNS,
} from 'common/components/OutreachAI/state/outreachAICampaignSlice'
import { useLazyUpdateDefaultColumnsQuery } from 'features/Outreach/state/api/OutreachApi'
import CustomStatusTag from 'common/components/OutreachAI/CreateCampaign/UI/CustomStatusTag'

interface ModularTableProps extends TableProps<any> {
  columns: any
  data: any[]
  loadingTable?: boolean
  pageSize?: number
  rowSelection?: any
  isExpanded?: boolean
  openDrawer: boolean
  setOpenDrawer: (open: boolean) => void
  lastStep?: boolean
  defaultColumns?: any
}

const ModularTable: React.FC<ModularTableProps> = ({
  columns,
  data,
  loadingTable = false,
  pageSize = 10,
  rowSelection,
  isExpanded = false,
  openDrawer,
  setOpenDrawer,
  lastStep = false,
  defaultColumns,
  ...tableProps
}) => {
  const dispatch = useAppDispatch()
  const [searchTerm, setSearchTerm] = useState('')
  const [updateDefaultColumns] = useLazyUpdateDefaultColumnsQuery()
  const {
    campaign_id,
    default_columns,
    modularVisibleColumns: visibleColumns,
    modularHiddenColumns: hiddenColumns,
  } = useAppSelector((state) => state.outreachAICampaign)

  const handleClose = () => {
    const defaultColumnsKeys = defaultColumns
      ? defaultColumns
      : [...default_columns, lastStep ? last_step_col.key : null].filter((col) => col)

    let visibleColumnsNew = columns?.filter((col: any) => defaultColumnsKeys?.includes(col.key))
    const hiddenColumnsNew = columns?.filter((col: any) => !defaultColumnsKeys?.includes(col.key))

    // sort visible columns based on the order of default columns
    visibleColumnsNew = visibleColumnsNew.sort((a: any, b: any) => {
      return defaultColumnsKeys.indexOf(a.key) - defaultColumnsKeys.indexOf(b.key)
    })

    dispatch(SET_MODULAR_VISIBLE_COLUMNS(visibleColumnsNew))
    dispatch(SET_MODULAR_HIDDEN_COLUMNS(hiddenColumnsNew))

    toggleDrawer()
  }
  const toggleDrawer = () => setOpenDrawer(!openDrawer)
  const handleSave = async () => {
    toggleDrawer()
    dispatch(SET_DEFAULT_COLUMNS(visibleColumns?.map((col: any) => col.key)))

    await updateDefaultColumns({
      campaign_id: campaign_id,
      default_columns: visibleColumns?.map((col: any) => col.key),
    })
  }
  const modifiedColumns = visibleColumns.map((column: any) => ({
    ...column,
    hidden: false,
  }))
  const last_step_col = {
    title: 'Status',
    dataIndex: 'last_step',
    key: 'last_step',
    width: 150,
    render: (text: string, data: any) => {
      return <CustomStatusTag status={text || 'Pending'} />
    },
  }
  // take default columns, set the to visible columns and hide every other column

  useEffect(() => {
    const defaultColumnsKeys = defaultColumns
      ? defaultColumns
      : [...default_columns, lastStep ? last_step_col.key : null].filter((col) => col)

    let visibleColumnsNew = columns?.filter((col: any) => defaultColumnsKeys?.includes(col.key))
    const hiddenColumnsNew = columns?.filter((col: any) => !defaultColumnsKeys?.includes(col.key))

    // sort visible columns based on the order of default columns
    visibleColumnsNew = visibleColumnsNew.sort((a: any, b: any) => {
      return defaultColumnsKeys.indexOf(a.key) - defaultColumnsKeys.indexOf(b.key)
    })

    dispatch(SET_MODULAR_VISIBLE_COLUMNS(visibleColumnsNew))
    dispatch(SET_MODULAR_HIDDEN_COLUMNS(hiddenColumnsNew))
  }, [])

  const onDragEnd = (result: any) => {
    const { source, destination } = result

    if (!destination) return
    if (destination.droppableId !== 'visibleColumns' && visibleColumns.length === 1) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      const items = Array.from(source.droppableId === 'visibleColumns' ? visibleColumns : hiddenColumns)
      const [reorderedItem] = items.splice(source.index, 1)
      items.splice(destination.index, 0, reorderedItem)

      source.droppableId === 'visibleColumns'
        ? dispatch(SET_MODULAR_VISIBLE_COLUMNS(items))
        : dispatch(SET_MODULAR_HIDDEN_COLUMNS(items))
    } else {
      const sourceList = Array.from(source.droppableId === 'visibleColumns' ? visibleColumns : hiddenColumns)
      const destinationList = Array.from(destination.droppableId === 'visibleColumns' ? visibleColumns : hiddenColumns)

      const [movedItem] = sourceList.splice(source.index, 1)
      destinationList.splice(destination.index, 0, movedItem)

      source.droppableId === 'visibleColumns'
        ? dispatch(SET_MODULAR_VISIBLE_COLUMNS(sourceList)) && dispatch(SET_MODULAR_HIDDEN_COLUMNS(destinationList))
        : dispatch(SET_MODULAR_VISIBLE_COLUMNS(destinationList)) && dispatch(SET_MODULAR_HIDDEN_COLUMNS(sourceList))
    }
  }

  // Filter columns based on the search term
  const filteredVisibleColumns = visibleColumns.filter((col: any) =>
    col.title.toLowerCase().includes(searchTerm.toLowerCase()),
  )
  const filteredHiddenColumns = hiddenColumns.filter((col: any) =>
    col.title.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  return (
    <div className={styles.container}>
      <Table
        {...tableProps}
        dataSource={
          loadingTable
            ? [...Array(10)].map((_, index) => ({
                key: `key${index}`,
              }))
            : data
        }
        columns={
          loadingTable
            ? modifiedColumns.map((column: any) => {
                return {
                  ...column,
                  render: function renderPlaceholder() {
                    return (
                      <Skeleton
                        className='campaignsPageTable'
                        key={column.dataIndex}
                        title={true}
                        paragraph={false}
                        active
                      />
                    )
                  },
                }
              })
            : modifiedColumns
        }
        rowSelection={rowSelection}
        pagination={{
          total: data.length,
          pageSize,
          showSizeChanger: false,
          position: ['bottomCenter'],
        }}
        scroll={{ y: 300, x: 'max-content' }}
        className='Leads_Table Modular_Table_Expand'
      />

      <Drawer
        className='modularTable'
        rootClassName='modularTable'
        maskClosable={false}
        bodyStyle={{ backgroundColor: '#f3f4f6' }}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>Properties</span>
            <Button type='primary' onClick={handleSave}>
              Save
            </Button>
          </div>
        }
        placement='right'
        closable={true}
        onClose={handleClose}
        open={openDrawer}
        width={450}>
        <Input
          prefix={<SearchOutlined style={{ color: '#97A0AF', fontSize: '16px' }} />}
          placeholder='Search for Properties'
          size='large'
          style={{ marginBottom: '1rem', fontSize: '14px' }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <div className={styles.title}>
          <span>Showing in table</span>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='visibleColumns'>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ display: 'flex', flexDirection: 'column' }}>
                {filteredVisibleColumns.map((col: any, index: any) => (
                  <Draggable key={col.key} draggableId={col.key} index={index}>
                    {(provided) => (
                      <div
                        className={styles.draggableItem}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{ ...provided.draggableProps.style }}>
                        <div className={styles.handle} {...provided.dragHandleProps}>
                          <img src={drag} alt='' />
                        </div>
                        <div className={styles.innerBody}>
                          <span>{col.title}</span>
                          <Button
                            type='link'
                            onClick={() => {
                              if (visibleColumns.length === 1) {
                                return
                              }
                              dispatch(SET_MODULAR_HIDDEN_COLUMNS([...hiddenColumns, col]))
                              dispatch(
                                SET_MODULAR_VISIBLE_COLUMNS(visibleColumns.filter((c: any) => c.key !== col.key)),
                              )
                            }}>
                            <img src={gray_eye} alt='' />
                          </Button>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <div className={styles.title}>Hidden</div>
          <Droppable droppableId='hiddenColumns'>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {filteredHiddenColumns.map((col: any, index: any) => (
                  <Draggable key={col.key} draggableId={col.key} index={index}>
                    {(provided) => (
                      <div
                        className={styles.draggableItem}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{ ...provided.draggableProps.style }}>
                        <div className={styles.handle} {...provided.dragHandleProps}>
                          <img src={drag} alt='' />
                        </div>
                        <div className={styles.innerBody}>
                          <span>{col.title}</span>
                          <Button
                            type='link'
                            onClick={() => {
                              dispatch(SET_MODULAR_VISIBLE_COLUMNS([...visibleColumns, col]))
                              dispatch(SET_MODULAR_HIDDEN_COLUMNS(hiddenColumns.filter((c: any) => c.key !== col.key)))
                            }}>
                            <img src={gray_eye} alt='' />
                          </Button>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Drawer>
    </div>
  )
}

export default ModularTable
