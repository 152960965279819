import React from 'react'
import { Card, Skeleton } from 'antd'
import styles from './GroupCard.module.scss'

const GroupSkeleton: React.FC = () => {
  return (
    <Card className={`addLeadsModal ${styles.groupCard} `} hoverable style={{ cursor: 'default' }}>
      <div className={styles.cardImage}>
        <div style={{ minHeight: '46px' }}>
          <>
            <>
              <Skeleton.Avatar shape='circle' size={'large'} rootClassName='mediumRoundPicture' active />
            </>
          </>
        </div>
      </div>
      <div className={styles.cardHeader}>
        <Skeleton.Input style={{ width: '100%', height: '10px' }} active size='small' />
      </div>
      <div className={styles.leads}>
        <Skeleton.Input style={{ width: '100%', height: '10px' }} active size='small' />
      </div>
      <div className={styles.leads}>
        <Skeleton.Input style={{ width: '100%', height: '10px' }} active size='small' />
      </div>
      <div className={styles.cardBody}>
        <Skeleton.Input style={{ width: '100%' }} active size='large' />
        <div className={styles.groupImportanatStatus}>
          <span style={{ position: 'relative', cursor: 'pointer', zIndex: 3333 }}>.</span>
        </div>
      </div>
    </Card>
  )
}

export default GroupSkeleton
