import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import { useGoogleLogin } from '@react-oauth/google'
import { Form, Input, Button, Divider, message } from 'antd'
import convrt_logo_login from 'common/assets/svg/convrt_logo_login.svg'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { AuthService } from 'common/services/authServices'
import { Buttons } from 'common/constants/label.constants'

import styles from './login.module.scss'
// import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'state'
import { useLocalStorage } from 'usehooks-ts'
import { LocalStorageKeys } from 'common/constants/localstorage.constants'

interface LoginProps {
  isCustomerService?: boolean
}

const Login: React.FC<LoginProps> = ({ isCustomerService }) => {
  const [user, setUser] = useState<any>([])
  const [, setBearer] = useLocalStorage(LocalStorageKeys.ACCESS_TOKEN, null)
  const [, setUserDetails] = useLocalStorage('user', null)
  const [form] = Form.useForm()
  // const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { isMobile } = useAppSelector((state) => state.General)
  const [, setStep] = useLocalStorage('step', 0)
  // const [errorLogin, setErrorLogin] = useState('')
  const { login: handleLogin } = useAuth()
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      setLoading(true)
      const result = await AuthService.doLogin(values)
      if (result?.step === 1) {
        setStep(1)
        setBearer(result.accessToken)
        setUserDetails(JSON.parse(result.user))
        window.open(result.url, '_self')
        return
      }
      if (result?.step > 1) {
        setStep(result?.step)
        setBearer(result.accessToken)
        setUserDetails(JSON.parse(result.user))
        window.open(result.url, '_self')
        return
      }
      if (result?.url) {
        return window.open(result.url, '_self')
      }
      if (result.accessToken) {
        handleLogin(result)
      } else {
        if (result.code === 'ERR_BAD_REQUEST') {
          // setErrorLogin(result.response.data.message)
          message.error(result.response.data.message)
        } else {
          // setErrorLogin(result.message)
          // message.error(result.message)
        }
      }
      localStorage.setItem('dismissedUsers', JSON.stringify([]))
      setLoading(false)
    },
  })

  const handleSignUpNavigate = () => {
    // navigate('/signup')
    // window.open('https://bit.ly/40e4itx', '_self')
    const currentUrl = window.location.origin
    const signupUrl = `${currentUrl}/register/d78f660d-f8c4-447b-8c05-5950cfb43c28`
    window.open(signupUrl, '_self')
  }

  const handleTerms = () => {
    window.open('https://www.convrt.io/terms-of-use', '_blank')
  }
  const handlePrivacyPolicy = () => {
    window.open('https://www.convrt.io/privacy-policy', '_blank')
  }

  const handleForgotPassword = async () => {
    const values = formik.values
    if (values) {
      const result = await AuthService.getUserIdbyEmail(values)
      const user_id = result?.id
      if (user_id) {
        const email = formik.values.email
        message.success(`Check your inbox! reset link has been sent to: ${email}`)
      } else {
        message.error('Email does not exist')
      }
    } else {
      message.error('Please enter your email address.')
    }
  }

  // const handleForgotPassword1 = async () => {
  //   const email = formik.values.email
  //   const path = `/reset-password/${email}`
  //   navigate(path, { state: { email } })
  // }

  const handleCustomerServiceLogin = async () => {
    const values = await form.getFieldsValue()
    const result = await AuthService.doCSLogin(values)
    if (result.accessToken) {
      handleLogin(result)
    } else {
      if (result.code === 'ERR_BAD_REQUEST') {
        // setErrorLogin(result.response.data.message)
        message.error(result.response.data.message)
      } else {
        // setErrorLogin(result.message)
        message.error(result.message)
      }
    }
  }

  const googleSSOLogin = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.error('Google SSO Login Failed:', error),
  })

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json',
            },
          })
          .then(async (res) => {
            const resultLogin = await AuthService.doSSOLogin({ email: res.data.email })

            if (resultLogin?.step === 1) {
              setStep(1)
              setBearer(resultLogin.accessToken)
              setUserDetails(JSON.parse(resultLogin.user))
              window.open(resultLogin.url, '_self')
              return
            }
            if (resultLogin?.step > 1) {
              setStep(resultLogin?.step)
              setBearer(resultLogin.accessToken)
              setUserDetails(JSON.parse(resultLogin.user))
              window.open(resultLogin.url, '_self')
              return
            }

            if (resultLogin?.url) {
              return window.open(resultLogin.url, '_self')
            }
            if (resultLogin.accessToken) {
              handleLogin(resultLogin)
            }
          })
          .catch((err) => console.error(err))
      }
    }
    fetchData().catch(console.error)
  }, [user])

  // const onForgotPasswordClick = () => {
  //   const email = formik.values.email
  //   if (email) {
  //     navigate('/reset-password', { state: { email } })
  //   } else {
  //     alert('Please enter your email address.')
  //   }
  // }

  const handleNavigate = () => {
    window.open('https://www.convrt.ai/', '_self')
  }

  return (
    <>
      {isMobile ? (
        <>
          <div className={styles.holdingContainerMobile}>
            <div className={styles.page}>
              <div className={styles.innerPageMobile}>
                <div className={styles.leftMobile}>
                  <div className={styles.logoMobile}>
                    <img onClick={handleNavigate} style={{ cursor: 'pointer' }} src={convrt_logo_login} alt='' />
                  </div>
                  <div className={styles.main}>
                    <div className={styles.contentMobile}>
                      <div className={styles.cardWrapperMobile}>
                        <div className={styles.cardMobile}>
                          <div className={styles.form}>
                            <div className={styles.header}>
                              <p>Welcome</p>
                              <span>
                                Please enter your account details or sign in with
                                <br />
                                Google to begin using Convrt.
                              </span>
                            </div>
                            {!isCustomerService && (
                              <>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                  <button
                                    className='gsi-material-button'
                                    onClick={() => {
                                      googleSSOLogin()
                                    }}>
                                    <div className='gsi-material-button-state'></div>
                                    <div className='gsi-material-button-content-wrapper'>
                                      <div className='gsi-material-button-icon'>
                                        <svg
                                          version='1.1'
                                          xmlns='http://www.w3.org/2000/svg'
                                          viewBox='0 0 48 48'
                                          xmlnsXlink='http://www.w3.org/1999/xlink'
                                          style={{ display: 'block' }}>
                                          <path
                                            fill='#EA4335'
                                            d='M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z'></path>
                                          <path
                                            fill='#4285F4'
                                            d='M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z'></path>
                                          <path
                                            fill='#FBBC05'
                                            d='M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z'></path>
                                          <path
                                            fill='#34A853'
                                            d='M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z'></path>
                                          <path fill='none' d='M0 0h48v48H0z'></path>
                                        </svg>
                                      </div>
                                      <span className='gsi-material-button-contents'>Sign in with Google</span>
                                      <span style={{ display: 'none' }}>Sign in with Google</span>
                                    </div>
                                  </button>
                                </div>
                                <Divider className={styles.Divider} style={{ margin: '32px 0px' }}>
                                  <span className={styles.Text}>Or</span>
                                </Divider>
                              </>
                            )}
                            <Form
                              form={form}
                              onFinish={isCustomerService ? handleCustomerServiceLogin : formik.handleSubmit}>
                              <div className={styles.formRow}>
                                <span className={styles.type}>Email</span>
                                <Form.Item
                                  style={{ marginTop: '10px' }}
                                  name='email'
                                  rules={[
                                    {
                                      type: 'email',
                                      message: 'Invalid email address',
                                    },
                                    {
                                      required: true,
                                      message: 'Email is required',
                                    },
                                  ]}>
                                  <Input
                                    style={{ height: 40, borderRadius: '10px' }}
                                    {...formik.getFieldProps('email')}
                                    placeholder='Email'
                                  />
                                </Form.Item>
                              </div>
                              <div className={styles.formRow}>
                                <div className={styles.password}>
                                  <span className={styles.type}>Password</span>
                                  <span className={styles.forgotPasswordButton} onClick={handleForgotPassword}>
                                    Forgot your password?
                                  </span>
                                </div>
                                <Form.Item
                                  style={{ marginTop: '10px' }}
                                  name='password'
                                  className='password'
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Password is required',
                                    },
                                    {
                                      min: 8,
                                      message: 'Invalid credentials',
                                    },
                                  ]}>
                                  <Input.Password
                                    size='small'
                                    style={{ height: 40, color: 'rgba(255,255,255)', borderRadius: '10px' }}
                                    {...formik.getFieldProps('password')}
                                    placeholder='Password'
                                  />
                                </Form.Item>
                              </div>
                              {/* {errorLogin !== '' ? <p className={styles.error}>{errorLogin}</p> : null} */}
                              <Form.Item>
                                <Button type='primary' className={styles.btnLogin} htmlType='submit' loading={loading}>
                                  {Buttons.LOG_IN}
                                </Button>
                              </Form.Item>
                            </Form>{' '}
                            <div className={styles.footer}>
                              <span>
                                By signing in, you agree to our{' '}
                                <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleTerms}>
                                  Terms
                                </span>{' '}
                                &{' '}
                                <span
                                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                  onClick={handlePrivacyPolicy}>
                                  Privacy Policy.
                                </span>
                              </span>
                              <span style={{ paddingBottom: '20px' }}>
                                Don't have an account?{' '}
                                <span onClick={handleSignUpNavigate} style={{ color: '#005AF4', cursor: 'pointer' }}>
                                  Sign Up Now!
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: '30px',
                  width: '100%',
                  background: 'transparent',
                  zIndex: -5,
                  position: 'relative',
                }}></div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.holdingContainer}>
            <div className={styles.logo}>
              <img onClick={handleNavigate} style={{ cursor: 'pointer' }} src={convrt_logo_login} alt='' />
            </div>
            <div className={styles.form}>
              <div className={styles.header}>
                <p>Welcome</p>
                <span>
                  Please enter your account details or sign in with
                  <br />
                  Google to begin using Convrt.
                </span>
              </div>
              {!isCustomerService && (
                <>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <button
                      className='gsi-material-button'
                      style={{ width: '400px' }}
                      onClick={() => {
                        googleSSOLogin()
                      }}>
                      <div className='gsi-material-button-state'></div>
                      <div className='gsi-material-button-content-wrapper'>
                        <div className='gsi-material-button-icon'>
                          <svg
                            version='1.1'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 48 48'
                            xmlnsXlink='http://www.w3.org/1999/xlink'
                            style={{ display: 'block' }}>
                            <path
                              fill='#EA4335'
                              d='M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z'></path>
                            <path
                              fill='#4285F4'
                              d='M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z'></path>
                            <path
                              fill='#FBBC05'
                              d='M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z'></path>
                            <path
                              fill='#34A853'
                              d='M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z'></path>
                            <path fill='none' d='M0 0h48v48H0z'></path>
                          </svg>
                        </div>
                        <span className='gsi-material-button-contents'>Sign in with Google</span>
                        <span style={{ display: 'none' }}>Sign in with Google</span>
                      </div>
                    </button>
                  </div>
                  <Divider className={styles.Divider} style={{ margin: '32px 0px' }}>
                    <span className={styles.Text}>Or</span>
                  </Divider>
                </>
              )}
              <Form form={form} onFinish={isCustomerService ? handleCustomerServiceLogin : formik.handleSubmit}>
                <div className={styles.formRow}>
                  <span className={styles.type}>Email</span>
                  <Form.Item
                    style={{ marginTop: '10px' }}
                    name='email'
                    rules={[
                      {
                        type: 'email',
                        message: 'Invalid email address',
                      },
                      {
                        required: true,
                        message: 'Email is required',
                      },
                    ]}>
                    <Input
                      style={{ height: 40, borderRadius: '10px' }}
                      {...formik.getFieldProps('email')}
                      placeholder='Email'
                    />
                  </Form.Item>
                </div>
                <div className={styles.formRow}>
                  <div className={styles.password}>
                    <span className={styles.type}>Password</span>
                    <span className={styles.forgotPasswordButton} onClick={handleForgotPassword}>
                      Forgot your password?
                    </span>
                  </div>
                  <Form.Item
                    style={{ marginTop: '10px' }}
                    name='password'
                    className='password'
                    rules={[
                      {
                        required: true,
                        message: 'Password is required',
                      },
                      {
                        min: 8,
                        message: 'Invalid credentials',
                      },
                    ]}>
                    <Input.Password
                      size='small'
                      style={{ height: 40, color: 'rgba(255,255,255)', borderRadius: '10px' }}
                      {...formik.getFieldProps('password')}
                      placeholder='Password'
                    />
                  </Form.Item>
                </div>
                {/* {errorLogin !== '' ? <p className={styles.error}>{errorLogin}</p> : null} */}
                <Form.Item>
                  <Button type='primary' className={styles.btnLogin} htmlType='submit' loading={loading}>
                    {Buttons.LOG_IN}
                  </Button>
                </Form.Item>
              </Form>
              <span style={{ color: '#97a0af' }}>
                Don't have an account?{' '}
                <span onClick={handleSignUpNavigate} style={{ color: '#005AF4', cursor: 'pointer' }}>
                  Sign Up Now!
                </span>
              </span>
            </div>
            <div className={styles.footer}>
              <span>
                By signing in, you agree to our{' '}
                <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleTerms}>
                  Terms
                </span>{' '}
                &{' '}
                <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handlePrivacyPolicy}>
                  Privacy Policy.
                </span>
              </span>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Login
