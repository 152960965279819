import { Button, Card, Dropdown, Form, Input, MenuProps, message, Modal, Select, Tooltip, Upload } from 'antd'
import styles from './ArticleCard.module.scss'
import ArticleDrawer from './ArticleDrawer/ArticleDrawer'
import { SET_ARTICLE_OPEN } from '../../state/slice/learningSlice'
import { useAppDispatch, useAppSelector } from 'state'
import Dots from 'common/assets/svg/three_dots.svg'
import {
  useLazyChangeArticleStatusQuery,
  useLazyDeleteArticleQuery,
  useLazyPriorityUpOrDownAllQuery,
  useLazyPriorityUpOrDownQuery,
} from '../../state/api/LearningCenterAPI'
import { useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { LocalStorageKeys } from 'common/constants/localstorage.constants'
import axios from 'axios'
import { trackEvent } from 'eventConfig'
interface ArticleCardProps {
  article: any
  onClose: () => void
  onOpen: () => void
  fade?: boolean
  all?: boolean
}
export default function ArticleCard({ article, onClose, onOpen, fade = false, all = false }: ArticleCardProps) {
  const dispatch = useAppDispatch()
  const { title, description, reading_min, html, id: articleID, cover, category } = article
  const { isAdmin, allCategories } = useAppSelector((state) => state.learning)
  const [changeArticleStatus] = useLazyChangeArticleStatusQuery()
  const [deleteARticle] = useLazyDeleteArticleQuery()
  const [editArticleModalVisible, setEditArticleModalVisible] = useState(false)
  const [newCoverImage, setNewCoverImage] = useState<any>()
  let allCategoriesWithArticles = [...allCategories, { title: 'Articles', name: 'articles' }]
  const options = allCategoriesWithArticles?.map((category: any) => ({
    label: category.title,
    value: category.name,
  }))
  const [priorityUpOrDown] = useLazyPriorityUpOrDownQuery()
  const [priorityUpOrDownAll] = useLazyPriorityUpOrDownAllQuery()
  const adminDropdownItems: MenuProps['items'] = [
    {
      key: '1',
      label: 'Unpublish',
    },
    {
      key: '2',
      label: 'Publish',
    },
    {
      key: '3',
      label: 'Delete',
    },
    {
      key: '4',
      label: 'Edit',
    },
    {
      key: '5',
      label: 'Move Up',
    },
    {
      key: '6',
      label: 'Move Down',
    },
  ]
  const publish = async () => {
    await changeArticleStatus({ articleID, status: true })
    message.success('Article published successfully - please refresh')
  }
  const unpublish = async () => {
    await changeArticleStatus({ articleID, status: false })
    message.success('Article unpublished successfully - please refresh')
  }
  const deleteArticle = async () => {
    await deleteARticle({ articleID })
    message.success('Article deleted successfully - please refresh')
  }
  const handleEditArticle = () => {
    // Open
    setEditArticleModalVisible(true)
  }
  const moveUp = async () => {
    if (all) {
      await priorityUpOrDownAll({ articleID, up: true })
    } else {
      await priorityUpOrDown({ articleID, up: true })
    }
    message.success('Article moved up successfully - please refresh')
  }
  const moveDown = async () => {
    if (all) {
      await priorityUpOrDownAll({ articleID, up: false })
    } else {
      await priorityUpOrDown({ articleID, up: false })
    }
    message.success('Article moved down successfully - please refresh')
  }
  const handleAdminMenuClick: MenuProps['onClick'] = (e) => {
    e.domEvent.stopPropagation()
    e.domEvent.preventDefault()
    if (e.key === '1') {
      unpublish()
    }
    if (e.key === '2') {
      publish()
    }
    if (e.key === '3') {
      deleteArticle()
    }
    if (e.key === '5') {
      moveUp()
    }
    if (e.key === '6') {
      moveDown()
    }
    if (e.key === '4') {
      handleEditArticle()
    }
  }
  const editArticle = async (values: any) => {
    const coverName = newCoverImage?.fileName
    const formData = new FormData()
    formData.append('title', values.title)
    formData.append('description', values.description)
    formData.append('category', values.category)
    if (newCoverImage) {
      formData.append('cover', new File([new Blob([newCoverImage as any])], coverName as string))
    }
    formData.append('reading_min', values.reading_min)
    formData.append('articleID', articleID)
    const headers = {
      'Content-Type': 'multipart/form-data',
      authorization: `Bearer ${localStorage
        .getItem(LocalStorageKeys.ACCESS_TOKEN)
        ?.substring(1, String(localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)).length - 1)} `,
      environment: `${process.env.REACT_APP_ENVIRONMENT}`,
    }
    try {
      if (newCoverImage) {
        await axios.post(`${process.env.REACT_APP_API_URL}/learning_center/editArticleWithImage`, formData, {
          headers: headers,
        })
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/learning_center/editArticle`, formData, {
          headers: headers,
        })
      }

      message.success('Article edited successfully')
    } catch (error) {
      message.error('Error editing article')
      console.error('Error editing article:', error)
    }
  }

  return (
    <>
      <Modal
        width={800}
        title='Upload Article'
        open={editArticleModalVisible}
        onCancel={() => setEditArticleModalVisible(false)}
        footer={null}
        zIndex={1002}>
        <Form
          name='basic'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={editArticle}
          autoComplete='off'>
          <Form.Item label='Title' name='title' rules={[{ required: true }]} initialValue={title}>
            <Input defaultValue={title} />
          </Form.Item>

          <Form.Item label='description' name='description' rules={[{ required: true }]} initialValue={description}>
            <Input defaultValue={description} />
          </Form.Item>
          <Form.Item label='reading minutes' name='reading_min' rules={[{ required: true }]} initialValue={reading_min}>
            <Input type='number' defaultValue={reading_min} />
          </Form.Item>

          <Form.Item label='category' name='category' rules={[{ required: true }]} initialValue={category}>
            <Select defaultValue={category} options={options}></Select>
          </Form.Item>

          <Form.Item label='cover image' name='cover'>
            <Upload
              beforeUpload={(file) => {
                setNewCoverImage(file)
                return false
              }}
              onRemove={() => {
                setNewCoverImage(undefined)
              }}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <ArticleDrawer onClose={onClose} articleTitle={title} articlePhoto={cover} content={html} articleID={articleID} />
      <Card
        onClick={() => {
          trackEvent('LEARNING_CENTER_USER_CLICKED_ARTICLE', {
            mediaType: 'Article',
            mediaName: title,
          })
          onOpen()
          dispatch(SET_ARTICLE_OPEN(articleID))
        }}
        className={styles.articleCard}
        bodyStyle={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '8px',
          width: '100%',
          paddingRight: '8px',
          opacity: fade ? '0.5' : '1',
        }}>
        <div style={{ display: 'flex', width: '100%', gap: 10 }}>
          <img alt='Article Cover' src={cover} className={styles.articleImage} /> {/* SHOULD BE image from article */}
          <div className={styles.articleContent}>
            <div className={styles.articleTitle}>
              <Tooltip title={title} placement='top' trigger={['hover', 'focus', 'click']}>
                {title?.length > 40 ? `${title?.trim()?.substring(0, 40)}...` : title}
              </Tooltip>
            </div>
            <div className={styles.articleDescription}>
              <Tooltip title={description} placement='top' trigger={['hover', 'focus', 'click']}>
                {description?.length > 80 ? `${description?.trim()?.substring(0, 80)}...` : description}
              </Tooltip>
            </div>
            <div className={styles.articleMeta}>Article • {reading_min} min</div>
          </div>
        </div>
        {isAdmin && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Dropdown
              placement='topLeft'
              menu={{ items: adminDropdownItems, onClick: handleAdminMenuClick }}
              trigger={['click', 'hover']}>
              <img
                src={Dots}
                alt='3 Dots'
                style={{ cursor: 'pointer', height: '100%', marginRight: 5 }}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              />
            </Dropdown>
          </div>
        )}
      </Card>
    </>
  )
}
