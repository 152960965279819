import { Tooltip } from 'antd'
import { capitalize } from 'lodash'

export const TagsListCut = ({
  tags,
  sliceNum = 2,
  toCapitalize = true,
}: {
  tags: any[]
  sliceNum?: any
  toCapitalize?: boolean
}) => {
  const sortedTags = [...tags]?.sort((a, b) => a.length - b.length)

  const toShowTags = sortedTags.slice(0, sliceNum)

  const toTooltipTags = sortedTags.slice(sliceNum)

  const TooltipContent = () => {
    return (
      <div className='tagsTooltip'>
        {toTooltipTags.length > 0 &&
          toTooltipTags.map((tag, index) => (
            <span className='tooltipContactTags' key={index}>
              {toCapitalize ? capitalize(tag) : tag}
            </span>
          ))}
      </div>
    )
  }

  return (
    <>
      {toShowTags.map((tag, index) => {
        const capTag = toCapitalize ? capitalize(tag) : tag
        return capTag.length > 8 ? (
          <Tooltip title={capTag} placement='top' key={index}>
            <span className='contactTags'>{`${capTag.slice(0, 8)?.trim()}...`}</span>
          </Tooltip>
        ) : (
          <span className='contactTags' key={index}>
            {capTag}
          </span>
        )
      })}
      {toTooltipTags.length > 0 && (
        <Tooltip
          overlayInnerStyle={{ minWidth: '400px', width: 'auto', paddingBottom: '10px' }}
          placement='topLeft'
          title={TooltipContent}>
          <span className='contactTagsNumber'>{`+${toTooltipTags.length}`}</span>
        </Tooltip>
      )}
    </>
  )
}
