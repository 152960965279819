import React, { useState, useEffect } from 'react'
import { DatePicker, Popover, Radio, Select, Space, Switch, message } from 'antd'
import moment from 'moment'
import CustomCollapseFooter from '../UI/CustomCollapseFooter'
import styles from './SchedulingAndConfiguration.module.scss'
import RunInScheduleModal from 'common/components/OutreachCampaign/NewCampaign/UI/RunInScheduleModal'
import { SET_CAMPAIGN_SETTINGS_AI } from '../../state/outreachAICampaignSlice'
import { useAppDispatch, useAppSelector } from 'state'
import dayjs from 'dayjs'
import three_dots from 'common/assets/svg/three_dots.svg'
import { useLazyGetCampaignScheduleQuery } from 'features/Outreach/state/api/OutreachApi'
import { InfoCircleOutlined } from '@ant-design/icons'
import { trackEvent } from 'eventConfig'

// const { Option } = Select

interface SchedulingAndConfigurationProps {
  onBack: () => void
}

const SchedulingAndConfiguration: React.FC<SchedulingAndConfigurationProps> = ({ onBack }) => {
  const dispatch = useAppDispatch()
  const { campaign_settings_ai } = useAppSelector((state) => state.outreachAICampaign)
  const [scheduleType, setScheduleType] = useState(campaign_settings_ai?.timingCampaign ? 'timing' : 'onLaunch')
  const [runInBetweenHoursOptions, setRunInBetweenHoursOptions] = useState<any[]>([])
  const [scheduleData, setScheduleData] = useState([])
  const [updateSchedules, setUpdateSchedules] = useState(0)

  // eslint-disable-next-line
  const [selectedDate, setSelectedDate] = useState(moment())
  // eslint-disable-next-line
  const [selectedSchedule, setSelectedSchedule] = useState('default')
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(true)
  const [isDoNotSendMessagesActive, setIsDoNotSendMessagesActive] = useState(
    campaign_settings_ai?.isDoNotSendMessagesActive || false,
  )
  const [isAvoidMessagesInOtherCampaignsActive, setIsAvoidMessagesInOtherCampaignsActive] = useState(
    campaign_settings_ai?.isAvoidMessagesInOtherCampaignsActive || false,
  )
  const [scheduleToOpen, setScheduleToOpen] = useState('Default Schedule')
  const [runInScheduleModalOpen, setRunInScheduleModalOpen] = useState(false)
  const [refetcGetCampaignSchedule] = useLazyGetCampaignScheduleQuery()

  const onScheduleTypeChange = (e: any) => {
    setScheduleType(e.target.value)
    const newDayJs = campaign_settings_ai?.timingCampaign ? dayjs(campaign_settings_ai?.timingCampaign) : dayjs()
    const updated_campaign_settings_ai = {
      ...campaign_settings_ai,
      timingCampaign: newDayJs,
    }
    dispatch(SET_CAMPAIGN_SETTINGS_AI(updated_campaign_settings_ai))

    trackEvent('SCHEDULING_AND_CONFIGURATION', { timingCampaign: newDayJs })
  }

  const onDateChange = (date: any, dateString: string) => {
    trackEvent('SCHEDULING_AND_CONFIGURATION', { timingCampaign: date })

    setSelectedDate(date)
    const updated_campaign_settings_ai = {
      ...campaign_settings_ai,
      timingCampaign: date,
    }
    dispatch(SET_CAMPAIGN_SETTINGS_AI(updated_campaign_settings_ai))
  }

  // eslint-disable-next-line
  const onScheduleChange = (value: string) => {
    setSelectedSchedule(value)
    if (value === 'custom') {
      handleRunInBetweenHoursChange(value)
    }
  }

  // eslint-disable-next-line
  const onCheckboxChange = (e: any) => {
    setIsCheckboxChecked(e.target.checked)
  }

  const onDoNotSendMessagesSwitchChange = (checked: boolean) => {
    setIsDoNotSendMessagesActive(checked)
    const updated_campaign_settings_ai = {
      ...campaign_settings_ai,
      isDoNotSendMessagesActive: checked,
    }
    dispatch(SET_CAMPAIGN_SETTINGS_AI(updated_campaign_settings_ai))

    trackEvent('SCHEDULING_AND_CONFIGURATION', { isDoNotSendMessagesActive: checked })
  }

  const onAvoidMessagesInOtherCampaignsSwitchChange = (checked: boolean) => {
    setIsAvoidMessagesInOtherCampaignsActive(checked)
    const updated_campaign_settings_ai = {
      ...campaign_settings_ai,
      isAvoidMessagesInOtherCampaignsActive: checked,
    }
    dispatch(SET_CAMPAIGN_SETTINGS_AI(updated_campaign_settings_ai))

    trackEvent('SCHEDULING_AND_CONFIGURATION', { isAvoidMessagesInOtherCampaignsActive: checked })
  }

  // const handleRunInBetweenHoursChange = (value: any) => {
  //   if (value === 'custom') {
  //     setScheduleToOpen('custom')
  //   } else if (value === 'default') {
  //     setScheduleToOpen('custom')
  //   } else {
  //     setScheduleToOpen(value)
  //   }
  //   setRunInScheduleModalOpen(true)
  // }

  const handleRunInBetweenHoursChange = (value: any) => {
    if (value.schedule_label === 'Custom Date & Time') {
      setScheduleToOpen('custom')
    } else if (value.schedule_label === 'Default Schedule') {
      setScheduleToOpen('Default Schedule')
    } else {
      setScheduleToOpen(value.schedule_label)
    }
    setRunInScheduleModalOpen(true)
  }

  const selectSchedule = (data: any) => {
    const updated_campaign_settings_ai = {
      ...campaign_settings_ai,
      schedule_id: data.schedule_id,
    }
    setScheduleToOpen(data.schedule_label)
    dispatch(SET_CAMPAIGN_SETTINGS_AI(updated_campaign_settings_ai))
  }

  useEffect(() => {
    const getCampaignsSchedule = async () => {
      const { data } = await refetcGetCampaignSchedule()
      const schedules = data?.response
      const runInBetween = schedules.map(({ schedule_id, schedule_label }: any) => ({ schedule_id, schedule_label }))
      const selectedScheduleId = campaign_settings_ai?.schedule_id
      let schedule: any = {}
      if (selectedScheduleId) {
        schedule = schedules.find((item: any) => item.schedule_id === selectedScheduleId)
        setScheduleToOpen(schedule?.schedule_label)
      } else {
        schedule = schedules.find((item: any) => item.schedule_label === scheduleToOpen)
        setScheduleToOpen(schedule?.schedule_label)
      }
      const selectedLabel = schedule?.schedule_label

      const scheduleLabelToFilter = selectedLabel || 'Default Schedule'
      const filteredData = runInBetween.filter((item: any) => item.schedule_label === scheduleLabelToFilter)

      const updated_campaign_settings_ai = {
        ...campaign_settings_ai,
        schedule_id: filteredData[0]?.schedule_id,
      }
      dispatch(SET_CAMPAIGN_SETTINGS_AI(updated_campaign_settings_ai))

      setRunInBetweenHoursOptions([{ schedule_id: '1', schedule_label: 'Custom Date & Time' }, ...runInBetween])
      setScheduleData(schedules)
    }
    if (scheduleToOpen !== 'custom') {
      getCampaignsSchedule()
    }
  }, [scheduleToOpen, updateSchedules])

  const handleRunInScheduleModalUpdate = async () => {
    setUpdateSchedules((prev) => prev + 1)
    setRunInScheduleModalOpen(false)
    message.success('Schedule Updated Successfully!')
  }

  const handleRunInScheduleModalSave = async (schedule: any) => {
    setRunInBetweenHoursOptions((prevOptions) => [
      ...prevOptions,
      { schedule_id: schedule.schedule_id, schedule_label: schedule.schedule_label },
    ])
    setScheduleToOpen(schedule.schedule_label)
    await refetcGetCampaignSchedule()
    setRunInScheduleModalOpen(false)
    message.success('Schedule Saved Successfully!')
  }

  return (
    <div className={styles.container}>
      <div className={styles.scheduling}>
        <div className={styles.header}>
          <p>Scheduling</p>
          <Popover
            trigger='hover'
            placement='right'
            content={
              <div className={styles.popoverContent}>
                Scheduling configuration sets the timing and start of the campaign. <br />
                Select 'Run Campaign on Launch' if you want it to start immediately after launch. <br />
                If you prefer to start at a specific time, choose 'Timed Campaign.'
                <br />
                <br /> 'Running Between Hours' specifies when the campaign will be active and take actions; the default
                is Mon-Fri 09:00-22:00. To adjust, click on the … to set the desired time zones, weekdays, and hours for
                campaign activity.
              </div>
            }>
            <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} />
          </Popover>
        </div>
        <div className={styles.body}>
          <Radio.Group onChange={onScheduleTypeChange} value={scheduleType} className='scheduleAndConfiguration'>
            <Space direction='vertical'>
              <Radio value={'onLaunch'}>
                <div className={styles.radio}>
                  <p>Run Campaign on Launch</p>
                  <span>
                    The campaign will commence running immediately and dispatch messages to the audience that have been
                    selected.
                  </span>
                </div>
              </Radio>
              <Radio value={'timing'}>
                <div className={styles.radio}>
                  <p>Timing Campaign</p>
                  <span style={{ paddingBottom: '10px' }}>
                    Set scheduling launch date to reach your audience when they are most receptive.
                  </span>
                  <DatePicker
                    onChange={onDateChange}
                    placeholder='DD/MM/YYYY'
                    style={{ width: '200px' }}
                    value={campaign_settings_ai?.timingCampaign ? dayjs(campaign_settings_ai?.timingCampaign) : dayjs()}
                    //   value={selectedDate}
                    disabled={scheduleType !== 'timing'}
                  />
                </div>
              </Radio>
            </Space>
          </Radio.Group>
          <div className={styles.checkbox}>
            {/* <Checkbox onChange={onCheckboxChange} checked={isCheckboxChecked} /> */}
            <div className={styles.body} style={{ marginLeft: '34px' }}>
              <p>Run in Between Hours</p>
              <span style={{ paddingBottom: '10px' }}>
                Set scheduling launch date to reach your audience when they are most receptive.
              </span>
              {/* <Select
                defaultValue='default'
                style={{ width: 200 }}
                onChange={onScheduleChange}
                value={selectedSchedule}
                disabled={!isCheckboxChecked}>
                <Option value={'Custom'}>Custom Schedule</Option>

                <Option value='default'>Default Schedule</Option>
              </Select> */}
              <Select
                size='large'
                placeholder='Select your schedule'
                optionLabelProp='key'
                style={{ width: 200 }}
                disabled={!isCheckboxChecked}
                value={scheduleToOpen}
                defaultValue={scheduleToOpen}
                // defaultValue={edit_mode ? runInBetweenId : null}
              >
                {runInBetweenHoursOptions.map((option) => (
                  <Select.Option
                    key={option.schedule_label === 'Custom Date & Time' ? undefined : option.schedule_label}
                    value={option.schedule_label === 'Custom Date & Time' ? undefined : option.schedule_id}>
                    <div
                      className={styles.customSelect}
                      onClick={() => {
                        trackEvent('SCHEDULING_AND_CONFIGURATION', { option })

                        option.schedule_label === 'Custom Date & Time'
                          ? handleRunInBetweenHoursChange(option)
                          : selectSchedule(option)
                      }}>
                      <span>{option.schedule_label}</span>
                      {option.schedule_label !== 'Custom Date & Time' && (
                        <img
                          style={{ padding: '10px 0px' }}
                          onClick={() => handleRunInBetweenHoursChange(option)}
                          src={three_dots}
                          alt=''
                        />
                      )}
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.configuration}>
        <div className={styles.header}>
          <p>Configuration</p>
        </div>
        <div className={styles.body}>
          <div className={styles.row}>
            <Switch size='small' onChange={onDoNotSendMessagesSwitchChange} checked={isDoNotSendMessagesActive} />
            <p style={{ fontWeight: 600, margin: '0px 10px' }}>
              Do not send messages to people I have already reached out to
            </p>
            <Popover
              trigger='hover'
              placement='right'
              content={
                <div className={styles.popoverContent}>
                  Don't send messages to prospects with whom you already have an active chat
                </div>
              }>
              <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} />
            </Popover>
          </div>
          <div className={styles.row}>
            <Switch
              size='small'
              onChange={onAvoidMessagesInOtherCampaignsSwitchChange}
              checked={isAvoidMessagesInOtherCampaignsActive}
            />
            <p style={{ fontWeight: 600, margin: '0px 10px' }}>
              Avoid sending messages to leads participating in other campaigns
            </p>
            <Popover
              trigger='hover'
              placement='right'
              content={
                <div className={styles.popoverContent}>
                  Don't send messages to leads that are part of any other campaign{' '}
                </div>
              }>
              <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} />
            </Popover>{' '}
          </div>
        </div>
      </div>
      <CustomCollapseFooter id='5' onBack={onBack} onGenerate={true} />
      {runInScheduleModalOpen && (
        <RunInScheduleModal
          open={runInScheduleModalOpen}
          onClose={() => {
            setRunInScheduleModalOpen(false)
          }}
          onCancel={() => {
            setRunInScheduleModalOpen(false)
            if (scheduleToOpen === 'custom') {
              setScheduleToOpen('Default Schedule')
            }
          }}
          onSave={handleRunInScheduleModalSave}
          onUpdate={handleRunInScheduleModalUpdate}
          data={scheduleData}
          scheduleToOpen={scheduleToOpen}
        />
      )}
    </div>
  )
}

export default SchedulingAndConfiguration
