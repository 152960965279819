import { Button, Col, Form, Input, message, Modal, Row, Skeleton, Upload } from 'antd'
import styles from './Videos.module.scss'
import plusOutline from 'common/assets/svg/plus_outlined.svg'
import VideoCard from './VideoCard'
import { useAppDispatch, useAppSelector } from 'state'
import { useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { LocalStorageKeys } from 'common/constants/localstorage.constants'
import axios from 'axios'
import { SET_LEARNING_OPEN, SET_VIDEO_SEE_MORE_OPEN } from '../../state/slice/learningSlice'
interface VideosProps {
  loading: any
  videos: any
}
export default function Videos({ loading, videos }: VideosProps) {
  const { isAdmin } = useAppSelector((state) => state.learning)
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const videosSorted = Array.isArray(videos) ? [...videos].sort((a: any, b: any) => a?.priority - b?.priority) : []
  const dispatch = useAppDispatch()
  const openUploadModal = () => {
    setUploadModalOpen(true)
  }
  const closeUploadModal = () => {
    setUploadModalOpen(false)
  }
  const onSeeMore = () => {
    dispatch(SET_LEARNING_OPEN(false))
    dispatch(SET_VIDEO_SEE_MORE_OPEN(true))
  }
  const uploadArticle = async (values: any) => {
    const cover = values?.cover?.file
    const src = values?.src?.file
    const coverName = cover?.name
    const videoName = src?.name
    const formData = new FormData()
    formData.append('title', values.title)
    formData.append('description', values.description)
    formData.append('cover', new File([new Blob([cover as any])], coverName as string))
    formData.append('src', new File([new Blob([src as any])], videoName as string))

    const headers = {
      'Content-Type': 'multipart/form-data',
      authorization: `Bearer ${localStorage
        .getItem(LocalStorageKeys.ACCESS_TOKEN)
        ?.substring(1, String(localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)).length - 1)} `,
      environment: `${process.env.REACT_APP_ENVIRONMENT}`,
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/learning_center/uploadVideo`, formData, {
        headers: headers,
      })

      message.success('Video uploaded successfully')
    } catch (error) {
      message.error('Error uploading Video')
      console.error('Error uploading Video:', error)
    }
  }
  const handleSeeMore = () => {
    onSeeMore()
  }

  return (
    <>
      <Modal
        width={800}
        title='Upload Article'
        open={uploadModalOpen}
        onCancel={closeUploadModal}
        footer={null}
        zIndex={1002}>
        <Form
          name='basic'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={uploadArticle}
          autoComplete='off'>
          <Form.Item label='Title' name='title' rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label='Video Source' name='src' rules={[{ required: true }]}>
            <Upload
              beforeUpload={() => {
                return false
              }}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item label='Video Cover' name='cover' rules={[{ required: true }]}>
            <Upload
              beforeUpload={() => {
                return false
              }}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: 20 }}>
        <div style={{ flex: 0.05, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            <span className={styles.title}>Videos</span>
            {isAdmin && (
              <span>
                <img src={plusOutline} alt='Plus' style={{ cursor: 'pointer' }} onClick={openUploadModal} />
              </span>
            )}
          </div>

          <span className={styles.seeMore} onClick={handleSeeMore}>
            See More Videos
          </span>
        </div>
        <div
          style={{
            flex: 0.95,
            width: '100%',
            maxHeight: '100%',
            overflow: 'auto',
            overflowX: 'hidden',
            scrollbarColor: 'black',
          }}>
          {loading ? (
            <Row gutter={[12, 12]} style={{ height: 350, justifyContent: 'center' }}>
              <Col span={12}>
                <Skeleton.Button active className='customVideoSkelton' />
              </Col>
              <Col span={12}>
                <Skeleton.Button active className='customVideoSkelton' />
              </Col>
              <Col span={12}>
                <Skeleton.Button active className='customVideoSkelton' />
              </Col>
              <Col span={12}>
                <Skeleton.Button active className='customVideoSkelton' />
              </Col>
            </Row>
          ) : (
            <Row gutter={[12, 12]} style={{ height: 350 }}>
              {videosSorted?.slice(0, 4).map((video: any, index: number) => (
                <Col span={12} key={index}>
                  <VideoCard video={video} key={index} />
                </Col>
              ))}
            </Row>
          )}
        </div>
      </div>
    </>
  )
}
