import { Button } from 'antd'
import styles from './VerifyEmail.module.scss'
import email from 'common/assets/svg/EmailUnverified.svg'
import emailVerified from 'common/assets/svg/EmailVerified.svg'
import { useEffect, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthService } from 'common/services/authServices'
import { useLazyLogAffiliateQuery, useLazyResendEmailQuery, useLazyUpdateUserQuery } from '../../state/api/SignUpApi'
import { useAppSelector } from 'state'
import { LocalStorageKeys } from 'common/constants/localstorage.constants'
// import { post } from 'common/api/axios'
// import { logEvent } from 'eventLogger'
import { trackEvent } from 'eventConfig'
export default function VerifyEmail() {
  const navigate = useNavigate()
  const [timer, setTimer] = useState(60)
  const [isDisabled, setIsDisabled] = useState(true)
  const [, setStep] = useLocalStorage('step', 1)
  const [error, setError] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const [isVerified, setIsVerified] = useLocalStorage('ev', confirmed && !error)
  const [resendEmailQuery] = useLazyResendEmailQuery()
  const [user]: any = useLocalStorage('user', null)
  const { affiliateData } = useAppSelector((state) => state.signup)
  const [logAffiliate] = useLazyLogAffiliateQuery()
  const [, setUserTokeb] = useLocalStorage(LocalStorageKeys.ACCESS_TOKEN, null)
  const [, setUserDetails] = useLocalStorage('user', null)
  const { emailToken, isMobile } = useAppSelector((slice) => slice.General)
  const [updateUser] = useLazyUpdateUserQuery()
  const affToken = searchParams.get('affiliate')
  const outside_onboarding = user?.outside_onboarding

  const handleContinue = async () => {
    // logEvent(
    //   'Outside Signup: Second step (Email Verified)',
    //   'frontend',
    //   {
    //     event_purpose: 'Track when a user completed the email verification',
    //     trigger: 'User clicked on continue',
    //     related_screen: 'Onboarding - Email verification',
    //     key_data_points: [],
    //     expected_outcome: 'Check if user completed this step',
    //     notes: 'This event indicates that the user completed the step and not just started.',
    //   },
    //   {
    //     affiliateData: affiliateData,
    //     user: user,
    //   },
    // )

    trackEvent('SIGNUP_EMAIL_VERIFIED', { affiliateData: affiliateData, user: user?.email, details: user })

    await updateUser({
      updates: {
        outside_onboarding: { ...outside_onboarding, step: 2 },
      },
      id: user.id,
    })

    setStep(2)
  }
  const resendEmail = async () => {
    setTimer(60)
    await resendEmailQuery({ email: user.email })
    setIsDisabled(true)
  }
  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1)
      }, 1000)

      return () => clearInterval(countdown) // Cleanup interval on unmount
    } else {
      setIsDisabled(false) // Enable the resend button when the timer hits 0
    }
  }, [timer])

  useEffect(() => {
    if (emailToken) {
      const t = emailToken
      AuthService.checkEmailToken(t)
        .then((response: any) => {
          if (response.error) {
            setError(true)
          } else {
            setUserDetails(response.user)
            setUserTokeb(response.accessToken)

            const handle = async () => {
              const settings = response?.settings || {}
              const creator_user_id = response?.settings?.creator_user_id || response?.creator_user_id || null

              await logAffiliate({
                convrt_user_created: creator_user_id,
                name: affiliateData.name,
                token: settings?.id,
                is_skip_outside_onboarding: settings?.skip_outside_onboarding,
              })
              // window.location.reload()
            }
            handle()
              .then(() => {
                setConfirmed(true)
                setIsVerified(true)
              })
              .catch((err) => {
                console.log(err)
                setError(true)
              })
          }
        })
        .catch((err: any) => {
          console.log(err)
          setError(true)
        })
      return
    }
    if (user.email_confirmed) {
      setIsVerified(true)
      setConfirmed(true)
      return
    }
  }, [location.search, emailToken, affToken, user])

  useEffect(() => {
    if (isVerified) {
      const currentUrl = new URL(window.location.href)
      currentUrl.searchParams.set('signedup-success', 'true')
      navigate(`${currentUrl.pathname}${currentUrl.search}`, { replace: true })
    }
  }, [isVerified])

  return (
    <>
      {isMobile ? (
        <>
          <div className={styles.cardMobile}>
            <div className={styles.icon}>
              <img src={isVerified ? emailVerified : email} alt='' />
            </div>

            {isVerified ? (
              <>
                <div className={styles.title}>Email Verified!</div>
                <div className={styles.great_frame}>
                  <span className={styles.great_text}>
                    Great! Now, let's get to know you better to personalize your Convrt experience.
                  </span>
                </div>
                <Button type='primary' style={{ width: '100%', height: '40px' }} onClick={handleContinue}>
                  Continue
                </Button>
              </>
            ) : (
              <>
                <div className={styles.title}>Verify your email</div>
                <div className={styles.we}>
                  <span className={styles.we_text}>We sent you a link to your email to verify your registration.</span>
                </div>
                <div className={styles.we}>
                  <span className={styles.we_text}>
                    Please check your spam folder if you don't see it in your inbox.
                  </span>
                </div>
                <div className={styles.actions}>
                  <div className={styles.resend}>
                    <div className={styles.buttonMobile}>
                      <Button type='primary' size='large' disabled={isDisabled} onClick={resendEmail}>
                        Resend verification email
                      </Button>
                    </div>

                    <div className={styles.timer}>
                      <span className={styles.timer_text}>
                        {' '}
                        {`${Math.floor(timer / 60)
                          .toString()
                          .padStart(2, '0')}:${(timer % 60).toString().padStart(2, '0')}`}
                      </span>
                    </div>
                    <div className={styles.change}>
                      <Button type='ghost' className={styles.text} onClick={() => setStep(0)}>
                        Change email address
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <div className={styles.card}>
          <div className={styles.icon}>
            <img src={isVerified ? emailVerified : email} alt='' />
          </div>

          {isVerified ? (
            <>
              <div className={styles.title}>Email Verified!</div>
              <div className={styles.great_frame}>
                <span className={styles.great_text}>
                  Great! Now, let's get to know you better to personalize your Convrt experience.
                </span>
              </div>
              <Button type='primary' style={{ width: '100%', height: '40px' }} onClick={handleContinue}>
                Continue
              </Button>
            </>
          ) : (
            <>
              <div className={styles.title}>Verify your email</div>
              <div className={styles.we}>
                <span className={styles.we_text}>We sent you a link to your email to verify your registration.</span>
              </div>
              <div className={styles.we}>
                <span className={styles.we_text}>Please check your spam folder if you don't see it in your inbox.</span>
              </div>
              <div className={styles.actions}>
                <div className={styles.resend}>
                  <div className={styles.button}>
                    <Button type='primary' disabled={isDisabled} onClick={resendEmail}>
                      Resend verification email
                    </Button>
                  </div>

                  <div className={styles.timer}>
                    <span className={styles.timer_text}>
                      {' '}
                      {`${Math.floor(timer / 60)
                        .toString()
                        .padStart(2, '0')}:${(timer % 60).toString().padStart(2, '0')}`}
                    </span>
                  </div>
                  <div className={styles.change}>
                    <Button type='ghost' className={styles.text} onClick={() => setStep(0)}>
                      Change email address
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}
