import React, { useState } from 'react'
import { Card, Modal, Select, Spin, message } from 'antd'
import styles from './ExtraFeaturesModal.module.scss'
import tick_extra_features from 'common/assets/svg/tick_extra_features.svg'
import x_extra_features from 'common/assets/svg/x_extra_features.svg'
import { startCase } from 'lodash'
import { useLazyGenerateStripeLinkQuery } from './API/UpgradeApi'
import { LoadingOutlined } from '@ant-design/icons'
import { getLeads, getSendRate, numberToRoman } from './upgradeUtils'
import { useAppSelector } from 'state'

interface ExtraFeaturesModalProps {
  open: boolean
  onClose: () => void
  plans: any // array of plans
  onUpgrade?: () => void
  selectedOptions: any
  onOptionChange: (planId: string, value: string) => void
  billingCycle: any
  subscription: any
}

const ExtraFeaturesModal: React.FC<ExtraFeaturesModalProps> = ({
  open,
  onClose,
  plans,
  onUpgrade,
  selectedOptions,
  onOptionChange,
  billingCycle,
  subscription,
}) => {
  const [generateStripeLink] = useLazyGenerateStripeLinkQuery()
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({})
  const filteredPlans = plans.filter((plan: any) => !plan.customPrice)
  const modalWidth = Math.max(850, 300 + filteredPlans.length * 200)
  const columnCount = Math.min(filteredPlans.length + 1)
  const { isMobile } = useAppSelector((state) => state.General)

  const handleSelectChange = (planId: string, value: string) => {
    // const plan = plans?.find((p: any) => p.id === planId)
    // const selected = plan.pricingOptions[billingCycle].find(
    //   (option: any) => `${option.magic}-${option.points}` === value,
    // )
    onOptionChange(planId, value)
  }

  const handleUpgrade = async (plan: any) => {
    const selectedOption = selectedOptions[plan.id]

    const isActivePlan =
      plan?.planName === subscription?.plan_name &&
      +subscription?.tier === +selectedOption &&
      ((billingCycle === 'monthly' && subscription?.interval === 'month' && subscription?.interval_count === 1) ||
        (billingCycle === 'yearly' && subscription?.interval === 'year' && subscription?.interval_count === 1))

    if (loading[plan.id] || isActivePlan) return // Check loading state for this plan
    if (plan.customPrice) {
      window.open('https://calendly.com/roy-convrt/demo-convrt', '_blank')
    } else {
      setLoading((prev) => ({ ...prev, [plan.id]: true })) // Set loading for this specific plan
      const selected = plan.pricingOptions[billingCycle][selectedOption]
      const price_id = selected.price_id
      const { data } = await generateStripeLink({ price_id })
      if (data?.url) {
        window.open(data.url, '_self')
      } else if (data?.not_allowed) {
        message.error('Please contact our Sales team for assistance with making this change.')
      } else if (data?.same_price_id) {
        message.error(
          'You have already switched to this plan. The change is scheduled and will take effect at the end of the current billing period.',
        )
      } else {
        message.error('Something went wrong redirecting to Stripe. Please try again or contact support.')
      }
      setLoading((prev) => ({ ...prev, [plan.id]: false }))
    }
  }

  const propertyMapping: any = {
    exportCSV: 'Export CSV',
    csvImport: 'CSV Import',
    abCTest: 'ABC Test',
    aiMagicLeads: 'AI Magic Leads',
    aiAutomaticResponder: 'AI Automatic Responder',
    aiMessages: 'AI Messages',
    crmIntegration: 'CRM Integration',
    oneOnOneOnboarding: 'One-on-One Onboarding',
  }

  return (
    <Modal
      className='ExtraFeaturesModal'
      width={modalWidth}
      open={open}
      onCancel={(e: any) => {
        e.stopPropagation()
        onClose()
      }}
      footer={null}
      centered
      closable={false}>
      <div className={styles.modalContent}>
        {/* Sticky Header for Plan Names */}
        <div
          className={`${styles.planHeader} ${styles.sticky}`}
          style={{ gridTemplateColumns: `repeat(${columnCount}, 1fr)` }}>
          <div className={styles.planName}></div>
          {filteredPlans.map((plan: any, index: number) => {
            const selectedOption = selectedOptions[plan.id]

            const isActivePlan =
              plan?.planName === subscription?.plan_name &&
              +subscription?.tier === +selectedOption &&
              ((billingCycle === 'monthly' &&
                subscription?.interval === 'month' &&
                subscription?.interval_count === 1) ||
                (billingCycle === 'yearly' && subscription?.interval === 'year' && subscription?.interval_count === 1))
            return (
              <Card key={index} className={`${styles.planName} CardNoPadding`}>
                <div className={styles.flexDiv}>
                  <h1 style={isMobile ? { fontSize: '16px' } : {}}>{plan.planName}</h1>
                  {/* Pricing or upgrade */}
                  <Select
                    style={{ maxWidth: '100%' }}
                    // value={`${selectedOptions[plan.id].magic}-${selectedOptions[plan.id].points}`}
                    value={selectedOptions[plan.id]}
                    onChange={(value) => handleSelectChange(plan.id, value)}>
                    {Object.entries(plan.pricingOptions[billingCycle]).map(([key, option]: any) => (
                      <Select.Option key={key} value={key} label='1'>
                        {isMobile ? (
                          <span>{numberToRoman(+key)}</span>
                        ) : (
                          <span className={styles.optionText}>
                            <span style={{ fontWeight: 600 }}>{getSendRate(key, plan)}</span>{' '}
                            <span style={{ fontWeight: 400 }}>Sending |</span>{' '}
                            <span style={{ fontWeight: 600 }}>{getLeads(option)}</span>{' '}
                            <span style={{ fontWeight: 400 }}>Collecting</span>{' '}
                          </span>
                        )}
                      </Select.Option>
                    ))}
                  </Select>
                  {!isMobile && (
                    <div className={`${styles.upgradeButton} ${isActivePlan ? styles.activePlan : ''}`}>
                      <span
                        onClick={() => {
                          handleUpgrade(plan)
                        }}>
                        {loading[plan.id] && (
                          <Spin indicator={<LoadingOutlined spin style={{ color: 'white', marginRight: '10px' }} />} />
                        )}

                        <span>{isActivePlan ? 'Active Plan' : plan.customPrice ? 'Contact Us' : 'Update Plan'}</span>
                      </span>
                    </div>
                  )}
                </div>
              </Card>
            )
          })}
        </div>
        {/* {console.log(filteredPlans[0]?.extraFeatures)} */}
        {/* Rendering extra features for each plan */}
        {filteredPlans[0]?.extraFeatures &&
          filteredPlans[0].extraFeatures.map((featureSection: any, idx: number) => (
            <div key={idx} className={styles.featureSection}>
              {/* Sticky section header */}
              <div className={`${styles.sectionHeader} ${styles.sticky}`} style={isMobile ? { top: 99 } : {}}>
                {featureSection.name}
              </div>

              {/* Feature rows */}
              {Object.keys(featureSection.properties).map((property, propertyIndex) => (
                <div
                  key={propertyIndex}
                  className={styles.featureRow}
                  style={{ gridTemplateColumns: `repeat(${columnCount}, 1fr)` }}>
                  <Card className={`${styles.featureName} CardNoPadding`} style={isMobile ? { fontSize: '11px' } : {}}>
                    {propertyMapping[property]
                      ? propertyMapping[property]
                      : startCase(property.replace(/([A-Z])/g, ' $1'))}
                  </Card>
                  {filteredPlans.map((plan: any, index: number) => {
                    const value = plan?.extraFeatures?.[idx]?.properties?.[property]?.[selectedOptions?.[plan?.id]]
                    return (
                      <Card key={index} className={`${styles.featureValue} CardNoPadding`}>
                        {typeof value === 'boolean' ? (
                          <img src={value ? tick_extra_features : x_extra_features} alt='' />
                        ) : (
                          <span>{value?.toLocaleString()}</span>
                        )}
                      </Card>
                    )
                  })}
                </div>
              ))}
            </div>
          ))}
      </div>
    </Modal>
  )
}

export default ExtraFeaturesModal
