import React, { useEffect, useState } from 'react'
import { Divider, Radio, Select, Space } from 'antd'
import Icon from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_NODES_DATA, SET_TRIGGER, SET_USER_UPDATED_SEQUENCE } from '../../state/outreachAICampaignSlice'
import { SET_BUILDER_DATA, SET_BUILDER_SETUP_DATA } from '../../Builder/Slice/CampaignBuilderSlice'
import { capitalize } from 'lodash'
import { trackEvent } from 'eventConfig'
// import styles from './CustomSelect.module.scss'

const { Option } = Select

interface CustomSelectProps {
  id: any
  type: 'time' | 'content' | 'count'
  defaultValue: { amount: number; unit?: string }
}

const capitalizeUnit = (unit: string, amount: number) => {
  if (unit) {
    const newUnit = capitalize(unit)
    if (amount === 1) {
      return `${amount} ${newUnit}`
    } else {
      if (unit === 'story') return 'Stories'
      return `${amount} ${newUnit + 's'}`
    }
  }
}

const normalizeUnit = (unit: string) => {
  // Normalize the unit to lowercase and singular form
  let normalizedUnit = unit.toLowerCase()
  if (normalizedUnit === 'stories') {
    return (normalizedUnit = 'story')
  }
  if (normalizedUnit.endsWith('s')) {
    normalizedUnit = normalizedUnit.slice(0, -1)
  }
  return normalizedUnit
}

const unitAmountNodeTypes = [
  'is_message_replied',
  'is_wait',
  'like_post',
  'lead_view_dm',
  'lead_respond',
  'lead_accepted',
  'lead_accepted_connect',
  'lead_accepted_follow',
]

const CustomSelect: React.FC<CustomSelectProps> = ({ id, type, defaultValue }) => {
  const dispatch = useAppDispatch()
  // const [selection, setSelection] = useState<any>(
  //   `${defaultValue.amount} ${defaultValue.unit?.charAt(0).toUpperCase() + (defaultValue.unit || '')?.slice(1)}${
  //     defaultValue?.amount !== 1 ? 's' : ''
  //   }`,
  // )
  const [selection, setSelection] = useState<any>(capitalizeUnit(defaultValue?.unit || '', defaultValue?.amount))
  const [unit, setUnit] = useState<string>(defaultValue.unit || '')
  const [amount, setAmount] = useState<any>(defaultValue.amount)
  const { nodes_data, trigger } = useAppSelector((state) => state.outreachAICampaign)
  const { builder_data, use_custom_flow, updated_builder_data } = useAppSelector((state) => state.CampaignBuilder)
  const path = window.location.href

  const handleSelectionChange = (value: any) => {
    if (type === 'time') {
      trackEvent('CUSTOM_SELECT_TIME', { value })
    }

    const newAmount = Number(value.split(' ')[0])
    let newUnit = value.split(' ')[1].toLowerCase()
    newUnit = normalizeUnit(newUnit)

    setAmount(newAmount)
    setUnit(newUnit)
    setSelection(capitalizeUnit(newUnit, newAmount))
    const existingDataIndex = nodes_data.findIndex((node: any) => node.step_id === id)

    dispatch(SET_USER_UPDATED_SEQUENCE(true))

    dispatch(
      SET_NODES_DATA({
        step_id: id,
        data: { unit: newUnit, amount: newAmount },
        ...(nodes_data[existingDataIndex]?.extraData && { extraData: { defaultAmount: newAmount } }),
      }),
    )
    dispatch(SET_TRIGGER(trigger + 1))

    if (updated_builder_data?.nodes?.length > 0) {
      dispatch(
        SET_BUILDER_SETUP_DATA({
          id: id,
          data: { unit: newUnit, amount: newAmount },
        }),
      )
    }

    if (builder_data?.nodes?.length > 0 && !path.includes('campaign-builder')) {
      dispatch(
        SET_BUILDER_SETUP_DATA({
          id: id,
          data: { unit: newUnit, amount: newAmount },
        }),
      )

      const updatedNodes = builder_data?.nodes?.map((node: any) => {
        if (node.id === id) {
          return { ...node, data: { ...node.data, unit: newUnit, amount: newAmount } }
        } else {
          return node
        }
      })
      dispatch(SET_BUILDER_DATA({ nodes: updatedNodes, edges: builder_data.edges }))
    }
  }

  useEffect(() => {
    const existingDataIndex = nodes_data.findIndex((node: any) => node.step_id === id)

    if (nodes_data[existingDataIndex]?.data?.unit || nodes_data[existingDataIndex]?.data?.amount) {
      if (
        existingDataIndex === -1 ||
        nodes_data[existingDataIndex]?.data?.unit !== unit ||
        nodes_data[existingDataIndex]?.data?.amount !== amount
      ) {
        const normalizedUnit = normalizeUnit(unit)
        dispatch(
          SET_NODES_DATA({
            step_id: id,
            data: { unit: normalizedUnit, amount: amount },
            ...(nodes_data[existingDataIndex]?.extraData && { extraData: { defaultAmount: amount } }),
          }),
        )
      }
    } else if (
      !nodes_data?.[existingDataIndex]?.data?.unit &&
      unitAmountNodeTypes?.includes(nodes_data?.[existingDataIndex]?.type)
    ) {
      const normalizedUnit = normalizeUnit(unit)
      dispatch(
        SET_NODES_DATA({
          step_id: id,
          data: { unit: normalizedUnit, amount: amount },
          ...(nodes_data?.[existingDataIndex]?.extraData && { extraData: { defaultAmount: amount } }),
        }),
      )
    }
  }, [])

  useEffect(() => {
    if (builder_data?.nodes?.length > 0 && use_custom_flow) {
      const currentNode = builder_data?.nodes?.filter((node: any) => node.id === id)?.[0]
      if (currentNode?.data?.amount) {
        setAmount(currentNode?.data?.amount || '')
      }
      if (currentNode?.data?.unit) {
        setUnit(currentNode?.data?.unit || '')
      }
    }
  }, [])

  const generateOptions = () => {
    if (type === 'time') {
      return [0, 1, 2, 3, 4, 5].map((count) => (
        <Option key={count} value={`${count} ${unit}`}>
          {`${count} ${capitalize(unit)}${count > 1 ? 's' : ''}`}
        </Option>
      ))
    } else if (type === 'content') {
      return [0, 1, 2, 3, 4, 5].map((count) => {
        let pluralForm = unit
        if (count > 1) {
          if (unit === 'story') {
            pluralForm = 'stories' // Correctly handle 'story' -> 'stories'
          } else if (unit !== 'story') {
            pluralForm += 's' // Add 's' for other selections
          }
        }

        return (
          <Option key={count} value={`${count} ${pluralForm}`}>
            {`${count} ${capitalize(pluralForm)}`}
          </Option>
        )
      })
    } else if (type === 'count') {
      return [1, 2, 3, 4, 5].map((count) => {
        return (
          <Option key={count} value={`${count}`}>
            {`${count} `}
          </Option>
        )
      })
    }
  }

  const ArrowSVG = () => (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.86128 6.19526C5.12163 5.93491 5.54374 5.93491 5.80409 6.19526L7.99935 8.39052L10.1946 6.19526C10.455 5.93491 10.8771 5.93491 11.1374 6.19526C11.3978 6.45561 11.3978 6.87772 11.1374 7.13807L8.47075 9.80474C8.2104 10.0651 7.78829 10.0651 7.52794 9.80474L4.86128 7.13807C4.60093 6.87772 4.60093 6.45561 4.86128 6.19526ZM7.76365 9.09763L7.99935 9.33333L8.23505 9.09763C8.10488 9.22781 7.89382 9.22781 7.76365 9.09763Z'
        fill='#97A0AF'
      />
    </svg>
  )

  const ArrowIcon = (props: Partial<any>) => <Icon component={ArrowSVG} {...props} />
  return (
    // <div className={styles.container}>
    <Select
      suffixIcon={<ArrowIcon className='ant-select-suffix' />}
      className='nopan nodrag customNodeSelect'
      defaultValue={selection}
      disabled={nodes_data[nodes_data.findIndex((node: any) => node.step_id === id)]?.data?.disabled}
      // disabled={Object.keys(stats_data)?.length > 0}
      // defaultValue={capitalizeUnit(unit, amount)}
      value={capitalizeUnit(unit, amount)}
      style={{ width: type === 'count' ? 50 : 85, marginLeft: 5 }}
      size='small'
      dropdownStyle={{ minWidth: type === 'time' ? '230px' : type === 'content' ? '242px' : '100px' }}
      onChange={handleSelectionChange}
      dropdownRender={(menu) => (
        <>
          {type === 'time' && (
            <Space style={{ padding: '10px' }}>
              <Radio.Group
                value={unit} // Set the value to the current unit selection
                defaultValue={unit}
                buttonStyle='solid'
                onChange={(e) => setUnit(e.target.value)}>
                <Radio.Button value='hour'>Hours</Radio.Button>
                <Radio.Button value='day'>Days</Radio.Button>
                <Radio.Button value='week'>Weeks</Radio.Button>
              </Radio.Group>
            </Space>
          )}
          {/* {type === 'content' && (
            <Space style={{ padding: '10px' }}>
              <Radio.Group
                value={unit}
                defaultValue={unit}
                buttonStyle='solid'
                onChange={(e) => setUnit(e.target.value)}>
                <Radio.Button value='post'>Posts</Radio.Button>
                <Radio.Button value='photo'>Photos</Radio.Button>
                <Radio.Button value='story'>Stories</Radio.Button>
              </Radio.Group>
            </Space>
          )} */}
          {type !== 'count' && type !== 'content' && <Divider style={{ margin: '8px 0' }} />}
          {menu}
        </>
      )}>
      {generateOptions()}
    </Select>
    // </div>
  )
}

export default CustomSelect
