import React, { useState } from 'react'
import { Popover, Button } from 'antd'
import styles from './CustomPopover.module.scss'

interface CustomPopoverProps {
  image?: string
  title: string
  body: string | React.ReactNode
  children: React.ReactNode // Define children explicitly
}

const CustomPopover: React.FC<CustomPopoverProps> = ({ image, title, body, children }) => {
  const [popoverVisible, setPopoverVisible] = useState(false)

  const handlePopoverVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible)
  }

  const content = (
    <div className={styles.popover}>
      <div className={styles.content}>
        {image && <img src={image} alt='Hover' className={styles.image} />}
        <div className={styles.textContainer}>
          <span className={styles.title}>{title}</span>
          <span className={styles.body}>{body}</span>
        </div>
        <div className={styles.footer}>
          <Button
            type='primary'
            className={styles.button}
            onClick={(e) => {
              setPopoverVisible(false)
              e.preventDefault()
              e.stopPropagation()
            }}>
            Got it
          </Button>
        </div>
      </div>
    </div>
  )

  return (
    <Popover
      trigger='click'
      placement='right'
      onVisibleChange={handlePopoverVisibleChange}
      visible={popoverVisible}
      content={content}>
      {children}
    </Popover>
  )
}

export default CustomPopover
