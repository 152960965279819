import React, { useEffect, useState } from 'react'
import { Button, Divider, Input, message, Popover, Select, Space, Spin } from 'antd'
import IconPicker from './IconPicker'
import { useLazyDeleteMeetingBookedTagsQuery, useLazyInsertMeetingBookedTagsQuery } from '../state/api/Chat-API'
import icons from 'common/utils/iconUtil'
import closeIcon from 'common/assets/svg/closeVideo.svg'
import MeetingBookOption from './MeetingBookOption'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_DIALOGS_METADATA_BULK } from '../state/chatSlice'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
import { useLocalStorage } from 'usehooks-ts'
interface CustomMeetingBookProps {
  defaultValue?: string
  value?: string
  onChange: any
  customMeetingBooked?: any
  getMeetingBooked?: any
  selectedChatRecipient?: any
}

const CustomMeetingBook: React.FC<CustomMeetingBookProps> = ({
  defaultValue,
  value,
  onChange,
  customMeetingBooked,
  getMeetingBooked,
  selectedChatRecipient,
}) => {
  const [options, setOptions] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [showIconPicker, setShowIconPicker] = useState(false)
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [insertMeetingBook] = useLazyInsertMeetingBookedTagsQuery()
  const [deleteMeetingBook] = useLazyDeleteMeetingBookedTagsQuery()
  const [editVisible, setEditVisible] = useState(false)
  const [newName, setNewName] = useState('')
  const [, setNewIcon] = useState('')
  const [inputStatus, setInputStatus] = useState<'error' | 'warning' | ''>('')
  const [getUserDetails] = useLazyGetUserDetailsQuery()
  const [, setUser] = useLocalStorage('user', null)
  const dispatch = useAppDispatch()
  const { dialogs_metadata } = useAppSelector((state) => state.chat)

  const handleDeleteTag = async (tag: any) => {
    setLoading(true)
    setDropdownVisible(false)
    await deleteMeetingBook({ tagId: tag.id, tagName: tag.name })
    const modified = JSON.parse(JSON.stringify(dialogs_metadata))
    for (const key in modified) {
      if (modified[key]?.meeting_book === tag.name) {
        modified[key].meeting_book = null
      }
    }
    dispatch(SET_DIALOGS_METADATA_BULK(modified))
    await getMeetingBooked()

    const { data } = await getUserDetails()
    if (data) {
      setUser(data)
    }

    setLoading(false)
    setEditVisible(false)
    onChange(null)
  }

  useEffect(() => {
    // Populate options based on `customMeetingBooked`.
    if (customMeetingBooked?.response) {
      const newOptions = customMeetingBooked.response.map((tag: any) => ({
        label: (
          <MeetingBookOption
            setDropdownVisible={setDropdownVisible}
            onChange={onChange}
            key={tag.id}
            getMeetingBooked={getMeetingBooked}
            tag={tag}
            icons={icons}
            handleDeleteTag={handleDeleteTag}
            setEditVisible={setEditVisible}
          />
        ),
        value: tag.name,
      }))
      setOptions(newOptions)
    }
  }, [customMeetingBooked])

  const handleChange = (selectedValue: string) => {
    onChange(selectedValue)
  }
  const onAdd = async (iconName: any) => {
    setLoading(true)
    setShowIconPicker(false)
    await insertMeetingBook({ tagName: newName, icon: iconName })
    await getMeetingBooked()
    onChange(newName)
    setLoading(false)
  }

  return (
    <Select
      rootClassName='meetingBookSelect'
      style={{ width: 250 }}
      placeholder='Select or add new'
      open={dropdownVisible}
      onChange={handleChange}
      defaultValue={defaultValue}
      value={value}
      onDropdownVisibleChange={(visible) => {
        if (showIconPicker) {
          setDropdownVisible(true)
          return
        }
        if (editVisible) {
          setDropdownVisible(true)
          return
        }
        setDropdownVisible(visible)
        if (!visible) {
          setNewName('')
          setNewIcon('')
        }
      }}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '0px' }} />

          <>
            {showIconPicker && (
              <Popover
                rootClassName='meetingBookOverlay'
                content={
                  <div
                    style={{ padding: 15 }}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}>
                    <div
                      style={{
                        marginBottom: '20px',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      <span
                        style={{
                          fontSize: '16px',
                        }}>
                        Pick An Icon
                      </span>
                      <img
                        src={closeIcon}
                        alt='Close'
                        style={{ width: 15, height: 15, cursor: 'pointer' }}
                        onClick={() => {
                          setShowIconPicker(false)
                          setNewIcon('')
                        }}
                      />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'auto',
                        overflowX: 'hidden',
                        marginTop: '15px',
                        maxHeight: '300px',
                      }}>
                      <IconPicker
                        setCurrentIcon={setNewIcon}
                        onAdd={(iconName: any) => {
                          onAdd(iconName)
                          setNewName('')
                          setNewIcon('')
                        }}
                      />
                    </div>
                  </div>
                }
                open={showIconPicker}
                placement='left'
                overlayStyle={{ margin: 10, padding: 10, zIndex: 1000, background: 'white' }}
                overlayInnerStyle={{ width: 300, height: 120, backgroundColor: 'white' }}></Popover>
            )}
            <Space style={{ display: 'flex', padding: '10px 5px' }}>
              {/* {newIcon ? (
                  <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                    <img src={newIcon} alt='New Icon' style={{ width: 15, height: 15 }} />
                  </div>
                ) : (
                  <div style={{ width: '15px', height: '15px', backgroundColor: 'white' }}></div>
                )} */}
              <Input
                placeholder='Add New Tag'
                value={newName}
                style={{ flex: 0.8 }}
                onChange={(e) => setNewName(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
                status={inputStatus}
              />
              <Button
                style={{ flex: 0.2 }}
                type='text'
                icon={loading ? <Spin indicator={<LoadingOutlined spin />} /> : <PlusOutlined />}
                onClick={(e) => {
                  e.preventDefault()
                  setInputStatus('')
                  if (!newName) {
                    setInputStatus('error')
                    return
                  }
                  if (customMeetingBooked?.response?.find((tag: any) => tag.name === newName)) {
                    setInputStatus('error')
                    message.error('Tag Name Exists')
                    return
                  }
                  setShowIconPicker(true)
                }}></Button>
            </Space>
          </>
        </>
      )}
      options={options}
    />
  )
}

export default CustomMeetingBook
