import React from 'react'
import { Modal, Button } from 'antd'
import styles from './WarningDeleteModal.module.scss'
import warning_triangle from 'common/assets/svg/warning_triangle.svg'

interface WarningNodeModalProps {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
}

const WarningDeleteModal: React.FC<WarningNodeModalProps> = ({ open, onConfirm, onCancel }) => {
  return (
    <Modal
      centered
      open={open}
      onCancel={onCancel}
      footer={null}
      className={styles.warningModal}
      closeIcon={<span className={styles.closeIcon}>×</span>}>
      <div className={styles.container}>
        <img src={warning_triangle} alt='' />
        <h2 className={styles.title}>Are you sure?</h2>
        <p className={styles.description}>
          This action will <b>permanently remove</b> the account from Convrt Inbox and unassign it from all campaigns it
          is part of. Any campaign where this account is the sole sender will be paused.{' '}
        </p>
        <div className={styles.buttonWrapper}>
          <Button className={styles.cancelButton} onClick={onConfirm}>
            Remove the account
          </Button>
          <Button className={styles.confirmButton} onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default WarningDeleteModal
