import React, { useEffect } from 'react'
import { Modal, Button } from 'antd'
import styles from './NotificationModal.module.scss'
import talk_to_us from 'common/assets/svg/talk_to_us.svg'
import convrt_info_outlined from 'common/assets/svg/convrt_info_outlined.svg'
import { useIntercom } from 'react-use-intercom'
import { useLocalStorage } from 'usehooks-ts'
import { useNavigate } from 'react-router-dom'

interface NotificationModalProps {
  type: 'support' | 'upgrade' | 'error' | 'signin' | 'addAccount'
  open: boolean
  onClose: () => void
  data?: any
}

const NotificationModal: React.FC<NotificationModalProps> = ({ type, open, onClose, data }) => {
  const navigate = useNavigate()
  const { boot, showNewMessage } = useIntercom()
  const [user] = useLocalStorage<any>('user', null)
  const [, setFailedSigninAttemps] = useLocalStorage<any>('failed_signin_attempt', null)

  const bootIntercomWithMessage = () => {
    boot({
      name: user?.name,
      email: user?.email,
    })
    showNewMessage('I need help with pricing!')
    onClose()
  }

  const handleAction = () => {
    if (type === 'support') {
      bootIntercomWithMessage()
    } else if (type === 'upgrade') {
      navigate('/upgrade')
    } else if (type === 'signin') {
      boot({
        name: user?.name,
        email: user?.email,
      })
      showNewMessage('I need help signing in to my account!')
      onClose()
    }
    onClose()
  }

  useEffect(() => {
    setFailedSigninAttemps(0)
  }, [])

  const findNextPlan = (currentPlanName: any, currentTier: any, plans: any) => {
    const currentPlan = plans?.find((plan: any) => plan.planName === currentPlanName)
    const currentPlanId = currentPlan?.id
    if (!currentPlan) return null

    const currentChannels = currentPlan?.features?.find((feature: any) => feature.name === 'Channels')?.value?.[
      currentTier
    ]

    for (const plan of plans) {
      if (plan?.id < currentPlanId) continue

      for (let tier = 1; tier <= 3; tier++) {
        if (plan.id === currentPlanId && tier <= currentTier) continue
        const channelsInTier = plan.features.find((feature: any) => feature.name === 'Channels')?.value?.[tier]

        if (channelsInTier && channelsInTier > currentChannels) {
          const tierLetter = tier === 1 ? 'I' : tier === 2 ? 'II' : tier === 3 ? 'III' : ''
          return { planName: plan.planName, tier: tierLetter, channels: channelsInTier }
        }
      }
    }

    return null
  }

  const renderContent = () => {
    switch (type) {
      case 'support':
        return (
          <div className={styles.notificationDiv}>
            <p>Not sure which plan suits your needs?</p>
            <img src={talk_to_us} alt='Talk to Us' />
            <span style={{ color: '#97a0af', fontSize: '16px' }}>
              Speak with one of our sales experts <br />
              to help you choose the best plan for you.
            </span>
            <Button onClick={handleAction} type='primary'>
              Get Expert Advice
            </Button>
          </div>
        )
      case 'signin':
        return (
          <div className={styles.notificationDiv}>
            <p>Having trouble signing in?</p>
            <img src={talk_to_us} alt='Talk to Us' />
            <span style={{ color: '#97a0af', fontSize: '16px' }}>Don't worry, our team is ready to assist you.</span>
            <Button onClick={handleAction} type='primary'>
              Get Support
            </Button>
          </div>
        )

      case 'upgrade':
        return (
          <div className={styles.notificationDiv}>
            <p>Upgrade your plan today!</p>
            <img src={talk_to_us} alt='Talk to Us' />
            <span className={styles.description}>
              Unlock more features and get access to premium support <br />
              by upgrading to a higher plan.
            </span>
            <Button onClick={handleAction} type='primary'>
              Upgrade Now
            </Button>
          </div>
        )
      case 'error':
        return (
          <div className={styles.notificationDiv}>
            <p>An error occurred</p>
            <img src={talk_to_us} alt='Talk to Us' />
            <span className={styles.description}>
              There was a problem processing your request. <br />
              Please try again later or contact support.
            </span>
            <Button onClick={onClose} type='primary'>
              Close
            </Button>
          </div>
        )
      case 'addAccount':
        return (
          <div className={styles.notificationDiv}>
            <div className={styles.header}>
              <div className={styles.rowDiv}>
                <img src={convrt_info_outlined} alt='' />
                <p>Connection Limit Reached</p>
              </div>
              <span>Cancel</span>
            </div>

            <span className={styles.description}>
              You’ve reached the maximum number of <br />
              connected social platforms for your plan. <br />
              Upgrade now to connect more platforms <br />
              and expand your reach!
            </span>
            <Button onClick={() => navigate('/upgrade')} type='primary'>
              Upgrade Now!
            </Button>
          </div>
        )
      default:
        return null
    }
  }

  if (type === 'addAccount') {
    const plan = data?.subscription?.plan_name
    const tier = data?.subscription?.tier
    const tierLetter = ['I', 'II', 'III'][tier - 1] || ''
    const planName = plan ? `${plan} - ${tierLetter}` : 'your plan'

    const nextPlan = findNextPlan(plan, tier, data.plans)
    const upgradeText = nextPlan ? `${nextPlan.planName} - ${nextPlan.tier}` : ''

    return (
      <Modal
        open={open}
        onCancel={onClose}
        footer={false}
        centered
        width={450}
        closable={false}
        className='ModalNoPadding'>
        <div className={styles.customModal}>
          <div className={styles.header}>
            <div className={styles.rowDiv}>
              <img src={convrt_info_outlined} alt='' />
              <p>Connection Limit Reached</p>
            </div>
            <span onClick={onClose}>Cancel</span>
          </div>

          <span className={styles.description}>
            You’ve reached the maximum number of <br />
            connected social platforms for <span style={{ fontWeight: 600 }}>{planName}</span>. <br />
            Upgrade now to{' '}
            {upgradeText && (
              <span>
                <span style={{ fontWeight: 600 }}>{upgradeText}</span> or beyond
              </span>
            )}{' '}
            to connect more platforms <br />
            and expand your reach!
          </span>

          <div className={styles.footer}>
            <Button onClick={() => navigate('/upgrade')} type='primary'>
              Upgrade Now!
            </Button>
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <Modal open={open} onCancel={onClose} footer={false} centered width={450}>
      {renderContent()}
    </Modal>
  )
}

export default NotificationModal
