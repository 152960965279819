export const meeting_booked_map = [
  {
    label: 'Interseted',
    key: 'interested',
  },
  {
    label: 'Not Interested',
    key: 'not_interested',
  },
  {
    label: 'Booked',
    key: 'booked',
  },
  {
    label: 'Out of Office',
    key: 'out_of_office',
  },
  {
    label: 'Closed',
    key: 'closed',
  },
  {
    label: 'Not Now',
    key: 'not_now',
  },
  {
    label: 'Do Not Contact',
    key: 'do_not_contact',
  },
]

export const getTagLabel = (tagKey: string) => {
  const found = meeting_booked_map.find((item) => item.key === tagKey.toLowerCase())
  return found ? found.label : tagKey
}

export const processMeetingData = (meetingData: any[]) => {
  const data = meetingData?.filter((mb: any) => mb?.meeting_book !== null)
  // Step 1: Merge same keys by their label
  const mergedData = data.reduce((acc: any[], item: any) => {
    // Find if the item already exists in the array based on mapped label
    const existingTag = acc.find((tag: any) => getTagLabel(item.meeting_book).toLowerCase() === tag.label.toLowerCase())

    if (existingTag) {
      // If the tag already exists, sum the counts
      existingTag.count += item.count
    } else {
      // If not, add a new entry with the label and count
      acc.push({ label: getTagLabel(item.meeting_book), count: item.count })
    }

    return acc
  }, [])

  // Step 2: Sort the result by count in descending order
  return mergedData.sort((a: any, b: any) => b.count - a.count)
}

export const nonHideableColumns = ['is_active', 'campaign_name', 'channels', 'status', 'prospects', 'actions']

export const fixedColumnsLocations = [
  {
    key: 'is_active',
    location: 0,
  },
  {
    key: 'campaign_name',
    location: 1,
  },
  {
    key: 'status',
    location: 2,
  },
  {
    key: 'channels',
    location: 3,
  },
  {
    key: 'actions',
    location: -1,
  },
]

export const fixedColumns = ['is_active', 'campaign_name', 'status', 'channels', 'actions']

export const initHidden = [
  'connections_sent',
  'connections_accepted',
  'liked_posts',
  'follow_sent',
  'follow_accepted',
  'meeting_booked',
]

export const defaultTypes = ['default', 'engagement_stats', 'meeting_booked']
