import React, { useState } from 'react'
import { Input, Button, Form, message, Card, Typography } from 'antd'
import styles from './CustomerServiceSettings.module.scss'
import { useLazyGenerateOneTimeTokenQuery } from '../state/api/SettingsApi'

const { Title } = Typography

const CustomerServiceSettings: React.FC = () => {
  const [userId, setUserId] = useState<string>('')
  const [token, setToken] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [generateToken] = useLazyGenerateOneTimeTokenQuery()

  const handleGenerateToken = async () => {
    if (!userId) {
      message.error('Please enter a User ID')
      return
    }

    try {
      setLoading(true)
      const { data, error } = await generateToken({ userId })
      if (data) {
        setToken(data.token)
        message.success('Token generated successfully')
      } else if (error) {
        message.error(error.data.message)
      }
    } catch (error) {
      console.log(error)
      message.error('Error generating token')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card className={styles.profileCard}>
      <Title level={3}>Generate One-Time Login Token</Title>
      <Form layout='vertical' className={styles.form}>
        <Form.Item label='User ID'>
          <Input value={userId} onChange={(e) => setUserId(e.target.value)} placeholder='Enter User ID' />
        </Form.Item>
        {token && (
          <Form.Item label='Generated Token'>
            <Input.TextArea value={token} readOnly autoSize={{ minRows: 2, maxRows: 6 }} />
          </Form.Item>
        )}
        <Form.Item>
          <Button type='primary' onClick={handleGenerateToken} loading={loading}>
            Generate One-Time Token
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default CustomerServiceSettings
