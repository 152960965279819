import React, { useEffect, useState } from 'react'
import { Table, Skeleton, Drawer, Button, Input, Divider } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import gray_eye from 'common/assets/svg/gray_eye.svg'
import drag from 'common/assets/svg/drag.svg'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import type { TableProps } from 'antd'
import styles from './ModularCampaignsTable.module.scss'
import { capitalize } from 'lodash'
import { useLazySaveCampaignsDefualtColumnsQuery } from 'features/Outreach/state/api/OutreachApi'
import { useLocalStorage } from 'usehooks-ts'
import _ from 'lodash'
import {
  defaultTypes,
  initHidden,
  nonHideableColumns,
  fixedColumns,
  fixedColumnsLocations,
} from './Helpers/CampaignsConstants'

interface ModularCampaignsTableProps extends TableProps<any> {
  columns: any
  campaigns: any[]
  loadingTable?: boolean
  pageSize?: number
  rowSelection?: any
  isExpanded?: boolean
  openDrawer: boolean
  setOpenDrawer: (open: boolean) => void
  defaultColumns?: any
  dynamicColumns?: any
}

const ModularCampaignsTable: React.FC<ModularCampaignsTableProps> = ({
  columns,
  campaigns,
  loadingTable = false,
  pageSize = 10,
  rowSelection,
  isExpanded = false,
  openDrawer,
  setOpenDrawer,
  defaultColumns,
  dynamicColumns,
  ...tableProps
}) => {
  const [user, setUser] = useLocalStorage<any>('user', null)
  const { default_campaigns_columns } = user
  const currentDefaultColumns =
    Array.isArray(default_campaigns_columns) && default_campaigns_columns?.length >= nonHideableColumns.length
      ? default_campaigns_columns?.map((col: any) => col.key)
      : defaultColumns?.filter((col: any) => !initHidden.includes(col.key))?.map((col: any) => col.key)
  const [searchTerm, setSearchTerm] = useState('')
  const [updateCampaignsDefaultColumns] = useLazySaveCampaignsDefualtColumnsQuery()
  const [visibleColumns, setVisibleColumns] = useState<any>([])
  const [hiddenColumns, setHiddenColumns] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const toggleDrawer = () => setOpenDrawer(!openDrawer)

  const handleClose = () => {
    let visibleColumnsNew = columns?.filter((col: any) => currentDefaultColumns?.includes(col.key))
    const hiddenColumnsNew = columns?.filter((col: any) => !currentDefaultColumns?.includes(col.key))

    visibleColumnsNew = visibleColumnsNew.sort((a: any, b: any) => {
      return currentDefaultColumns.indexOf(a.key) - currentDefaultColumns.indexOf(b.key)
    })
    setVisibleColumns(visibleColumnsNew)
    setHiddenColumns(hiddenColumnsNew)

    toggleDrawer()
  }

  const handleSave = async () => {
    setLoading(true)
    // const columns = visibleColumns?.map((col: any) => col.key)

    const columns = visibleColumns?.map((col: any) => ({
      key: col.key,
      type: defaultTypes.includes(col.type) ? 'default' : col.type || 'default',
    }))

    await updateCampaignsDefaultColumns({
      columns: columns,
    })
    setUser({ ...user, default_campaigns_columns: columns })
    toggleDrawer()
    setLoading(false)
  }

  const modifiedColumns = visibleColumns.map((column: any) => ({
    ...column,
    hidden: false,
  }))

  const handleResetToDefault = () => {
    const resetDefaultColumns = defaultColumns
      ?.filter((col: any) => !initHidden.includes(col.key))
      ?.map((col: any) => col.key)
    const resetVisibleColumns = columns.filter((col: any) => resetDefaultColumns.includes(col.key))

    const sortedVisibleColumns = resetVisibleColumns.sort((a: any, b: any) => {
      return resetDefaultColumns.indexOf(a.key) - resetDefaultColumns.indexOf(b.key)
    })

    const resetHiddenColumns = columns.filter((col: any) => !resetDefaultColumns.includes(col.key))

    const initialHiddenColumns =
      resetDefaultColumns.length === 0 || !default_campaigns_columns
        ? columns.filter((col: any) => initHidden.includes(col.key))
        : []

    setVisibleColumns(sortedVisibleColumns)
    setHiddenColumns([...resetHiddenColumns, ...initialHiddenColumns])
  }

  useEffect(() => {
    if (!openDrawer) {
      const visibleColumnsNew = columns?.filter((col: any) => currentDefaultColumns?.includes(col.key))
      const hiddenColumnsNew = columns?.filter((col: any) => !currentDefaultColumns?.includes(col.key))

      // Apply initHidden as the default hidden columns
      const initialHiddenColumns =
        currentDefaultColumns?.length === 0 || !default_campaigns_columns
          ? columns.filter((col: any) => initHidden.includes(col.key))
          : []

      setVisibleColumns(visibleColumnsNew)
      setHiddenColumns([...hiddenColumnsNew, ...initialHiddenColumns])
    }
  }, [dynamicColumns])

  useEffect(() => {
    if (!loading && !openDrawer) {
      const missingColumns = default_campaigns_columns.filter(
        ({ key }: any) => !columns.some((col: any) => col.key === key),
      )

      const temporaryColumns: any = missingColumns.map(({ key, type }: any) => ({
        key,
        title: key,
        align: 'center',
        ellipsis: true,
        width: 140,
        type: type,
        render: () => <span></span>,
      }))

      const sortedColumns = [...columns, ...temporaryColumns]
        ?.filter((col: any) => currentDefaultColumns?.includes(col.key))
        .sort((a: any, b: any) => currentDefaultColumns.indexOf(a.key) - currentDefaultColumns.indexOf(b.key))

      // Move the 'actions' column to the end if it's in the columns
      const actionsColumnIndex = sortedColumns.findIndex((col: any) => col.key === 'actions')
      if (actionsColumnIndex !== -1) {
        const actionsColumn = sortedColumns.splice(actionsColumnIndex, 1)[0]
        sortedColumns.push(actionsColumn) // Add 'actions' column to the end
      }

      setVisibleColumns(sortedColumns)
    }
  }, [columns])

  const onDragEnd = (result: any) => {
    const { source, destination, draggableId } = result
    const fixedEndIndices = fixedColumnsLocations
      .filter((col) => col.location === -1) // Columns fixed at the end
      .map(() => visibleColumns.length - 1) // Last column

    const lastFixedIndex = Math.max(...fixedEndIndices, -Infinity)

    if (destination.index <= 3 || destination.index >= lastFixedIndex) {
      // Invalid drag-and-drop, revert
      return
    }

    if (nonHideableColumns?.includes(draggableId) && destination.droppableId?.startsWith('hiddenColumns')) return
    if (!destination) return
    if (destination.droppableId !== 'visibleColumns' && visibleColumns.length === 1) {
      return
    }
    if (source.droppableId === destination.droppableId) {
      const items = Array.from(source.droppableId === 'visibleColumns' ? visibleColumns : hiddenColumns)

      const [reorderedItem] = items.splice(source.index, 1)
      items.splice(destination.index, 0, reorderedItem)

      source.droppableId === 'visibleColumns' ? setVisibleColumns(items) : setHiddenColumns(items)
    } else {
      const sourceList = Array.from(source.droppableId === 'visibleColumns' ? visibleColumns : hiddenColumns)
      const destinationList = Array.from(destination.droppableId === 'visibleColumns' ? visibleColumns : hiddenColumns)

      const [movedItem] = sourceList.splice(source.index, 1)
      destinationList.splice(destination.index, 0, movedItem)
      if (source.droppableId === 'visibleColumns') {
        setVisibleColumns(sourceList)
        setHiddenColumns(destinationList)
      } else {
        setVisibleColumns(destinationList)
        setHiddenColumns(sourceList)
      }
    }
  }

  // Filter columns based on the search term
  const filteredVisibleColumns = visibleColumns?.filter((col: any) => {
    return typeof col?.title === 'string' && col.title.toLowerCase().includes(searchTerm?.toLowerCase())
  })

  const filteredHiddenColumns = hiddenColumns?.filter((col: any) => {
    return typeof col?.title === 'string' && col.title.toLowerCase().includes(searchTerm?.toLowerCase())
  })

  // Group columns by type dynamically
  const groupColumnsByType = (columns: any[]) => {
    return columns.reduce((acc: any, col: any) => {
      const type = col?.type || 'default'
      if (!acc[type]) acc[type] = []
      acc[type].push(col)
      return acc
    }, {})
  }

  const groupedHiddenColumns = groupColumnsByType(filteredHiddenColumns)

  return (
    <>
      <Table
        {...tableProps}
        dataSource={
          loadingTable
            ? [...Array(pageSize)].map((_, index) => ({
                key: `key${index}`,
              }))
            : campaigns
        }
        columns={
          loadingTable
            ? modifiedColumns.map((column: any) => {
                return {
                  ...column,
                  render: function renderPlaceholder() {
                    return (
                      <Skeleton
                        style={{
                          margin: '0px',
                          paddingTop: '0px',
                          paddingBottom: '0px',
                          paddingLeft: '5px',
                          height: '22px',
                          paddingRight: '5px',
                          display: 'flex',
                          alignItems: 'center',
                          opacity: 0.5,
                        }}
                        className='campaignsPageTable'
                        key={column.key}
                        title={true}
                        paragraph={false}
                        active
                      />
                    )
                  },
                }
              })
            : modifiedColumns
        }
        rowSelection={rowSelection}
      />

      <Drawer
        className='modularTable'
        rootClassName='modularTable'
        maskClosable={false}
        bodyStyle={{ backgroundColor: '#f3f4f6' }}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>Properties</span>
            <div className={styles.rightSide}>
              <span className={styles.reset} onClick={handleResetToDefault}>
                Reset to Default
              </span>
              <Button type='primary' onClick={handleSave} loading={loading}>
                Save
              </Button>
            </div>
          </div>
        }
        placement='right'
        closable={true}
        onClose={handleClose}
        open={openDrawer}
        width={450}>
        <Input
          prefix={<SearchOutlined style={{ color: '#97A0AF', fontSize: '16px' }} />}
          placeholder='Search for Properties'
          size='large'
          style={{ marginBottom: '1rem', fontSize: '14px' }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <div className={styles.title}>
          <span style={{ fontWeight: 600 }}>Showing in table</span>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='visibleColumns'>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ display: 'flex', flexDirection: 'column' }}>
                {filteredVisibleColumns.map((col: any, index: any) => (
                  <Draggable key={col.key} draggableId={col.key} index={index}>
                    {(provided) => (
                      <div
                        className={styles.draggableItem}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{ ...provided.draggableProps.style }}>
                        <div
                          className={styles.handle}
                          style={fixedColumns.includes(col.key) ? { cursor: 'default' } : {}}
                          {...(fixedColumns.includes(col.key) ? {} : provided.dragHandleProps)}>
                          <img src={drag} alt='' style={fixedColumns.includes(col.key) ? { opacity: 0.3 } : {}} />
                        </div>
                        <div className={styles.innerBody}>
                          <span> {col.title || capitalize(col.key)}</span>
                          <Button
                            type='link'
                            onClick={() => {
                              if (nonHideableColumns.includes(col.key)) {
                                return
                              }
                              setHiddenColumns([...hiddenColumns, col])
                              setVisibleColumns(visibleColumns.filter((c: any) => c.key !== col.key))
                            }}
                            style={nonHideableColumns.includes(col.key) ? { opacity: 0.3, cursor: 'default' } : {}}>
                            <img src={gray_eye} alt='' />
                          </Button>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <Divider />
          <div className={styles.title} style={{ margin: 0 }}>
            <span style={{ fontWeight: 600 }}>Hidden</span>
          </div>
          {/* <Droppable droppableId='hiddenColumns'>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {filteredHiddenColumns.map((col: any, index: any) => (
                  <Draggable key={col.key} draggableId={col.key} index={index}>
                    {(provided) => (
                      <div
                        className={styles.draggableItem}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{ ...provided.draggableProps.style }}>
                        <div className={styles.handle} {...provided.dragHandleProps}>
                          <img src={drag} alt='' />
                        </div>
                        <div className={styles.innerBody}>
                          {col?.type === 'dynamic' ? (
                            <Tag className={styles.tag} color='#f2f0ff'>
                              {col.title}
                            </Tag>
                          ) : (
                            <span>{col.title}</span>
                          )}
                          <Button
                            type='link'
                            onClick={() => {
                              const updatedHidden = hiddenColumns.filter((c: any) => c.key !== col.key)
                              const updatedVisible = [...visibleColumns, col]
                              setHiddenColumns(updatedHidden)
                              setVisibleColumns(updatedVisible)
                            }}>
                            <img src={gray_eye} alt='' />
                          </Button>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable> */}

          {Object.keys(groupedHiddenColumns).map((type) => (
            <div key={type}>
              <div className={styles.subtitle}>
                <span style={{ textAlign: 'center' }}>{_.startCase(type)}</span>
              </div>
              <Droppable droppableId={`hiddenColumns_${type}`}>
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ display: 'flex', flexDirection: 'column' }}>
                    {groupedHiddenColumns[type].map((col: any, index: any) => (
                      <Draggable key={col.key} draggableId={col.key} index={index}>
                        {(provided) => (
                          <div
                            className={styles.draggableItem}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{ ...provided.draggableProps.style }}>
                            <div
                              className={styles.handle}
                              style={{ cursor: 'default' }}
                              // {...provided.dragHandleProps}
                            >
                              <img src={drag} alt='' style={{ opacity: 0.3 }} />
                            </div>
                            <div className={styles.innerBody}>
                              <span>{col.title || capitalize(col.key)}</span>
                              <Button
                                type='link'
                                onClick={() => {
                                  if (nonHideableColumns.includes(col.key)) return

                                  const updatedHiddenColumns = hiddenColumns.filter((c: any) => c.key !== col.key)
                                  const updatedVisibleColumns = [...visibleColumns]

                                  // Insert at the second-to-last position before 'actions'
                                  const actionsIndex = updatedVisibleColumns.findIndex((c) => c.key === 'actions')
                                  const insertIndex = actionsIndex !== -1 ? actionsIndex : updatedVisibleColumns.length
                                  updatedVisibleColumns.splice(insertIndex, 0, col)

                                  setHiddenColumns(updatedHiddenColumns)
                                  setVisibleColumns(updatedVisibleColumns)
                                }}
                                style={nonHideableColumns.includes(col.key) ? { opacity: 0.3, cursor: 'default' } : {}}>
                                <img src={gray_eye} alt='' />
                              </Button>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </DragDropContext>
      </Drawer>
    </>
  )
}

export default ModularCampaignsTable
