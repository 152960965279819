import React from 'react'
import { Button, Divider } from 'antd'
import styles from './CampaignActions.module.scss'
import archive_icon from 'common/assets/svg/archive_icon.svg'
import unarchive_icon from 'common/assets/svg/archive_icon.svg'
import play_circle_icon from 'common/assets/svg/play_circle_icon.svg'
import pause_circle_icon from 'common/assets/svg/pause_circle_icon.svg'
import { useAppSelector } from 'state'

interface CampaignActionsProps {
  selectedRows: any[]
  campaigns: any[]
  setOpenArchiveCampaignModal: (open: boolean) => void
  setCampaignName: (name: string) => void
  handleBulkActivate: () => void
  setOpenDisableCampaignModal: (open: boolean) => void
  handleDismissSelection: () => void
  handleBulkUnarchive: () => void
}

const CampaignActions: React.FC<CampaignActionsProps> = ({
  selectedRows,
  campaigns,
  setOpenArchiveCampaignModal,
  setCampaignName,
  handleBulkActivate,
  setOpenDisableCampaignModal,
  handleDismissSelection,
  handleBulkUnarchive,
}) => {
  const { campaign_filters } = useAppSelector((state) => state.outreachCampaign)
  const isArchive = campaign_filters?.value === 'archive'

  if (selectedRows?.length === 0) return <div></div>

  return (
    <div className={styles.selectionActions}>
      <span>{`Selected ${selectedRows?.length || '0'} of ${campaigns?.length || '0'} campaigns`}</span>
      <Divider type='vertical' />

      {isArchive ? (
        <Button onClick={handleBulkUnarchive} disabled={selectedRows?.length === 0}>
          <img src={unarchive_icon} alt='' />
          <span>Unarchive</span>
        </Button>
      ) : (
        <>
          <Button
            onClick={() => {
              setOpenArchiveCampaignModal(true)
              setCampaignName(`${selectedRows?.length} of your Campaigns`)
            }}
            disabled={selectedRows?.length === 0}>
            <img src={archive_icon} alt='' />
            <span>Archive</span>
          </Button>
          <Button onClick={handleBulkActivate} disabled={selectedRows?.length === 0}>
            <img src={play_circle_icon} alt='' />
            <span>Run</span>
          </Button>
          <Button
            onClick={() => {
              setOpenDisableCampaignModal(true)
              setCampaignName(`${selectedRows?.length} of your Campaigns`)
            }}
            disabled={selectedRows?.length === 0}>
            <img src={pause_circle_icon} alt='' />
            <span>Pause</span>
          </Button>
        </>
      )}

      <Divider type='vertical' />
      <span className={styles.dismiss} onClick={handleDismissSelection}>
        Dismiss
      </span>
    </div>
  )
}

export default CampaignActions
