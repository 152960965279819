import { Layout, Steps, Card, Row, Col, Space, Button, message, Popover } from 'antd'
import styles from './Onboarding.module.scss'
import instagram_icon from 'common/assets/svg/instagram_icon.svg'
import facebook_icon from 'common/assets/svg/facebook_logo.svg'
import linkedin_icon from 'common/assets/svg/linkedin_logo.svg'
import twitter_icon from 'common/assets/svg/twitter_logo.svg'
import telegram_icon from 'common/assets/svg/telegram_logo.svg'
import discord_icon from 'common/assets/svg/discord_logo.svg'
import reddit_icon from 'common/assets/svg/reddit_icon.svg'
import tiktok_icon from 'common/assets/svg/tiktok_logo.svg'
import SmallSocialCard from '../Components/SmallSocialCard'
import { useEffect, useState } from 'react'
import hooray_img from 'common/assets/svg/hooray.svg'
import hooray_conffeti from 'common/assets/lottie-animations/hooray_confetti.json'
import Lottie from 'lottie-react'
import { useNavigate } from 'react-router-dom'
import {
  useAddIndividualPersonasMutation,
  useLazySubmitOnboardingStepQuery,
  useLazyUpdateUserDataQuery,
} from '../../state/api/SettingsApi'
import { useLocalStorage } from 'usehooks-ts'
import OnboardingICP from '../Onboarding/OnboardingICP'
import { Footer } from 'antd/es/layout/layout'
import OnboardingTrack from './OnboardingTrack'
import {
  useLazyGetGroupsQuery,
  useLazyGetUserSpecificGroupsQuery,
  useLazySetSelectedGroupsQuery,
} from 'common/components/OutreachAI/state/api/OutreachAI-API'
import SmallLogout from 'GeneralComponents/SmallLogout'
import { trackEvent } from 'eventConfig'

const { Header, Content, Sider } = Layout

const icons = {
  instagram: instagram_icon,
  facebook: facebook_icon,
  linkedin: linkedin_icon,
  telegram: telegram_icon,
  twitter: twitter_icon,
  discord: discord_icon,
  reddit: reddit_icon,
  tiktok: tiktok_icon,
}

const areAllKeysFilled = (obj: any, checkAll: boolean) => {
  if (obj.persona_type === 'business') {
    for (const [key, value] of Object.entries(obj)) {
      if (key === 'description') continue
      if (Array.isArray(value)) {
        if (key === 'parsed_locations' || key === 'interests') continue

        if (value.length === 0 || !value.every((item) => typeof item === 'string' && item.trim().length > 0)) {
          return false
        }
      } else if (typeof value === 'string') {
        if (['problem', 'solution', 'results', 'competitive_advantage'].includes(key) && !checkAll) {
          continue
        }
        if (value.trim().length === 0) {
          return false
        }
      } else {
        return false
      }
    }
    return true
  } else {
    for (const [key, value] of Object.entries(obj)) {
      if (key === 'parsed_locations' || key === 'job_title' || key === 'industry' || key === 'number_of_employees')
        continue
      if (Array.isArray(value)) {
        if (value.length === 0 || !value.every((item) => typeof item === 'string' && item.trim().length > 0)) {
          return false
        }
      } else if (typeof value === 'string') {
        if (
          ['problem', 'solution', 'results', 'competitive_advantage', 'description', 'job_title'].includes(key) &&
          !checkAll
        ) {
          continue
        }
        if (value.trim().length === 0) {
          return false
        }
      } else {
        return false
      }
    }
  }
  return true
}

const Onboarding: React.FC = () => {
  const navigate = useNavigate()

  const [currentPersonaStep, setCurrentPersonaStep] = useState(1)
  const [personaType, setPersonaType] = useState<string>('')

  const [getGroups, { data: groupsData }] = useLazyGetGroupsQuery()
  const [getUserGroups, { data: userSpecificGroups, isFetching }] = useLazyGetUserSpecificGroupsQuery()
  const [setSelectedGroups] = useLazySetSelectedGroupsQuery()
  const [loading, setLoading] = useState(false)
  const [selectedCards, setSelectedCards] = useState<string[]>([])
  const [updateUserData] = useLazyUpdateUserDataQuery()
  const [submitOnboardingStep] = useLazySubmitOnboardingStepQuery()
  const [addIndividualPersonas] = useAddIndividualPersonasMutation()
  const [anyPlatformConnected, setAnyPlatformConnected] = useState(false)
  const [allPlatformsConnected, setAllPlatformsConnected] = useState(false)
  const [highlightMissingValues, setHighlightMissingValues] = useState(false)
  const [openPopover, setOpenPopover] = useState(false)
  const [user, setUser] = useLocalStorage<any>('user', null)
  const [userAddons, setUserAddons] = useState(user?.addons)
  const [currentStep, setCurrentStep] = useState(0)
  const [ICPData, setICPData] = useState<any>({})
  const [trackData, setTrackData] = useState<any>({})
  const maxChannels = userAddons.maxChannels || 0

  const handleSkip = () => {
    trackEvent('ONBOARDING_USER_SKIPPED_SELECTING_GROUPS', {
      step: 3,
      type: 'confirm',
    })
    setCurrentStep(currentStep + 1)
  }

  const handleConfirm = async () => {
    const updatedAddons = {
      ...user.addons,
      onboarding_status: currentStep === 4,
      onboarding_step: currentStep + 1,
    }

    setUser(() => ({
      ...user,
      addons: updatedAddons,
    }))

    if (currentStep === 0) {
      trackEvent('ONBOARDING_USER_SELECTED_PLATFORMS', {
        platforms: selectedCards,
        step: 0,
        type: 'confirm',
      })
      setCurrentStep(currentStep + 1)
    }

    if (currentStep === 1) {
      const connectedPlatforms = selectedCards.find((platform) => user?.[platform]?.length > 0)

      trackEvent('ONBOARDING_USER_CONNECTED_PLATFORMS', {
        connected_platforms: connectedPlatforms,
        step: 1,
        type: 'confirm',
      })
      setCurrentStep(currentStep + 1)
    }

    if (currentStep === 2) {
      handleCreatePersona()
    }

    if (currentStep === 3) {
      // Add selected groups to groups_fetching_requests
      setLoading(true)
      const { data } = await setSelectedGroups({ data: trackData })
      if (data) {
        trackEvent('ONBOARDING_USER_SELECTED_GROUPS', {
          groups: trackData,
          step: 3,
          type: 'confirm',
        })
        setCurrentStep(currentStep + 1)
      } else {
        message.error('Something went worng. Please try again or contact support.')
      }
      setLoading(false)
    }

    if (currentStep === 4) {
      setLoading(true)
      const addonName = ['onboarding_status', ...selectedCards.map((card) => `channel_${card}`)]
      const addonValue = new Array(addonName.length).fill(true)

      const { data } = await updateUserData({
        addonName: addonName,
        addonValue: addonValue,
      })

      trackEvent('ONBOARDING_USER_COMPLETED_ONBOARDING', {
        step: 4,
        type: 'confirm',
      })

      setUser(data)
      setLoading(false)
      navigate('/outreach')
    }
  }

  const handleCreatePersona = async () => {
    if (!areAllKeysFilled(ICPData, true)) {
      setOpenPopover(true)
      setHighlightMissingValues(true)
    } else {
      proceedWithCreatePersona()
    }
  }

  const proceedWithCreatePersona = async () => {
    try {
      setLoading(true)
      if (ICPData.persona_type === 'business') {
        const { data } = await submitOnboardingStep({ step: 'ICP', data: ICPData })
        if (data?.success) {
          trackEvent('ONBOARDING_USER_CREATED_PERSONA', {
            persona: ICPData,
            step: 2,
            type: 'confirm',
          })
          setCurrentStep(currentStep + 1)
        } else {
          message.error(`Can't update user's ICP. Please try again or contact support.`)
        }
      } else if (ICPData.persona_type === 'individual') {
        await addIndividualPersonas(ICPData)
        trackEvent('ONBOARDING_USER_CREATED_PERSONA', {
          persona: ICPData,
          step: 2,
          type: 'confirm',
        })
        setCurrentStep(currentStep + 1)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error(`Can't update user's ICP. Please try again or contact support.`)
    }
  }

  const handleSelect = (title: string) => {
    setSelectedCards((prevSelected) => {
      const isAlreadySelected = prevSelected.includes(title)

      if (isAlreadySelected) {
        return prevSelected.filter((item) => item !== title)
      }

      if (prevSelected.length < maxChannels) {
        return [...prevSelected, title]
      }

      return prevSelected
    })
  }

  const handleStepChange = (nextStep: number) => {
    // Allow going back to the first step if no platform is connected
    if (!anyPlatformConnected && nextStep === 0 && currentStep < 2) {
      setCurrentStep(0)
    } else if (anyPlatformConnected) {
      // Prevent changing steps if a platform is connected
      setCurrentStep((prevStep) => prevStep)
    }
  }

  const getAllGroupsData = async () => {
    await getGroups({})
  }

  const getUserSpecificGroups = async () => {
    await getUserGroups()
  }

  useEffect(() => {
    const isAnyPlatformConnected = selectedCards.some((platform) => user?.[platform]?.length > 0)
    const areAllSelectedPlatformsConnected = selectedCards.every((platform) => user?.[platform]?.length > 0)

    setAnyPlatformConnected(isAnyPlatformConnected)
    setAllPlatformsConnected(areAllSelectedPlatformsConnected)
  }, [selectedCards, user])

  useEffect(() => {
    const { addons } = user
    setUserAddons(addons)
  }, [user])

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null

    const shouldCallApi = currentStep === 3

    if (shouldCallApi) {
      getUserSpecificGroups()

      interval = setInterval(() => {
        getUserSpecificGroups()
      }, 10000)
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [user, currentStep])

  useEffect(() => {
    switch (currentStep) {
      case 0:
        trackEvent('ONBOARDING_USER_SELECTED_PLATFORMS', {
          step: 0,
          type: 'view',
        })
        break
      case 1:
        trackEvent('ONBOARDING_USER_CONNECTED_PLATFORMS', {
          step: 1,
          type: 'view',
        })

        break
      case 2:
        trackEvent('ONBOARDING_USER_CREATED_PERSONA', {
          step: 2,
          type: 'view',
        })
        break
      case 3:
        trackEvent('ONBOARDING_USER_SKIPPED_SELECTING_GROUPS', {
          step: 3,
          type: 'view',
        })

        break
      case 4:
        trackEvent('ONBOARDING_USER_COMPLETED_ONBOARDING', {
          step: 4,
          type: 'view',
        })
        break
      default:
        break
    }
  }, [currentStep])

  useEffect(() => {
    getAllGroupsData()
  }, [])

  const socialCards = [
    { title: 'instagram', imageSrc: instagram_icon },
    { title: 'facebook', imageSrc: facebook_icon },
    { title: 'linkedin', imageSrc: linkedin_icon },
    { title: 'telegram', imageSrc: telegram_icon, isPremium: true },
    { title: 'twitter', imageSrc: twitter_icon, isPremium: true },
    { title: 'discord', imageSrc: discord_icon, isPremium: true },
    { title: 'reddit', imageSrc: reddit_icon, isPremium: true },
    { title: 'tiktok', imageSrc: tiktok_icon, isPremium: true },
  ]

  const getHeaderText = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className={styles.title}>
            <h1>Choose Outreach Channels</h1>
            <span>
              Adding your social account will help us provide you with the most personalized and relevant results.
            </span>
          </div>
        )
      case 1:
        return (
          <div className={styles.title}>
            <h1>Connect Your Accounts</h1>
            <span>
              Adding your social account will help us provide you with the most personalized and relevant results.
            </span>
          </div>
        )
      case 2:
        return (
          <div className={styles.title}>
            <span>Welcome to Convrt </span>
            <h1>Let's Create Your First Persona</h1>
          </div>
        )
      case 3:
        return (
          <div className={styles.title}>
            <h1>Select content to track</h1>
            <span>Adding groups, pages, and people will help us provide you with relevant information and tasks.</span>
          </div>
        )
      case 4:
        return (
          <div className={styles.title}>
            <h1>Hooray! Let’s start your first campaign</h1>
            <span>Time to launch your first social outreach campaign and boost your social selling game.</span>
          </div>
        )
      default:
        return (
          <div className={styles.title}>
            <h1>Onboarding</h1>
          </div>
        )
    }
  }

  const getButtonProps: any = () => {
    switch (currentStep) {
      case 0:
        return {
          text: 'Confirm',
          onClick: handleConfirm,
          disabled: selectedCards.length === 0,
        }
      case 1:
        return {
          text: 'Continue',
          onClick: handleConfirm,
          disabled: allPlatformsConnected === false,
        }
      case 2:
        return {
          text: 'Create Persona',
          onClick: handleConfirm,
          disabled: !areAllKeysFilled(ICPData, false),
        }
      case 3:
        return {
          text: 'Continue',
          onClick: handleConfirm,
          disabled: trackData?.length === 0,
        }
      case 4:
        return {
          text: `Start First Campaign`,
          onClick: handleConfirm,
          disabled: false,
        }
      default:
        return 'Onboarding'
    }
  }

  const buttonProps = getButtonProps()

  const popoverContent = (
    <div className={styles.popoverContainer}>
      <p>Some values are missing. Would you like to fill them in?</p>
      <span
        onClick={() => {
          setOpenPopover(false)
          setHighlightMissingValues(false)
          proceedWithCreatePersona()
        }}>
        Continue Anyway
      </span>
    </div>
  )

  const footerStyle: React.CSSProperties = {
    textAlign: 'end',
    color: '#fff',
    backgroundColor: '#F9FAFB',
    padding: 0,
  }
  const contentStyle: React.CSSProperties = {
    backgroundColor: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
  }
  const headerStyle: React.CSSProperties = {
    backgroundColor: '#F9FAFB',
    padding: 0,
  }
  const layoutStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    padding: '32px 64px',
    flexDirection: 'column',
    background: '#F9FAFB',
    // position: 'relative',
    gap: '32px',
  }

  return (
    <Layout>
      <SmallLogout />
      <Sider
        width='15%'
        style={{
          height: '100%',
          display: 'inline-flex',
          padding: '16px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '16px',
          flexShrink: '0',
          background: 'var(--Natural-100, #FFF)',
        }}>
        <div className={styles.siderContainer}>
          <p className={styles.siderText}>Convrt Onboarding</p>
        </div>
        <div className={styles.steps}>
          <Steps
            className='onboardingSteps'
            style={{ paddingLeft: '20px' }}
            size={'small'}
            direction='vertical'
            current={currentStep}
            onChange={handleStepChange}
            items={[
              {
                title: 'Choose Channels',
              },
              {
                title: 'Connect Your Accounts',
              },
              {
                title: 'Create a Persona',
              },
              {
                title: 'Track Groups & Pages',
              },
              {
                title: 'Start a Campaign',
              },
            ]}
          />
        </div>
      </Sider>
      <Layout style={layoutStyle}>
        <Header style={headerStyle}>{getHeaderText()}</Header>
        <Content style={contentStyle}>
          <Card className={`${styles.card} OnboardingCard ${currentStep === 3 ? 'CardNoPadding' : ''}`}>
            <div
              style={{
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                display: 'flex',
              }}>
              {currentStep === 0 && (
                <Space
                  direction='vertical'
                  size='middle'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    margin: 'auto',
                  }}>
                  <div className={styles.cardTitle}>
                    <p>
                      Your subscription comes with {maxChannels} channels for integration with Convrt. Choose your
                      channels here.
                    </p>
                    <span>Selection can't be changed after onboarding.</span>
                  </div>
                  <Row gutter={[16, 16]} justify='center' align='middle' style={{ flexWrap: 'wrap' }}>
                    {socialCards.map((card) => (
                      <Col key={card.title}>
                        <SmallSocialCard
                          imageSrc={card.imageSrc}
                          title={card.title}
                          isPremium={card.isPremium}
                          isDisabled={
                            // card.isDisabled ||
                            selectedCards.length === maxChannels && !selectedCards.includes(card.title)
                          }
                          isSelected={selectedCards.includes(card.title)}
                          onSelect={handleSelect}
                        />
                      </Col>
                    ))}
                  </Row>
                </Space>
              )}
              {currentStep === 1 && (
                <div style={{ paddingBottom: '40px', margin: 'auto' }}>
                  <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
                    <div className={styles.cardTitle}>
                      <p>Great! Now that you've chosen your channels, let's connect your accounts.</p>
                      <span>You can connect more accounts later if they are included with your subscription.</span>
                    </div>
                    <Row gutter={[16, 16]} justify='center' align='middle'>
                      {selectedCards.map((title) => (
                        <Col key={title}>
                          <SmallSocialCard
                            imageSrc={icons[title as keyof typeof icons]}
                            title={title}
                            isSelected={true}
                            connectionStep={true}
                            isConnected={user?.[title]?.length > 0}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Space>
                </div>
              )}
              {currentStep === 2 && (
                <div className='w-full -mt-2'>
                  <OnboardingICP
                    setICPData={setICPData}
                    highlightMissingValues={highlightMissingValues}
                    personaType={personaType}
                    setPersonaType={setPersonaType}
                    currentStep={currentPersonaStep}
                    setCurrentStep={setCurrentPersonaStep}
                  />
                </div>
              )}
              {currentStep === 3 && (
                <OnboardingTrack
                  data={groupsData?.data}
                  userSpecificData={[
                    ...(userSpecificGroups?.instagram?.data || []),
                    ...(userSpecificGroups?.facebook?.data || []),
                  ]}
                  userHistory={userSpecificGroups?.facebook?.history}
                  setTrackData={setTrackData}
                  isFetching={isFetching}
                />
              )}
              {currentStep === 4 && (
                <div className={styles.container}>
                  <div className={styles.lottieContainer}>
                    <Lottie
                      loop={false}
                      animationData={hooray_conffeti}
                      style={{ position: 'relative', height: '100%' }}
                    />
                  </div>
                  <img src={hooray_img} alt='Hooray' />
                  <p className={styles.innerCardBigTitle}>Ready to start?</p>
                  <p className={styles.innerCardSmallTitle}>
                    Click 'Start First Campaign' to build your sequences in four easy steps
                  </p>
                </div>
              )}
            </div>
          </Card>
        </Content>
        <Footer style={footerStyle}>
          <div className={styles.footer}>
            {anyPlatformConnected && currentStep === 1 && !allPlatformsConnected && (
              <div className={styles.doLater}>
                <span onClick={handleConfirm}>Do it Later</span>
              </div>
            )}
            {currentStep === 3 && (
              <div className={styles.doLater}>
                <span onClick={handleSkip}>Skip</span>
              </div>
            )}
            <Popover
              content={popoverContent}
              open={openPopover}
              onOpenChange={() => {
                setOpenPopover(false)
                setHighlightMissingValues(false)
              }}>
              <Button loading={loading} type='primary' onClick={buttonProps.onClick} disabled={buttonProps.disabled}>
                {buttonProps.text}
              </Button>
            </Popover>
          </div>
        </Footer>
      </Layout>
    </Layout>
  )
}

export default Onboarding
